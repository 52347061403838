import React, { Component, createRef } from 'react';
import logo from '../../assets/images/logo/logo3.png';
import documentImg from '../../assets/images/mob/document.png';
import ActionSheet from "actionsheet-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbar from '@material-ui/core/Snackbar';
import backButton from '../../assets/images/mob/left-arrow (2).png';
const bip39 = require('bip39');

class GenerateMnemonic extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      mneumonic: [],
      checkSelect : false,
      snackbarState: { open: false, message: '' }
    };
    this.ref = createRef();
  }

  componentDidMount() {
    //this.loadBlockchainData();
    const mneumonic = this.createMneumonic();
    console.log('mneumonic:', mneumonic);
    localStorage.setItem('mneumonic', mneumonic);
    let arrayOfMneumonic = mneumonic.split(" ");
    this.setState({ mneumonic: arrayOfMneumonic, stringMneumonic: mneumonic }, () => {
      console.log('mneumonicArray:', this.state);
    });
  }

  async handleShowSnackBar(msg) {
    await this.setState({ snackbarState: { open: true, message: msg } })
    setTimeout(() => {
      this.setState({ snackbarState: { open: false, message: '' } });
    }, 2000);
  } 

  createMneumonic() {
    try {
      return bip39.generateMnemonic();
    } catch (error) {
      this.handleShowSnackBar('Something went wrong while generating passphrase');
    }
  }

  OpenActionSheet() {
    this.ref.current.open();
  }

  closeActionSheet() {
    this.ref.current.close();
  }

  render() {
    return (
      <>
        <div id="page">
          <div className="header header-fixed header-logo-center" style={{ backgroundColor: '#fff' }}>
            <a  className="header-title"><img src={logo} height="25px" width="110px" /></a>
            <a onClick={()=>{ this.props.history.push('/createPassword') }} data-back-button className="header-icon header-icon-1"><img height={20} src={backButton} /></a>
            {/* <a  data-toggle-theme class="header-icon header-icon-4"><i class="fas fa-lightbulb"></i></a> */}
          </div> 
          {/* <div id="footer-bar" class="footer-bar-1">
            <a ><i class="fa fa-home"></i><span>Home</span></a>
            <a href="index-components.html"><i class="fa fa-star"></i><span>Features</span></a>
            <a href="index-pages.html" class="active-nav"><i class="fa fa-heart"></i><span>Pages</span></a>
            <a href="index-search.html"><i class="fa fa-search"></i><span>Search</span></a>
            <a  data-menu="menu-settings"><i class="fa fa-cog"></i><span>Settings</span></a>
        </div> */}
          <div className="page-content header-clear-medium">
            <div className="card card-style">
              <div className="content" style={{ marginBottom: '0px' }}>
                <h3 style={{ paddingBottom: '25px !important' }}>Please write down the {this.state.mneumonic.length} word passphrase</h3>
                <div style={{ textAlign:'center', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }} >
                  {
                    this.state.mneumonic.map((value, index) => {
                      return <div key={index} className="form-check interest-check" style={{ marginTop: '15px' }}>
                        {/* <input className="form-check-input" type="checkbox" id="check2" disabled /> */}
                        <label className="form-check-label rounded-m" htmlFor="check2" style={{ width: '90px', color: '#000', paddingLeft: '15px', textAlign: 'center' }}> {value} <br />{index + 1}/{this.state.mneumonic.length}</label>
                        <i className=" font-17 color-brown1-dark" />
                      </div>
                    })
                  }
                  {/* <br/>
                <div className="form-check interest-check" style={{ marginTop: '15px' }}>
                  <input className="form-check-input" type="checkbox" id="check2" disabled />
                  <label className="form-check-label rounded-m" htmlFor="check2" style={{ width: '90px', color: '#000', paddingLeft: '15px', textAlign: 'center' }}> Petasdasd <br />1/12</label>
                  <i className=" font-17 color-brown1-dark" />
                </div>
                <div className="form-check interest-check" style={{ marginTop: '15px' }} >
                  <input className="form-check-input" type="checkbox" defaultValue id="check3" disabled />
                  <label className="form-check-label rounded-sm" htmlFor="check3" style={{ width: '90px', color: '#000', paddingLeft: '15px', textAlign: 'center' }}>Foo <br />2/12</label>
                  <i className=" font-17 color-gray2-dark" />
                </div>
                <div className="form-check interest-check" style={{ marginTop: '15px' }}>
                  <input className="form-check-input" type="checkbox" defaultValue id="check4" disabled />
                  <label className="form-check-label rounded-m" htmlFor="check4" style={{ width: '90px', color: '#000', paddingLeft: '15px', textAlign: 'center' }}>Bik <br />3/12</label>
                  <i className=" font-17 color-blue2-dark" />
                </div>
                <div className="form-check interest-check" style={{ marginTop: '15px' }}>
                  <input className="form-check-input" type="checkbox" defaultValue id="check5" disabled />
                  <label className="form-check-label rounded-m" htmlFor="check5" style={{ width: '90px', color: '#000', paddingLeft: '15px', textAlign: 'center' }}>Spo <br />4/12</label>
                  <i className=" color-brown1-dark font-17" />
                </div>
                <div className="form-check interest-check" style={{ marginTop: '15px' }}>
                  <input className="form-check-input" type="checkbox" defaultValue id="check6" disabled />
                  <label className="form-check-label rounded-m" htmlFor="check6" style={{ width: '90px', color: '#000', paddingLeft: '15px', textAlign: 'center' }}>Lik <br />5/12</label>
                  <i className=" font-17 color-yellow1-dark" />
                </div>
                <div className="form-check interest-check" style={{ marginTop: '15px' }}>
                  <input className="form-check-input" type="checkbox" defaultValue id="check7" disabled />
                  <label className="form-check-label rounded-m" htmlFor="check7" style={{ width: '90px', color: '#000', paddingLeft: '15px', textAlign: 'center' }}>Mus <br />6/12</label>
                  <i className=" font-17 color-instagram" />
                </div>
                <div className="form-check interest-check" style={{ marginTop: '15px' }}>
                  <input className="form-check-input" type="checkbox" defaultValue id="check8" disabled />
                  <label className="form-check-label rounded-m" htmlFor="check8" style={{ width: '90px', color: '#000', paddingLeft: '15px', textAlign: 'center' }}>Tra <br />7/12</label>
                  <i className="font-14 color-dark2-dark" />
                </div>
                <div className="form-check interest-check" style={{ marginTop: '15px' }}>
                  <input className="form-check-input" type="checkbox" defaultValue id="check9" disabled />
                  <label className="form-check-label rounded-m" htmlFor="check9" style={{ width: '90px', color: '#000', paddingLeft: '15px', textAlign: 'center' }}>Hac <br />8/12</label>
                  <i className="font-16 color-blue2-dark" />
                </div>
                <div className="form-check interest-check" style={{ marginTop: '15px' }}>
                  <input className="form-check-input" type="checkbox" defaultValue id="check11" disabled />
                  <label className="form-check-label rounded-m" htmlFor="check11" style={{ width: '90px', color: '#000', paddingLeft: '15px', textAlign: 'center' }}>Lik <br />9/12</label>
                  <i className="font-17 color-green2-dark" />
                </div>
                <div className="form-check interest-check" style={{ marginTop: '15px' }}>
                  <input className="form-check-input" type="checkbox" defaultValue id="check12" disabled />
                  <label className="form-check-label rounded-m" htmlFor="check12" style={{ width: '90px', color: '#000', paddingLeft: '15px', textAlign: 'center' }}>pac <br />10/12</label>
                  <i className="font-16 color-teal1-dark" />
                </div>
                <div className="form-check interest-check" style={{ marginTop: '15px' }}>
                  <input className="form-check-input" type="checkbox" defaultValue id="check14" disabled />
                  <label className="form-check-label rounded-m" htmlFor="check14" style={{ width: '90px', color: '#000', paddingLeft: '15px', textAlign: 'center' }}>App <br />11/12</label>
                  <i className="font-17 color-gray2-dark" />
                </div>
                <div className="form-check interest-check" style={{ marginTop: '15px' }}>
                  <input className="form-check-input" type="checkbox" defaultValue id="check15" disabled />
                  <label className="form-check-label rounded-m" htmlFor="check15" style={{ width: '90px', color: '#000', paddingLeft: '15px', textAlign: 'center' }}>Dr <br />12/12</label>
                  <i className="form-check-label font-18" />
                  <i className="font-16 color-green-dark" />
                </div> */}
                </div>



                {/* <div class="form-check interest-check">
                          <input class="form-check-input" type="checkbox" value="" id="check16a">
                          <label class="form-check-label rounded-m" for="check16a">Videos</label>
                          <i class="fa fa-check-circle color-white font-18"></i>
                          <i class="fab fa-google font-16 color-orange-dark"></i>
                    </div>
                    <div class="form-check interest-check">
                          <input class="form-check-input" type="checkbox" value="" id="check16b">
                          <label class="form-check-label rounded-m" for="check16b">Likes</label>
                          <i class="fa fa-check-circle color-white font-18"></i>
                          <i class="fa fa-thumbs-up font-16 color-blue2-dark"></i>
                    </div> */}
                <div className="mt-3" style={{ marginBottom: '30px' }} />
                <a onClick={() => { if (this.state.checkSelect) {
                  this.props.history.push('/enterMnemonic');
                } else {
                  this.handleShowSnackBar('Please check below condition!')
                }  }} className="btn btn-l rounded-sm text-uppercase font-700 btn-full  shadow-xxl" data-menu="menu-action-edge-to-edge" style={{ backgroundColor: '#68c6b0', color: '#fff' }}>Submit</a>
                <div className="divider mt-4 mb-0" />
                {/* <p class="font-11 line-height-xs boxed-text-xl pt-4">These elements are built with default input checkboxes. <br>Easy to use for your programatic functions.</p> */}
              </div>
            </div>
            <div className="card card-style" style={{ marginBottom: '7px' }}>
              <div className="content mb-0">
                <ul className="icon-list">
                  <li onClick={() => this.OpenActionSheet()} ><img src={documentImg} height="17px" /> Click here to copy your passphrase code</li>
                </ul>
              </div>
            </div>
            <div className="card card-style">
              <div className="content mb-0">
                <ul className="icon-list">
                  <li> <input id="box1-fac-checkbox" type="checkbox" onChange={ async(e)=>{ await this.setState({ checkSelect : e.target.checked }); console.log(this.state.checkSelect); }} />  Write down the 12 word Passphrase: 
Click here to submit. If you lose your passphrase you will not be able to access your wallet..</li>
                </ul>
              </div>
            </div>
            {/* <div class="fac fac-checkbox fac-default"><span></span>
                <input id="box1-fac-checkbox" type="checkbox" value="0" checked>
                <label for="box1-fac-checkbox">Simple</label>
            </div> */}
          </div>
          {/* End of Page Content*/}
          {/* menu-action-edge-to-edge */}
          <div id="menu-action-edge-to" className="menu menu-box-bottom rounded-0" data-menu-effect="menu-over" data-menu-height={240} style={{ paddingTop: '50px' }}>
            <div className="menu-title mt-n1">
              <h4 className="passphrase" style={{ paddingLeft: '20px', paddingRight: '20px' }}></h4>
              {/* <p class="color-highlight">This covers the full width of the screen</p>
                <a  class="close-menu"><i class="fa fa-times"></i></a> */}
            </div>
            <div className="content mb-0 mt-2">
              <div className="divider mb-3" />
              {/* <p>
                    The default boxes are rounded, but you can create square edges as well.
                </p> */}
              <a className="close-menu btn btn-l rounded-0 btn-full bg-green1-dark text-uppercase font-800">Copy</a>
            </div>
          </div>
          {/* All Menus, Action Sheets, Modals, Notifications, Toasts, Snackbars get Placed outside the <div class="page-content"> */}
          <div id="menu-settings" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={308}>
            <div className="menu-title"><h1>Settings</h1><p className="color-highlight">Flexible and Easy to Use</p><a className="close-menu"><i className="fa fa-times" /></a></div>
            <div className="divider divider-margins mb-n2" />
            <div className="content">
              <div className="list-group list-custom-small">
                <a data-toggle-theme data-trigger-switch="switch-dark-mode" className="pb-2 ml-n1">
                  <i className="fa font-12 fa-moon rounded-s bg-highlight color-white mr-3" />
                  <span>Dark Mode</span>
                  <div className="custom-control scale-switch ios-switch">
                    <input data-toggle-themr-switch type="checkbox" className="ios-input" id="switch-dark-mode" />
                    <label className="custom-control-label" htmlFor="switch-dark-mode" />
                  </div>
                  <i className="fa fa-angle-right" />
                </a>
              </div>
              <div className="list-group list-custom-large">
                <a data-menu="menu-highlights" >
                  <i className="fa font-14 fa-tint bg-green1-dark rounded-s" />
                  <span>Page Highlight</span>
                  <strong>16 Colors Highlights Included</strong>
                  <span className="badge bg-highlight color-white">HOT</span>
                  <i className="fa fa-angle-right" />
                </a>
                <a data-menu="menu-backgrounds" className="border-0">
                  <i className="fa font-14 fa-cog bg-blue2-dark rounded-s" />
                  <span>Background Color</span>
                  <strong>10 Page Gradients Included</strong>
                  <span className="badge bg-highlight color-white">NEW</span>
                  <i className="fa fa-angle-right" />
                </a>
              </div>
            </div>
          </div>
          {/* Menu Settings Highlights*/}
          <div id="menu-highlights" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={380} data-menu-effect="menu-over">
            <div className="menu-title"><h1>Highlights</h1><p className="color-highlight">Any Element can have a Highlight Color</p><a className="close-menu"><i className="fa fa-times" /></a></div>
            <div className="divider divider-margins mb-n2" />
            <div className="content">
              <div className="highlight-changer">
                <a data-change-highlight="blue2"><i className="fa fa-circle color-blue2-dark" /><span className="color-blue2-light">Default</span></a>
                <a data-change-highlight="red2"><i className="fa fa-circle color-red1-dark" /><span className="color-red2-light">Red</span></a>
                <a data-change-highlight="orange"><i className="fa fa-circle color-orange-dark" /><span className="color-orange-light">Orange</span></a>
                <a data-change-highlight="pink2"><i className="fa fa-circle color-pink2-dark" /><span className="color-pink2-light">Pink</span></a>
                <a data-change-highlight="magenta2"><i className="fa fa-circle color-magenta2-dark" /><span className="color-magenta2-light">Purple</span></a>
                <a data-change-highlight="aqua"><i className="fa fa-circle color-aqua-dark" /><span className="color-aqua-light">Aqua</span></a>
                <a data-change-highlight="teal"><i className="fa fa-circle color-teal-dark" /><span className="color-teal-light">Teal</span></a>
                <a data-change-highlight="mint"><i className="fa fa-circle color-mint-dark" /><span className="color-mint-light">Mint</span></a>
                <a data-change-highlight="green2"><i className="fa fa-circle color-green2-dark" /><span className="color-green2-light">Green</span></a>
                <a data-change-highlight="green1"><i className="fa fa-circle color-green1-dark" /><span className="color-green1-light">Grass</span></a>
                <a data-change-highlight="yellow2"><i className="fa fa-circle color-yellow2-dark" /><span className="color-yellow2-light">Sunny</span></a>
                <a data-change-highlight="yellow1"><i className="fa fa-circle color-yellow1-dark" /><span className="color-yellow1-light">Goldish</span></a>
                <a data-change-highlight="brown1"><i className="fa fa-circle color-brown1-dark" /><span className="color-brown1-light">Wood</span></a>
                <a data-change-highlight="dark1"><i className="fa fa-circle color-dark1-dark" /><span className="color-dark1-light">Night</span></a>
                <a data-change-highlight="dark2"><i className="fa fa-circle color-dark2-dark" /><span className="color-dark2-light">Dark</span></a>
                <div className="clearfix" />
              </div>
              <a data-menu="menu-settings" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">Back to Settings</a>
            </div>
          </div>
          {/* Menu Settings Backgrounds*/}
          <div id="menu-backgrounds" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={310} data-menu-effect="menu-over">
            <div className="menu-title"><h1>Backgrounds</h1><p className="color-highlight">Change Page Color Behind Content Boxes</p><a className="close-menu"><i className="fa fa-times" /></a></div>
            <div className="divider divider-margins mb-n2" />
            <div className="content">
              <div className="background-changer">
                <a data-change-background="none"><i className="bg-theme" /><span>Default</span></a>
                <a data-change-background="plum"><i className="body-plum" /><span className="color-plum-dark">Plum</span></a>
                <a data-change-background="magenta3"><i className="body-magenta3" /><span className="color-magenta3-dark">Magenta</span></a>
                <a data-change-background="dark3"><i className="body-dark3" /><span className="color-dark3-dark">Dark</span></a>
                <a data-change-background="violet"><i className="body-violet" /><span className="color-violet-dark">Violet</span></a>
                <a data-change-background="red3"><i className="body-red3" /><span className="color-red3-dark">Red</span></a>
                <a data-change-background="green3"><i className="body-green3" /><span className="color-green3-dark">Green</span></a>
                <a data-change-background="sky"><i className="body-sky" /><span className="color-sky-dark">Sky</span></a>
                <a data-change-background="pumpkin"><i className="body-pumpkin" /><span className="color-pumpkin-dark">Orange</span></a>
                <a data-change-background="yellow3"><i className="body-yellow3" /><span className="color-yellow3-dark">Yellow</span></a>
                <div className="clearfix" />
              </div>
              <a data-menu="menu-settings" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">Back to Settings</a>
            </div>
          </div>
          {/* Menu Share */}
          <div id="menu-share" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={345} data-menu-effect="menu-over">
            <div className="menu-title mt-n1"><h1>Share the Love</h1><p className="color-highlight">Just Tap the Social Icon. We'll add the Link</p><a className="close-menu"><i className="fa fa-times" /></a></div>
            <div className="content mb-0">
              <div className="divider mb-0" />
              <div className="list-group list-custom-small list-icon-0">
                <a className="shareToFacebook">
                  <i className="font-18 fab fa-facebook color-facebook" />
                  <span className="font-13">Facebook</span>
                  <i className="fa fa-angle-right" />
                </a>
                <a className="shareToTwitter">
                  <i className="font-18 fab fa-twitter-square color-twitter" />
                  <span className="font-13">Twitter</span>
                  <i className="fa fa-angle-right" />
                </a>
                <a className="shareToLinkedIn">
                  <i className="font-18 fab fa-linkedin color-linkedin" />
                  <span className="font-13">LinkedIn</span>
                  <i className="fa fa-angle-right" />
                </a>
                <a className="shareToWhatsApp">
                  <i className="font-18 fab fa-whatsapp-square color-whatsapp" />
                  <span className="font-13">WhatsApp</span>
                  <i className="fa fa-angle-right" />
                </a>
                <a className="shareToMail border-0">
                  <i className="font-18 fa fa-envelopr-square color-mail" />
                  <span className="font-13">Email</span>
                  <i className="fa fa-angle-right" />
                </a>
              </div>
            </div>
          </div>
          <Snackbar
          open={this.state.snackbarState.open}
          onClose={this.handleClose}
          message={this.state.snackbarState.message}
          key={'top' + 'center'}
        />
        </div>
        <ActionSheet ref={this.ref}>
          <div style={style.content}>
            {
              <h3>{this.state.mneumonic.join(", ")}</h3>
            }
            <CopyToClipboard text={this.state.stringMneumonic} onCopy={() => { this.handleShowSnackBar('Passphrase copied!'); }}>
              <a className="btn btn-m mt-4 mb-3 btn-full rounded-sm bg-highlight text-uppercase font-900" style={{ backgroundColor: '#68c6b0' }}>COPY</a>
            </CopyToClipboard>
          </div>
        </ActionSheet>
      </>
    );
  }
}

const style = {
  content: {
    height: 200,
    padding: 20,
    textAlign: 'center'
  },
};

export default GenerateMnemonic;

import React, { Component, createRef } from 'react';
import logo from '../../assets/images/logo/logo3.png';
import maticIcon from '../../assets/images/polygon-matic-logo.png';
import fbeIcon from '../../assets/images/wallettab.png';
import createWalletImg from '../../assets/images/mob/purse.png';
import importWalletImg from '../../assets/images/mob/import.png';
import LoadingOverlay from 'react-loading-overlay-ts';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Snackbar from '@material-ui/core/Snackbar'
import Web3 from 'web3';
import AddressAbbr from './AddressAbbr';
import { db } from '../firebase';
import { historySub, getUserHistory, addressesSub, generateAddress, balanceSub, getBalance, getOtherBalance, otherBalanceSub } from '../Helper/Helper';
import { Subscription } from 'rxjs';  
import ActionSheet from "actionsheet-react"; 
import { blue } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const bip39 = require('bip39');
const HdKey = require('ethereumjs-wallet').hdkey; 

const TEST_NET = "https://polygon-rpc.com/";
const TEST_NET_ADDRESS = '0x6f0dCC6597C86A2FdE9795F634f621f947CA125c';
const web3 = new Web3(new Web3.providers.HttpProvider(TEST_NET));

class WalletDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      displayLoading: false,
      addresses: [],
      balance: 0,
      otherBalance : 0,
      transactionHistory: [], 
      displayTimer: '',
      snackbarState: { open: false, message: '' },
      dialogOpen : false,
      anchorEl : null,
      password: '',
      viewPassword: false,
      sendDialogOpen : false,
      sendSelectValue : emails[1]
    };
    this.historySubscription = Subscription;
    this.addressSubscription = Subscription;
    this.balanceSubscription = Subscription;
    this.otherBalanceSubscription = Subscription;
    this.open = Boolean(this.state.anchorEl);

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.logoutNow = this.logoutNow.bind(this); 
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.sendHandleClickOpen = this.sendHandleClickOpen.bind(this);
    this.sendHandleClose = this.sendHandleClose.bind(this);
    // this.closeActionSheet = this.closeActionSheet.bind(this);
    
    this.ref = createRef();
  } 

  async handleClickOpen(event) {
    await this.setState({ dialogOpen: true });
  };

  handleClose() {
    this.setState({ dialogOpen: false }); 
  };

  componentDidMount() {
    setTimeout(() => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('1'); 
      }
    }, 100);
    localStorage.removeItem('addresses');
    this.generateAddress();
    this.setTimer();
  }
 
  handleMenuClick(event) {
    this.setState({ anchorEl: event.currentTarget })
  } 

  handleMenuClose() {
    this.setState({ anchorEl : null })
  }

  async handleShowSnackBar(msg) {
    await this.setState({ snackbarState: { open: true, message: msg } })
    setTimeout(() => {
      this.setState({ snackbarState: { open: false, message: '' } });
    }, 2000);
  }

  OpenActionSheet() {
    this.ref.current.open(); 
  }

  closeActionSheet() {
    this.ref.current.close();
  }

  handlePasswordSubmit() { 
    if (this.state.password) {
      if (this.state.password == localStorage.getItem('walletPassord')) {
          this.closeActionSheet();
          this.props.history.push('/displayPassphrase');
      } else {
          this.handleShowSnackBar('Please enter valid password!');
      }
  } else {
      this.handleShowSnackBar('Please enter password!');
  }
  }

  setTimer() {
    let dateObject = new Date();
    dateObject.setMonth(dateObject.getMonth()+1, 1);
    dateObject.setHours(0, 0, 0, 0);
    var countDownDate = new Date(dateObject).getTime();

    var now = new Date().getTime();
    var distance = countDownDate - now;
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    var display = days + " Days: " + hours + " Hours: " + minutes + " Mins " //+ seconds + "s ";
    this.setState({ displayTimer: display });

    this.time = setInterval(() => {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      var display = days + " Days: " + hours + " Hours: " + minutes + " Mins " //+ seconds + "s ";
      this.setState({ displayTimer: display });
    }, 1000 * 60);
  }

  generateAddress() {
    this.addressSubscription = addressesSub.subscribe(async (res) => {
      if (!res) {
        generateAddress();
      } else {
        await this.setState({ addresses: res });
        this.getBalance();
        this.getTransactionHistory();
        this.getBnbBalance();
      } 
    });
    // let mnenumonic = localStorage.getItem('mneumonic');
    // console.log('mnenumonic:', mnenumonic);
    // this.showKeys(mnenumonic);
  }

  async showKeys(mnenumonic) {
    let addresses = await this.createWallet(mnenumonic);
    for (let i = 0; i < 10; i++) {
      // console.log("----------------------------------");
      let privateKey = addresses[i].getPrivateKey().toString('hex');
      // console.log('privateKey:', privateKey);
      let publicKey = addresses[i].getAddress().toString('hex');
      let address = web3.eth.accounts.privateKeyToAccount(privateKey);
      let myAddress = this.state.addresses;
      myAddress.push({
        address: address.address,
        abbrAddress: address.address.slice(0, [2, 4][0] + 2) + '...' + address.address.slice(address.address.length - [3, 4][1], address.address.length),
        privateKey: privateKey.toString()
      });
      await this.setState({ addresses: myAddress });
      // this.addAddressOnFirebase();
    }
 
    // console.log('state addresses..:', this.state.addresses);
    this.getTransactionHistory();
  }

  createWallet(mneumonic) {
    let i = 0;
    let addresses = [];
    while (i < 10) {
      let seed = bip39.mnemonicToSeedSync(mneumonic);
      //console.log(mneumonic);
      let wallet = HdKey.fromMasterSeed(seed).derivePath("m/44'/60'/0'/0/0").getWallet();
      // wallet.getPublicKeyString()
      // wallet.getPrivateKeyString()
      addresses.push(wallet);
      i++;
    }

    return addresses;
  } 

  getBalance() {
    this.balanceSubscription = balanceSub.subscribe((res) => {
      if (res == null) {
        getBalance(this.state.addresses[0].address);
      } else {
        this.setState({ balance: res });
      }
    }); 
  }

  getBnbBalance() {
    this.otherBalanceSubscription = otherBalanceSub.subscribe((res)=>{
      if (res == null) {
        getOtherBalance(this.state.addresses[0].address);  
      } else {
        this.setState({ otherBalance : res });
      }
    })
  }

  copyAddress(str) {
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.handleShowSnackBar('Address Copied!');
  }

  async getTransactionHistory() {
    try {
        this.historySubscription = historySub.subscribe(async (res) => {
          // console.log('this.state.transactionHistory:', this.state.transactionHistory);
          if (!res) {
            getUserHistory(this.state.addresses[0].address);
          } else {
            await this.setState({ transactionHistory: res });
          }
        });
    } catch (error) {
      console.log('error:', error);
    }
  }

  addAddressOnFirebase() {
    var docRef = db.collection('walletAddresses').doc(this.state.addresses[0].address.toLowerCase());
    docRef.get().then((doc) => {
      if (doc.exists) { } else {
        db.collection('walletAddresses').doc(this.state.addresses[0].address.toLowerCase()).set({
          name: "Amrut Halnor"
        });
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }

  goTOTransactionDetails(value, index) {
    let ref = document.getElementById('ref' + index);
    let sendData = {
      displayValue: ref.innerText,
      check: document.getElementById('check' + index).innerText,
      value: value
    };
    this.props.history.push('/transactionDetails', sendData)
  }

  componentWillUnmount() {
   if(this.historySubscription) this.historySubscription.unsubscribe();
   if(this.addressSubscription) this.addressSubscription.unsubscribe();
   if(this.balanceSubscription) this.balanceSubscription.unsubscribe();
   if(this.otherBalanceSubscription) this.otherBalanceSubscription.unsubscribe();
   if(this.time) clearInterval(this.time);
  }

  logoutNow() {
    localStorage.removeItem('walletPassord');
    localStorage.removeItem('mneumonic');
    if(this.historySubscription) this.historySubscription.unsubscribe();
    if(this.addressSubscription) this.addressSubscription.unsubscribe();
    if(this.balanceSubscription) this.balanceSubscription.unsubscribe();
    if(this.otherBalanceSubscription) this.otherBalanceSubscription.unsubscribe();
    historySub.next(null);
    addressesSub.next(null);
    balanceSub.next(null);
    otherBalanceSub.next(null);
    this.props.history.push('/enterScreens');
    this.handleClose();
  } 
 
  sendHandleClickOpen() {
    this.setState({ sendDialogOpen : true });
  };

  sendHandleClose(value) { 
    if (value == "fbe") {
      this.props.history.push('/sendFoobee')
    } 
    if (value == "matic") {
      this.props.history.push('/sendMatic');
    }
    this.setState({ sendDialogOpen : false });
  }; 

  refreshFun() {
    console.log('refreshFun called');
    getBalance(this.state.addresses[0].address);
    getUserHistory(this.state.addresses[0].address);
    getOtherBalance(this.state.addresses[0].address);  
  }

  
  render() {
    return (
      <>
      <div id="page">
        <div className="header header-fixed header-logo-center" style={{ backgroundColor: '#fff' }}>
          <a className="header-title"><img src={logo} height="25px" width="110px" /></a>
          {/* <a  data-back-button className="header-icon header-icon-1"><img src="./images/mob/left-arrow (2).png" /></a> */}

          
          {/* <a onClick={this.handleClickOpen} style={{ marginTop: 5 }} data-toggle-theme className="header-icon header-icon-4"><i style={{ fontSize: 18 }} className="fas fa-power-off"></i></a> */}

          <button aria-controls="simple-menu" aria-haspopup="true" style={{ marginTop: 4 }} onClick={(e)=>{this.handleMenuClick(e)}} data-toggle-theme className="header-icon header-icon-4">
            <i style={{ fontSize: 18 }} className="fas fa-cog"></i>
          </button>

          {/* <Button aria-controls="simple-menu" aria-haspopup="true" >
            Open Menu
          </Button> */}
            <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={this.open}
              open={Boolean(this.state.anchorEl)}
              onClose={() => { this.handleMenuClose() }}
            >
              <MenuItem onClick={() => { this.refreshFun(); this.handleMenuClose() }}>Refresh</MenuItem>
              <MenuItem onClick={() => { this.OpenActionSheet(); this.handleMenuClose() }}>Reveal Secret Recovery Phrase</MenuItem>
              <MenuItem onClick={() => { this.handleClickOpen(); this.handleMenuClose() }}>Logout</MenuItem>
            </Menu>
        </div>
        {/* <div id="footer-bar" class="footer-bar-1">
            <a ><i class="fa fa-home"></i><span>Home</span></a>
            <a href="index-components.html" class="active-nav"><i class="fa fa-star"></i><span>Features</span></a>
            <a href="index-pages.html"><i class="fa fa-heart"></i><span>Pages</span></a>
            <a href="index-search.html"><i class="fa fa-search"></i><span>Search</span></a>
            <a  data-menu="menu-settings"><i class="fa fa-cog"></i><span>Settings</span></a>
        </div> */}
        <LoadingOverlay
          active={this.state.displayLoading}
          spinner
          text='Please wait...'
        >
          <div style={{ height: '100%' }} className="page-content header-clear-medium">
            <div className="card card-style" style={{ boxShadow: 'none' }}>
              <p style={{ textAlign: 'center', fontSize:15, marginBottom:10 }} >COUNTDOWN TO FOOBEE TOKENS RELEASE</p>
              {/* (<span style={{ fontSize: 10 }} >NUMBER OF EARNED TODAY</span>)  */}
              <h6 style={{ textAlign: 'center', color: '#68c6b0' }} >{this.state.displayTimer}</h6>
              <div className="content mb-0">
                <div className="row mb-0">
                  <div className="col-5 pr-1 text-center">
                    <span style={{ fontSize: '15px !important' }}><b>FBE Balance</b></span>
                    {/* <a  className="btn btn-m btn-full mb-3 rounded-xl text-uppercase font-900 shadow-s bg-red2-dark" style={{fontSize: '14px !important'}}>100 Foobee</a> */}
                    <a className="btn btn-m btn-full mb-3 rounded-xl text-uppercase font-900 shadow-s  bg-mint-dark" style={{ fontSize: '14px !important', color: 'white', backgroundColor: '#b0bec5' }}>{this.state.balance} FBE</a>
                  </div>
                  <div className="col-2 pr-1">
                  </div> 
                  <div className="col-5 pr-1 pl-1 text-center">
                    <span style={{ fontSize: '15px !important' }}><b>Your Wallet Address</b></span>
                    <a onClick={() => { this.copyAddress(this.state.addresses[0]?.address) }} className="btn btn-m btn-full mb-3 rounded-xl font-900 shadow-s bg-mint-dark bg-highlight1" style={{ fontSize: '14px !important', color: 'white' }}>{this.state.addresses[0]?.abbrAddress}
                      &nbsp;&nbsp; <i style={{ fontSize: "15px" }} className="far fa-copy" />
                    </a>
                  </div>
                </div>

                <div className="row mb-0">
                  <div style={{ margin:"0 auto", textAlign:'center' }}>
                    <span style={{ fontSize: '15px !important' }}><b>MATIC Balance/Gas Fee</b></span>
                    <a className="btn btn-m btn-full mb-3 rounded-xl text-uppercase font-900 shadow-s  bg-mint-dark" style={{ fontSize: '14px !important', color: 'white', backgroundColor: '#b0bec5' }}>{this.state.otherBalance} MATIC</a>
                  </div>
                </div>
 
                <div className="card card-style">
                  <div className="content  mb-0" style={{ marginTop: '0px' }}>
                    <div className="list-group list-custom-small list-icon-0">
                      <a style={{ cursor: 'pointer' }}
                      onClick={this.sendHandleClickOpen} 
                      data-menu="menu-action-edge-to-edge"><img src={createWalletImg} className="left" /><span>Send</span><i className="fa fa-angle-right" /></a>
                    </div>
                  </div>
                </div>

                {/* onClick={() => { this.props.history.push('/sendFoobee', this.state.addresses) }}  */}

                <div className="card card-style">
                  <div className="content  mb-0" style={{ marginTop: '0px' }}>
                    <div className="list-group list-custom-small list-icon-0">
                      <a style={{ cursor: 'pointer' }} onClick={() => {
                        this.props.history.push('/receiveFoobee', this.state.addresses)
                      }} data-menu="menu-action-edge-to-edge"><img src={importWalletImg} className="left" /><span>Receive</span><i className="fa fa-angle-right" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-style" style={{ boxShadow: 'none' }}>
              <div className="content">
                <h4>Transaction History</h4>
                {/* <p>
                        Simple accordions, you can use them with or without icons, it's incredibly simple.
                    </p> */}
              </div>
              <div className="list-group list-boxes">
                {
                  this.state.transactionHistory.map((value, index) => {
                    return <a key={index} onClick={() => { this.goTOTransactionDetails(value, index); }} className="border rounded-s shadow-xs" style={{ borderColor: 'yellow' }} >
                      <i style={{ color: '#0065ff' }} className="fa font-20 fa-mobile color-blue2-dark" />
                      <span id={'ref' + index} >{
                        (this.state.addresses[0].address.toLowerCase() == value.to) ?
                          (<AddressAbbr value={value.from} />) : (<AddressAbbr value={value.to} />)}
                        {/* } */}
                      </span>
                      <strong id={'check' + index} >{value.txreceipt_status == "0" ? 'Fail' : (this.state.addresses[0].address.toLowerCase() == value.to ? 'Received' : 'Send')} </strong>
                      <i className={value?.txreceipt_status == "0" ? "fa fa-times-circle" : "fa fa-check-circle"} style={{ color: value.txreceipt_status == "0" ? '#ed5565' : (this.state.addresses[0].address.toLowerCase() == value.from ? '#68c6b0' : '#0065ff') }} />
                    </a>
                  })
                }
                {/* <a  className="border border-green1-dark rounded-s shadow-xs" style={{borderColor: '#0065ff !important'}}>
                    <i style={{color:'#0065ff'}} className="fa font-20 fa-mobile color-blue2-dark" />
                    <span>Zayn</span>
                    <strong>Receive</strong>
                    <i className="fa fa-check-circle color-green1-dark" style={{color: '#0065ff'}} />
                  </a>  
                  <a  className="border border-red2-dark rounded-s shadow-s" style={{borderColor: '#ff0000 !important'}}>
                    <i style={{color:'#0065ff'}} className="fa font-20 fa-mobile color-blue2-dark" />
                    <span>Zayn</span>
                    <strong>Cancel</strong>
                    <i style={{ color:"#ed5565" }} className="fa fa-times-circle color-red2-light" />
                  </a>         */}
              </div>
            </div>
          </div>
        </LoadingOverlay>
        {/* End of Page Content*/}
        {/* All Menus, Action Sheets, Modals, Notifications, Toasts, Snackbars get Placed outside the <div class="page-content"> */}
        <div id="menu-settings" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={308}>
          <div className="menu-title"><h1>Settings</h1><p className="color-highlight">Flexible and Easy to Use</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-n2" />
          <div className="content">
            <div className="list-group list-custom-small">
              <a data-toggle-theme data-trigger-switch="switch-dark-mode" className="pb-2 ml-n1">
                <i className="fa font-12 fa-moon rounded-s bg-highlight color-white mr-3" />
                <span>Dark Mode</span>
                <div className="custom-control scale-switch ios-switch">
                  <input data-toggle-theme-switch type="checkbox" className="ios-input" id="switch-dark-mode" />
                  <label className="custom-control-label" htmlFor="switch-dark-mode" />
                </div>
                <i className="fa fa-angle-right" />
              </a>
            </div>
            <div className="list-group list-custom-large">
              <a data-menu="menu-highlights" >
                <i className="fa font-14 fa-tint bg-green1-dark rounded-s" />
                <span>Page Highlight</span>
                <strong>16 Colors Highlights Included</strong>
                <span className="badge bg-highlight color-white">HOT</span>
                <i className="fa fa-angle-right" />
              </a>
              <a data-menu="menu-backgrounds" className="border-0">
                <i className="fa font-14 fa-cog bg-blue2-dark rounded-s" />
                <span>Background Color</span>
                <strong>10 Page Gradients Included</strong>
                <span className="badge bg-highlight color-white">NEW</span>
                <i className="fa fa-angle-right" />
              </a>
            </div>
          </div>
        </div>
        {/* Menu Settings Highlights*/}
        <div id="menu-highlights" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={380} data-menu-effect="menu-over">
          <div className="menu-title"><h1>Highlights</h1><p className="color-highlight">Any Element can have a Highlight Color</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-n2" />
          <div className="content">
            <div className="highlight-changer">
              <a data-change-highlight="blue2"><i className="fa fa-circle color-blue2-dark" /><span className="color-blue2-light">Default</span></a>
              <a data-change-highlight="red2"><i className="fa fa-circle color-red1-dark" /><span className="color-red2-light">Red</span></a>
              <a data-change-highlight="orange"><i className="fa fa-circle color-orange-dark" /><span className="color-orange-light">Orange</span></a>
              <a data-change-highlight="pink2"><i className="fa fa-circle color-pink2-dark" /><span className="color-pink2-light">Pink</span></a>
              <a data-change-highlight="magenta2"><i className="fa fa-circle color-magenta2-dark" /><span className="color-magenta2-light">Purple</span></a>
              <a data-change-highlight="aqua"><i className="fa fa-circle color-aqua-dark" /><span className="color-aqua-light">Aqua</span></a>
              <a data-change-highlight="teal"><i className="fa fa-circle color-teal-dark" /><span className="color-teal-light">Teal</span></a>
              <a data-change-highlight="mint"><i className="fa fa-circle color-mint-dark" /><span className="color-mint-light">Mint</span></a>
              <a data-change-highlight="green2"><i className="fa fa-circle color-green2-dark" /><span className="color-green2-light">Green</span></a>
              <a data-change-highlight="green1"><i className="fa fa-circle color-green1-dark" /><span className="color-green1-light">Grass</span></a>
              <a data-change-highlight="yellow2"><i className="fa fa-circle color-yellow2-dark" /><span className="color-yellow2-light">Sunny</span></a>
              <a data-change-highlight="yellow1"><i className="fa fa-circle color-yellow1-dark" /><span className="color-yellow1-light">Goldish</span></a>
              <a data-change-highlight="brown1"><i className="fa fa-circle color-brown1-dark" /><span className="color-brown1-light">Wood</span></a>
              <a data-change-highlight="dark1"><i className="fa fa-circle color-dark1-dark" /><span className="color-dark1-light">Night</span></a>
              <a data-change-highlight="dark2"><i className="fa fa-circle color-dark2-dark" /><span className="color-dark2-light">Dark</span></a>
              <div className="clearfix" />
            </div>
            <a data-menu="menu-settings" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">Back to Settings</a>
          </div>
        </div>
        {/* Menu Settings Backgrounds*/}
        <div id="menu-backgrounds" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={310} data-menu-effect="menu-over">
          <div className="menu-title"><h1>Backgrounds</h1><p className="color-highlight">Change Page Color Behind Content Boxes</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-n2" />
          <div className="content">
            <div className="background-changer">
              <a data-change-background="none"><i className="bg-theme" /><span>Default</span></a>
              <a data-change-background="plum"><i className="body-plum" /><span className="color-plum-dark">Plum</span></a>
              <a data-change-background="magenta3"><i className="body-magenta3" /><span className="color-magenta3-dark">Magenta</span></a>
              <a data-change-background="dark3"><i className="body-dark3" /><span className="color-dark3-dark">Dark</span></a>
              <a data-change-background="violet"><i className="body-violet" /><span className="color-violet-dark">Violet</span></a>
              <a data-change-background="red3"><i className="body-red3" /><span className="color-red3-dark">Red</span></a>
              <a data-change-background="green3"><i className="body-green3" /><span className="color-green3-dark">Green</span></a>
              <a data-change-background="sky"><i className="body-sky" /><span className="color-sky-dark">Sky</span></a>
              <a data-change-background="pumpkin"><i className="body-pumpkin" /><span className="color-pumpkin-dark">Orange</span></a>
              <a data-change-background="yellow3"><i className="body-yellow3" /><span className="color-yellow3-dark">Yellow</span></a>
              <div className="clearfix" />
            </div>
            <a data-menu="menu-settings" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">Back to Settings</a>
          </div>
        </div>
        {/* Menu Share */}
        <div id="menu-share" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={345} data-menu-effect="menu-over">
          <div className="menu-title mt-n1"><h1>Share the Love</h1><p className="color-highlight">Just Tap the Social Icon. We'll add the Link</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="content mb-0">
            <div className="divider mb-0" />
            <div className="list-group list-custom-small list-icon-0">
              <a className="shareToFacebook">
                <i className="font-18 fab fa-facebook color-facebook" />
                <span className="font-13">Facebook</span>
                <i className="fa fa-angle-right" />
              </a>
              <a className="shareToTwitter">
                <i className="font-18 fab fa-twitter-square color-twitter" />
                <span className="font-13">Twitter</span>
                <i className="fa fa-angle-right" />
              </a>
              <a className="shareToLinkedIn">
                <i className="font-18 fab fa-linkedin color-linkedin" />
                <span className="font-13">LinkedIn</span>
                <i className="fa fa-angle-right" />
              </a>
              <a className="shareToWhatsApp">
                <i className="font-18 fab fa-whatsapp-square color-whatsapp" />
                <span className="font-13">WhatsApp</span>
                <i className="fa fa-angle-right" />
              </a>
              <a className="shareToMail border-0">
                <i className="font-18 fa fa-envelope-square color-mail" />
                <span className="font-13">Email</span>
                <i className="fa fa-angle-right" />
              </a>
            </div>
          </div>
        </div>
        <Snackbar
          open={this.state.snackbarState.open}
          message={this.state.snackbarState.message}
          key={'top' + 'center'}
        />

        <Dialog
          open={this.state.dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm to Logout"}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginTop:-10 }} id="alert-dialog-description">
            Are you sure to do this?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              No
          </Button>
            <Button onClick={this.logoutNow} color="primary" autoFocus>
              Yes
          </Button>
          </DialogActions>
        </Dialog>
      </div>
        <ActionSheet ref={this.ref}>
          <div style={style.content}>
            <h1 style={{ marginBottom:25 }} >Secret Recovery Phrase</h1>
            <h4>Enter password to continue</h4>
            <div className="content mb-0">
              <div className="input-style has-icon input-style-1 input-required">
                <em style={{ left: '5px', color: '#6e6e6e' }} >
                  <i style={{ marginTop: 5 }} className="input-icon fa fa-lock color-theme" />
                </em>
                <em style={{ marginRight: '20px', color: '#6e6e6e' }}>
                  {this.state.viewPassword ? <i style={{ marginTop: 5 }} onClick={() => this.setState({ viewPassword: !this.state.viewPassword })} className="input-icon fa fa-eye" /> :
                    <i style={{ marginTop: 5 }} onClick={() => this.setState({ viewPassword: !this.state.viewPassword })} className="input-icon fa fa-eye-slash" />
                  }
                </em>
                <input type={this.state.viewPassword ? "text" : "password"} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} placeholder="Enter Password" />
              </div>
            </div>
            <br />
            <a onClick={() => this.handlePasswordSubmit()} className="btn btn-m mt-4 mb-3 btn-full rounded-sm bg-highlight text-uppercase font-900" style={{ backgroundColor: '#68c6b0' }}>Confirm</a>
          </div>
        </ActionSheet>

        <SimpleDialog selectedValue={this.state.sendSelectValue} open={this.state.sendDialogOpen} onClose={this.sendHandleClose} />
      </>
    );
  }
}

const style = {
  content: {
      height: 350,
      padding: 20,
      textAlign: 'center'
  },
};

export default WalletDashboard;



const emails = ['FBE Coin', 'MATIC Coin'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(null);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      {/* <DialogTitle id="simple-dialog-title">FBE Coin</DialogTitle>
      <DialogTitle id="simple-dialog-title">MATIC Coin</DialogTitle> */}
      <List>
        <ListItem button onClick={() => handleListItemClick('fbe')}>
          <ListItemAvatar>
            <Avatar>
              <img src={fbeIcon} height="100%" width="100%" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Foobee Coin" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </ListItem>
        <ListItem button onClick={() => handleListItemClick('matic')}>
          <ListItemAvatar>
            <Avatar>
              <img src={maticIcon} height="85%" width="85%" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="MATIC Coin" />
        </ListItem>
      </List>
    </Dialog>
  );
}
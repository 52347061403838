import React, { Component, createRef } from 'react';
import logo from '../../assets/images/logo/logo3.png';
import abi from '../../binance-abi';
import QrScanner from '../screens/QrScanner';
import Web3 from 'web3';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useForm } from 'react-hook-form';
import { Subscription } from 'rxjs';
import { balanceSub, getBalance, getOtherBalance, otherBalanceSub, getUserHistory, addressesSub, generateAddress, adminAddress } from '../Helper/Helper';
import { db } from '../firebase';
import ActionSheet from "actionsheet-react";
import Snackbar from '@material-ui/core/Snackbar';
import backButton from '../../assets/images/mob/left-arrow (2).png';

const TEST_NET = "https://polygon-rpc.com/";
const TEST_NET_ADDRESS = '0x6f0dCC6597C86A2FdE9795F634f621f947CA125c';
const web3 = new Web3(new Web3.providers.HttpProvider(TEST_NET));
const GAS = 60000;
const GASPRICE = '0x6FC23AC00';

class SendFoobee extends Component {

    constructor(props) { 
        super(props); 
        this.state = { 
            addresses: [],
            balance: 0,
            otherBalance : 0,
            input: {},
            errors: {},
            chainId: 0,
            isOpenQRCode: false,
            displayLoading: false,
            viewPassword: false,
            password: '',
            snackbarState: { open: false, message: '' },
            isSubscription : false,
            subscriptionAmount : '25',
            subscriptionSuccess : false
        };

        this.ref = createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.scannerCallBackData = this.scannerCallBackData.bind(this);
        this.balanceSubscription = Subscription;
        this.otherBalanceSubscription = Subscription;
    }

    componentWillMount() {
        sessionStorage.removeItem('subscriptionAmount');
        sessionStorage.removeItem('isSubscription');

        const queryParams = new URLSearchParams(window.location.search);
        const isSubscription = queryParams.get('isSubscription');
        const amount = queryParams.get('amount');

        if (isSubscription) sessionStorage.setItem('isSubscription', isSubscription);
        if (amount) sessionStorage.setItem('subscriptionAmount', amount);
    }

    async componentDidMount() {
        this.addressSubscription = addressesSub.subscribe(async (res) => {
            if (!res) {
                generateAddress();
            } else {
                await this.setState({ addresses: res });
                this.init();
            }
        }); 
        
        if (sessionStorage.getItem('isSubscription')) {
            this.setState({ isSubscription : true });
            if (sessionStorage.getItem('subscriptionAmount')) {
                await this.setState({ 
                    input : { receiverAddress : adminAddress, noOfFoobee : sessionStorage.getItem('subscriptionAmount'), receiverName : 'Subscription Purchase' } 
                });  
            } else { this.setState({  input : { receiverAddress : adminAddress }  }); } 
        } else {
            this.setState({ isSubscription : false });
        }
    }

    async handleShowSnackBar(msg) {
        await this.setState({ snackbarState: { open: true, message: msg } })
        setTimeout(() => {
          this.setState({ snackbarState: { open: false, message: '' } });
        }, 2000);
    }

    async init() {
        this.getBalance();
        this.getBnbBalance();
    }
 
    getBalance() {
        this.balanceSubscription = balanceSub.subscribe((res) => {
            if (res == null) {
                getBalance(this.state.addresses[0].address);
            } else {
                this.setState({ balance: res });
            }
        });
    }

    async getBnbBalance() {
        this.otherBalanceSubscription = otherBalanceSub.subscribe((res)=>{
          if (res == null) {
            getOtherBalance(this.state.addresses[0].address);  
          } else {
            this.setState({ otherBalance : res });
          }
        });
      }

    handleChange(event) {
        // console.log(event);
        let input = this.state.input;
        input[event.target.name] = event.target.value;
        this.setState({
            input
        });
    }

    async handleSubmit(event) {
        if (this.validate()) {
            this.changeLoadingStatus();
            var FoobeeContract = new web3.eth.Contract(abi, TEST_NET_ADDRESS);
            var balance = await FoobeeContract.methods.balanceOf(this.state.addresses[0].address).call();
            let decimals = await FoobeeContract.methods.decimals().call();
            let latestBalance = parseFloat(balance) / parseFloat(10 ** decimals);
            let latestGasPrice = await web3.eth.getGasPrice();
            let latestOtherBalance = await web3.eth.getBalance(this.state.addresses[0].address);
            console.log('latestGasPrice:',latestGasPrice); 

            console.log('latestBalance:',latestBalance)

            this.changeLoadingStatus();
            if (latestBalance >= Number(this.state.input.noOfFoobee)) {
                if (latestOtherBalance >= GAS) {
                    this.OpenActionSheet();   
                } else {
                    this.handleShowSnackBar('Insufficient MATIC!');
                    setTimeout(() => {
                        this.checkSubscriptionSuccess();  
                    }, 1000);
                }
            } else {
                this.handleShowSnackBar('Insufficient balance!');
                setTimeout(() => {
                    this.checkSubscriptionSuccess();  
                }, 1000);
            }
        }
    } 

    handlePasswordSubmit() {
        if (this.state.password) {
            if (this.state.password == localStorage.getItem('walletPassord')) {
                this.closeActionSheet();
                this.sendFoobeeCoins();
            } else {
                this.handleShowSnackBar('Please enter valid password!');
            }
        } else {
            this.handleShowSnackBar('Please enter password!');
        }
    }

    OpenActionSheet() {
        this.ref.current.open();
    }

    closeActionSheet() {
        this.ref.current.close();
    }
    
    async sendFoobeeCoins() {
        try {
            this.changeLoadingStatus();
            var FoobeeContract = new web3.eth.Contract(abi, TEST_NET_ADDRESS);
            let decimals = await FoobeeContract.methods.decimals().call();

            let amount = Math.round(Number(this.state.input.noOfFoobee) * (10 ** decimals));

            let methodEncodeAbi = FoobeeContract.methods.transfer(this.state.input.receiverAddress, amount).encodeABI();

            let gasPrice = await web3.eth.getGasPrice();
            // let blockGasLimit = await web3.eth.getGasPrice();
            

            let privateKey = Buffer.from(this.state.addresses[0].privateKey);
            const rawTx = {
                to: TEST_NET_ADDRESS,
                data: methodEncodeAbi,
                gasPrice : '0x' + Number(gasPrice).toString(16),
                gasLimit: '0x' + Number(GAS).toString(16),
            };
            console.log('rawTx:', rawTx); 
            // web3.eth.getChainId().then(console.log);
            // console.log(typeof('0xc67a1074e67015cb712749beb860dd217ab6231b993ae653ca70ff59b807921f'.s));
            let signAndSend = async () => {
                try {
                    let res = await web3.eth.accounts.signTransaction(rawTx, '0x' + this.state.addresses[0].privateKey);
                    console.log('res:', res);
                    console.log("web3.utils.isHex(res['rawTransaction']):", web3.utils.isHex(res['rawTransaction']));
        
                    let a = await web3.eth.sendSignedTransaction(res['rawTransaction']);
                    console.log("as : ",a);

                    let receipt = await web3.eth.getTransactionReceipt(res['transactionHash']);
                    console.log('receipt:', receipt);
                    this.changeLoadingStatus();
                    if (receipt.status) { 
                        this.handleShowSnackBar('Transaction successful!');
                        await this.setState({ input : {} });
                        if (this.state.isSubscription) { await this.setState({ subscriptionSuccess : true }); } 
                        else { await this.setState({ subscriptionSuccess : false }); }
                        getBalance(this.state.addresses[0].address);
                        getOtherBalance(this.state.addresses[0].address);
                        getUserHistory(this.state.addresses[0].address);
                        setTimeout(() => {
                            this.props.history.push('/walletDashboard');
                        },1000); 
                        // this.checkSubscriptionSuccess(); 

                        // if (this.state.input.receiverName) {
                        //     this.addAddressOnFirebase(this.state.input.receiverAddress);
                        // }
                    } else {
                        this.handleShowSnackBar('Transaction fail!');
                        setTimeout(() => {
                            this.checkSubscriptionSuccess();  
                        }, 1000);
                    }   
                } catch (error) {
                    console.log('error:',error);
                    this.changeLoadingStatus();
                    this.checkSubscriptionSuccess();  
                }
            }; 
            signAndSend();
        } catch (error) {
            console.log('error:', error);
            this.changeLoadingStatus();
            this.checkSubscriptionSuccess();  
        }
    }
 
    addAddressOnFirebase(address) { 
        var docRef = db.collection('walletAddresses').doc(address.toLowerCase());
        docRef.get().then((doc) => {
            if (doc.exists) { } else {
                db.collection('walletAddresses').doc(address.toLowerCase()).set(Object.assign({}, {
                    name: this.state.input.receiverName
                }));
            } 
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    validate() {
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        // if (!input["receiverName"]) {
        //     isValid = false;
        //     errors["receiverName"] = "Please enter receiver name.";
        // }

        if (!input["receiverAddress"]) {
            isValid = false;
            errors["receiverAddress"] = "Please enter receiver address ";
        }

        if (!input["noOfFoobee"] ||input["noOfFoobee"] <= 0) {
            isValid = false;
            errors["noOfFoobee"] = "Please enter no. of foobee.";
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    scannerCallBackData(data, isClose) {
        console.log('call back data:', data);
        console.log('isClose:', isClose);
        if (data) {
            let input = this.state.input;
            input['receiverAddress'] = data.trim();
            this.setState({ input });
        }
        if (isClose) {
            this.setState({ isOpenQRCode: isClose ? false : true });
        }
    }

    changeLoadingStatus() {
        this.setState({ displayLoading: !this.state.displayLoading });
    }

    checkSubscriptionSuccess() {
        if (this.state.isSubscription) {
            console.log('subscriptionSuccess:',this.state.subscriptionSuccess);
            if (this.state.subscriptionSuccess) {
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage('subscriptionSuccess');
                }
            } else {
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage('subscriptionFail');
                }
            }  
            
            sessionStorage.removeItem('subscriptionAmount');
            sessionStorage.removeItem('isSubscription');
        }
    }

    componentWillUnmount() {
        console.log('componentWillUnmount called');
        this.checkSubscriptionSuccess();  
        if(this.addressSubscription) this.addressSubscription.unsubscribe();
        if(this.otherBalanceSubscription) this.otherBalanceSubscription.unsubscribe();
    }

    render() {
        return (
            <>
                <LoadingOverlay
                    active={this.state.displayLoading}
                    spinner
                    text='Please wait...'
                >
                    <div id="page">
                        <div className="header header-fixed header-logo-center" style={{ backgroundColor: '#fff' }}>
                            <a className="header-title"><img src={logo} height="24px" width="110px" /></a>
                            <a onClick={()=>{ this.props.history.push('/walletDashboard') }} data-back-button className="header-icon header-icon-1"><img height={17} src={backButton} /></a>

                            {
                                this.state.isSubscription ? <></> : 
                                <a onClick={() => { this.setState({ isOpenQRCode: true }); }} style={{ cursor: 'pointer' }} className="header-icon header-icon-1" style={{ position: 'fixed', left: '85%', marginTop: 4 }} ><i style={{ fontSize: 18 }} className="fas fa-qrcode"></i></a>
                            } 

                            {/* <a  data-toggle-theme class="header-icon header-icon-4"><i class="fas fa-lightbulb"></i></a> */}
                        </div>
                        {/* <div id="footer-bar" class="footer-bar-1">
        <a ><i class="fa fa-home"></i><span>Home</span></a>
        <a href="index-components.html" class="active-nav"><i class="fa fa-star"></i><span>Features</span></a>
        <a href="index-pages.html"><i class="fa fa-heart"></i><span>Pages</span></a>
        <a href="index-search.html"><i class="fa fa-search"></i><span>Search</span></a>
        <a  data-menu="menu-settings"><i class="fa fa-cog"></i><span>Settings</span></a>
    </div> */}


                        <>
                            {
                                this.state.isOpenQRCode ? <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', }} >
                                    <QrScanner message="Hello Amrut" scannerCallBackData={this.scannerCallBackData} /></div> :
                                    <div className="page-content header-clear-medium">
                                        <div className="page-content header-clear-medium" style={{ height: '100%', paddingBottom: '0' }}>
                                            <div className="card card-style" style={{ boxShadow: 'none' }}>
                                                <div className="content mb-0">
                                                    <div className="row mb-0">
                                                        <div className="col-3 pr-1">
                                                        </div>
                                                        <div style={{margin:"0 auto", textAlign:'center'}} className="col-6 pr-1">
                                                        <span style={{ fontSize: '18px !important' }}><b>FBE Balance</b></span>
                                                            <a className="btn btn-m btn-full mb-3 rounded-xl text-uppercase font-900 shadow-s bg-red2-dark" style={{ fontSize: '14px !important', backgroundColor: '#b0bec5', color: 'white' }}>{this.state.balance} FBE</a>
                                                        </div>
                                                        <div className="col-3 pr-1">
                                                        </div>
                                                    </div>
                                                    <div className="row mb-0">
                                                        <div style={{ margin:"0 auto", textAlign:'center' }} className="col-6 pr-1">
                                                        <span style={{ fontSize: '18px !important' }}><b>MATIC Balance</b></span>
                                                            <a className="btn btn-m btn-full mb-3 rounded-xl text-uppercase font-900 shadow-s bg-red2-dark" style={{ fontSize: '14px !important', backgroundColor: '#b0bec5', color: 'white' }}>{this.state.otherBalance} MATIC</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card card-style">
                                                <div className="content mb-0">
                                                    <h3 style={{ paddingBottom: '25px !important' }}>Enter Receiver's Detail</h3>
                                                    <div className="input-style has-icon input-style-1 input-required">
                                                        <i className="input-icon fa fa-user color-theme" />
                                                        <span>Receiver's Name</span>
                                                        <input type="text" style={{ marginBottom: 0 }} onChange={(event) => this.handleChange(event)} value={this.state.input.receiverName || ''} name="receiverName" placeholder="Receiver's Name" readOnly={this.state.isSubscription} />
                                                        <p className="text-danger" style={{ marginLeft: 30 }} >{this.state.errors.receiverName}</p>
                                                    </div>
                                                    <div className="input-style has-icon input-style-1 input-required">
                                                        <i className="input-icon fa fa-envelope color-theme" />
                                                        <span>Receiver's Address</span>
                                                        <input type="text" style={{ marginBottom: 0 }} onChange={(event) => this.handleChange(event)} value={this.state.input.receiverAddress || ''} name="receiverAddress" placeholder="Receiver's Address" readOnly={this.state.isSubscription} />
                                                        <p className="text-danger" style={{ marginLeft: 30 }} >{this.state.errors.receiverAddress}</p>
                                                    </div>
                                                    <div className="input-style has-icon input-style-1 input-required">
                                                        <i className="input-icon fa fa-share color-theme" />
                                                        <span>No. of Foobee coin to send</span>
                                                        <input type="text" style={{ marginBottom: 0 }} onChange={(event) => this.handleChange(event)} value={this.state.input.noOfFoobee || ''} name="noOfFoobee" placeholder="No. of Foobee to send" readOnly={this.state.isSubscription} />
                                                        <p className="text-danger" style={{ marginLeft: 30 }} >{this.state.errors.noOfFoobee}</p>
                                                    </div>
                                                    <a onClick={() => this.handleSubmit()} className="btn btn-m mt-4 mb-3 btn-full rounded-sm bg-highlight text-uppercase font-900" style={{ backgroundColor: '#68c6b0' }}>Confirm</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </>




                        {/* End of Page Content*/}
                        {/* All Menus, Action Sheets, Modals, Notifications, Toasts, Snackbars get Placed outside the <div class="page-content"> */}
                        <div id="menu-settings" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={308}>
                            <div className="menu-title"><h1>Settings</h1><p className="color-highlight">Flexible and Easy to Use</p><a className="close-menu"><i className="fa fa-times" /></a></div>
                            <div className="divider divider-margins mb-n2" />
                            <div className="content">
                                <div className="list-group list-custom-small">
                                    <a data-toggle-theme data-trigger-switch="switch-dark-mode" className="pb-2 ml-n1">
                                        <i className="fa font-12 fa-moon rounded-s bg-highlight color-white mr-3" />
                                        <span>Dark Mode</span>
                                        <div className="custom-control scale-switch ios-switch">
                                            <input data-toggle-theme-switch type="checkbox" className="ios-input" id="switch-dark-mode" />
                                            <label className="custom-control-label" htmlFor="switch-dark-mode" />
                                        </div>
                                        <i className="fa fa-angle-right" />
                                    </a>
                                </div>
                                <div className="list-group list-custom-large">
                                    <a data-menu="menu-highlights" >
                                        <i className="fa font-14 fa-tint bg-green1-dark rounded-s" />
                                        <span>Page Highlight</span>
                                        <strong>16 Colors Highlights Included</strong>
                                        <span className="badge bg-highlight color-white">HOT</span>
                                        <i className="fa fa-angle-right" />
                                    </a>
                                    <a data-menu="menu-backgrounds" className="border-0">
                                        <i className="fa font-14 fa-cog bg-blue2-dark rounded-s" />
                                        <span>Background Color</span>
                                        <strong>10 Page Gradients Included</strong>
                                        <span className="badge bg-highlight color-white">NEW</span>
                                        <i className="fa fa-angle-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Menu Settings Highlights*/}
                        <div id="menu-highlights" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={380} data-menu-effect="menu-over">
                            <div className="menu-title"><h1>Highlights</h1><p className="color-highlight">Any Element can have a Highlight Color</p><a className="close-menu"><i className="fa fa-times" /></a></div>
                            <div className="divider divider-margins mb-n2" />
                            <div className="content">
                                <div className="highlight-changer">
                                    <a data-change-highlight="blue2"><i className="fa fa-circle color-blue2-dark" /><span className="color-blue2-light">Default</span></a>
                                    <a data-change-highlight="red2"><i className="fa fa-circle color-red1-dark" /><span className="color-red2-light">Red</span></a>
                                    <a data-change-highlight="orange"><i className="fa fa-circle color-orange-dark" /><span className="color-orange-light">Orange</span></a>
                                    <a data-change-highlight="pink2"><i className="fa fa-circle color-pink2-dark" /><span className="color-pink2-light">Pink</span></a>
                                    <a data-change-highlight="magenta2"><i className="fa fa-circle color-magenta2-dark" /><span className="color-magenta2-light">Purple</span></a>
                                    <a data-change-highlight="aqua"><i className="fa fa-circle color-aqua-dark" /><span className="color-aqua-light">Aqua</span></a>
                                    <a data-change-highlight="teal"><i className="fa fa-circle color-teal-dark" /><span className="color-teal-light">Teal</span></a>
                                    <a data-change-highlight="mint"><i className="fa fa-circle color-mint-dark" /><span className="color-mint-light">Mint</span></a>
                                    <a data-change-highlight="green2"><i className="fa fa-circle color-green2-dark" /><span className="color-green2-light">Green</span></a>
                                    <a data-change-highlight="green1"><i className="fa fa-circle color-green1-dark" /><span className="color-green1-light">Grass</span></a>
                                    <a data-change-highlight="yellow2"><i className="fa fa-circle color-yellow2-dark" /><span className="color-yellow2-light">Sunny</span></a>
                                    <a data-change-highlight="yellow1"><i className="fa fa-circle color-yellow1-dark" /><span className="color-yellow1-light">Goldish</span></a>
                                    <a data-change-highlight="brown1"><i className="fa fa-circle color-brown1-dark" /><span className="color-brown1-light">Wood</span></a>
                                    <a data-change-highlight="dark1"><i className="fa fa-circle color-dark1-dark" /><span className="color-dark1-light">Night</span></a>
                                    <a data-change-highlight="dark2"><i className="fa fa-circle color-dark2-dark" /><span className="color-dark2-light">Dark</span></a>
                                    <div className="clearfix" />
                                </div>
                                <a data-menu="menu-settings" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">Back to Settings</a>
                            </div>
                        </div>
                        {/* Menu Settings Backgrounds*/}
                        <div id="menu-backgrounds" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={310} data-menu-effect="menu-over">
                            <div className="menu-title"><h1>Backgrounds</h1><p className="color-highlight">Change Page Color Behind Content Boxes</p><a className="close-menu"><i className="fa fa-times" /></a></div>
                            <div className="divider divider-margins mb-n2" />
                            <div className="content">
                                <div className="background-changer">
                                    <a data-change-background="none"><i className="bg-theme" /><span>Default</span></a>
                                    <a data-change-background="plum"><i className="body-plum" /><span className="color-plum-dark">Plum</span></a>
                                    <a data-change-background="magenta3"><i className="body-magenta3" /><span className="color-magenta3-dark">Magenta</span></a>
                                    <a data-change-background="dark3"><i className="body-dark3" /><span className="color-dark3-dark">Dark</span></a>
                                    <a data-change-background="violet"><i className="body-violet" /><span className="color-violet-dark">Violet</span></a>
                                    <a data-change-background="red3"><i className="body-red3" /><span className="color-red3-dark">Red</span></a>
                                    <a data-change-background="green3"><i className="body-green3" /><span className="color-green3-dark">Green</span></a>
                                    <a data-change-background="sky"><i className="body-sky" /><span className="color-sky-dark">Sky</span></a>
                                    <a data-change-background="pumpkin"><i className="body-pumpkin" /><span className="color-pumpkin-dark">Orange</span></a>
                                    <a data-change-background="yellow3"><i className="body-yellow3" /><span className="color-yellow3-dark">Yellow</span></a>
                                    <div className="clearfix" />
                                </div>
                                <a data-menu="menu-settings" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">Back to Settings</a>
                            </div>
                        </div>
                        {/* Menu Share */}
                        <div id="menu-share" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={345} data-menu-effect="menu-over">
                            <div className="menu-title mt-n1"><h1>Share the Love</h1><p className="color-highlight">Just Tap the Social Icon. We'll add the Link</p><a className="close-menu"><i className="fa fa-times" /></a></div>
                            <div className="content mb-0">
                                <div className="divider mb-0" />
                                <div className="list-group list-custom-small list-icon-0">
                                    <a className="shareToFacebook">
                                        <i className="font-18 fab fa-facebook color-facebook" />
                                        <span className="font-13">Facebook</span>
                                        <i className="fa fa-angle-right" />
                                    </a>
                                    <a className="shareToTwitter">
                                        <i className="font-18 fab fa-twitter-square color-twitter" />
                                        <span className="font-13">Twitter</span>
                                        <i className="fa fa-angle-right" />
                                    </a>
                                    <a className="shareToLinkedIn">
                                        <i className="font-18 fab fa-linkedin color-linkedin" />
                                        <span className="font-13">LinkedIn</span>
                                        <i className="fa fa-angle-right" />
                                    </a>
                                    <a className="shareToWhatsApp">
                                        <i className="font-18 fab fa-whatsapp-square color-whatsapp" />
                                        <span className="font-13">WhatsApp</span>
                                        <i className="fa fa-angle-right" />
                                    </a>
                                    <a className="shareToMail border-0">
                                        <i className="font-18 fa fa-envelope-square color-mail" />
                                        <span className="font-13">Email</span>
                                        <i className="fa fa-angle-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <Snackbar
                            open={this.state.snackbarState.open}
                            onClose={this.handleClose}
                            message={this.state.snackbarState.message}
                            key={'top' + 'center'}
                        />
                    </div>
                </LoadingOverlay> 
                <ActionSheet ref={this.ref}>
                    <div style={style.content}>
                        <h2>ENTER PASSWORD</h2>
                        <div className="content mb-0">
                            <div className="input-style has-icon input-style-1 input-required">
                                <em style={{ left: '5px', color: '#6e6e6e' }} >
                                    <i style={{ marginTop: 5 }} className="input-icon fa fa-lock color-theme" />
                                </em>
                                <em style={{ marginRight: '20px', color: '#6e6e6e' }}>
                                    {this.state.viewPassword ? <i style={{ marginTop: 5 }} onClick={() => this.setState({ viewPassword: !this.state.viewPassword })} className="input-icon fa fa-eye" /> :
                                        <i style={{ marginTop: 5 }} onClick={() => this.setState({ viewPassword: !this.state.viewPassword })} className="input-icon fa fa-eye-slash" />
                                    }
                                </em>
                                <input type={this.state.viewPassword ? "text" : "password"} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} placeholder="Enter Password" />
                            </div>
                        </div>
                        <br />
                        <a onClick={() => this.handlePasswordSubmit()} className="btn btn-m mt-4 mb-3 btn-full rounded-sm bg-highlight text-uppercase font-900" style={{ backgroundColor: '#68c6b0' }}>Confirm</a>
                    </div>
                </ActionSheet>
            </>
        ); 
    }
}

const style = {
    content: {
        height: 300,
        padding: 20,
        textAlign: 'center'
    },
};

export default SendFoobee;
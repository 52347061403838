import React, { Component } from 'react';
import logo from '../../assets/images/logo/logo3.png';
import abi from '../../binance-abi';
import Web3 from 'web3';
import Snackbar from '@material-ui/core/Snackbar';
import backButton from '../../assets/images/mob/left-arrow (2).png';
const bip39 = require('bip39');
const HdKey = require('ethereumjs-wallet').hdkey;

const TEST_NET = "https://polygon-rpc.com/";
const TEST_NET_ADDRESS = '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee';
const web3 = new Web3(new Web3.providers.HttpProvider(TEST_NET));

class EnterMnemonic extends Component {

  constructor(props) {
    super(props);
    this.state = {
      randomOrderMneumonic: [],
      enterMneumnic: [],
      correctOrderMneumnic: [],
      stringMneumnic: localStorage.getItem('mneumonic').toString(),
      snackbarState: { open: false, message: '' }
    };
  }

  componentDidMount() {
    this.initFun();
  }

  async initFun() {
    // await this.setState();
    //await this.shuffle();
    this.getRandom();
  }

  async handleShowSnackBar(msg) {
    await this.setState({ snackbarState: { open: true, message: msg } })
    setTimeout(() => {
      this.setState({ snackbarState: { open: false, message: '' } });
    }, 2000);
  }

  async getRandom() {
    let mymneumonic = localStorage.getItem('mneumonic').toString().split(" ");
    const random = mymneumonic.sort((a, b)=>{ return 0.5 - Math.random() });
    await this.setState({ randomOrderMneumonic: random });
    await this.setState({correctOrderMneumnic: localStorage.getItem('mneumonic').toString().split(" ")});
    console.log('state:',this.state); 
  }

  async shuffle() {
    var myArray = this.state.correctOrderMneumnic;
    var currentIndex = myArray.length, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [myArray[currentIndex], myArray[randomIndex]] = [
        myArray[randomIndex], myArray[currentIndex]];
    }
    await this.setState({randomOrderMneumonic: myArray});
    return null;
  }

  inputMneumnic(value) {
    if (this.state.enterMneumnic.length < 3) { 
      if (!this.state.enterMneumnic.some(mneumnic => mneumnic.value == value)) {
        let myEnterMneumnic = this.state.enterMneumnic;
        let correctIndex = this.state.correctOrderMneumnic.indexOf(value);
        myEnterMneumnic.push({ value: value, correctIndex: correctIndex });
        this.setState({ enterMneumnic: myEnterMneumnic });
      }
    } else {
      this.setState({ enterMneumnic: [] });
      let correctIndex = this.state.correctOrderMneumnic.indexOf(value);
      this.setState({ enterMneumnic: [{ value: value, correctIndex: correctIndex }] });
    }
  }

  confirm() {
    let enterMneumonic = this.state.enterMneumnic;
    console.log('enterMneumonic:',enterMneumonic);  
    let check = false;
    if (this.state.enterMneumnic.length == 3) {
      for (let index = 0; index < enterMneumonic.length; index++) {
        console.log('enterMneumonic[index]:',enterMneumonic[index]);  
        if (enterMneumonic[index].correctIndex == index) {
          check = true;
        } else { check = false; break; }
      } 
      console.log('check:', check); 
      if (check) {
        try {
          this.props.history.push('/walletDashboard');
        } catch (error) {
          this.props.history.push('/walletDashboard'); 
        }
      } else {
        this.handleShowSnackBar('Please tap on valid Passphrase!');
      }
    } else {
      this.handleShowSnackBar('Please tap on at least 3 Passphrase!');
      // ToastsStore.danger("Hey, you just clicked!")
    }
  }

  generateAddress() {
    let mnenumonic = this.state.stringMneumnic;
    console.log('mnenumonic:', mnenumonic);
    this.showKeys(mnenumonic);
  }

  async showKeys(mnenumonic) {
    let addresses = await this.createWallet(mnenumonic);
    console.log('addresses.....:',addresses);
    for (let i = 0; i < 10; i++) {
      console.log("----------------------------------");
      console.log("Public Key :", addresses[i].getAddress().toString('hex'));
      console.log("Private Key :", addresses[i].getPrivateKey().toString('hex'));
      let privateKey = addresses[i].getPrivateKey().toString('hex');
      let publicKey = addresses[i].getAddress().toString('hex');
      console.log("asdas :", web3.eth.accounts.privateKeyToAccount(privateKey));
      console.log("isValid", web3.utils.isAddress(web3.eth.accounts.privateKeyToAccount(privateKey)) );
    }
    this.props.history.push('/walletDashboard');
  }

  createWallet(mneumonic) {
    let i = 0;
    let addresses = [];
    while (i < 10) {
      let seed = bip39.mnemonicToSeedSync(mneumonic, i.toString());
      //console.log(mneumonic);
      let wallet = HdKey.fromMasterSeed(seed).derivePath("m/44'/60'/0'/0/0").getWallet();
      // wallet.getPublicKeyString()
      // wallet.getPrivateKeyString()
      addresses.push(wallet);
      i++;
    }

    return addresses;
  }
 
  render() {
    return (
      <div id="page">
        <div className="header header-fixed header-logo-center" style={{ backgroundColor: '#fff' }}>
          <a className="header-title"><img src={logo} height="25px" width="110px" /></a>
          <a onClick={()=>{ this.props.history.push('/generateMnemonic') }} data-back-button className="header-icon header-icon-1"><img height={20} src={backButton} /></a>
          {/* <a  data-toggle-theme class="header-icon header-icon-4"><i class="fas fa-lightbulb"></i></a> */}
        </div> 
        <div className="page-content header-clear-medium">
          <div className="card card-style">
            <h3 style={{ paddingBottom: '25px !important', margin: 10 }}>Tap first 3 passphrase words in the correct order</h3>
            <p className="content" style={{ height: '150px' }}>
              {
                this.state.enterMneumnic.map((value, index) => {
                  return <a key={index} className="btn btn-m rounded-xl shadow-xl text-uppercase font-800 bg-highlight" style={{ marginBottom: '10px', width: 'auto', backgroundColor: '#b0bec5', display: 'inline-block', marginLeft:3 }}>{value.value}
                  {/* <br />{ value.correctIndex + 1 }/{this.state.correctOrderMneumnic.length} */}
                  </a>
                })
              }
              {/* <a  data-back-button className="btn btn-m rounded-xl shadow-xl text-uppercase font-800 bg-highlight" style={{ marginBottom: '10px', width: '101px' }}>payment <br />7/12</a>
              <a  data-back-button className="btn btn-m rounded-xl shadow-xl text-uppercase font-800 bg-highlight" style={{ marginBottom: '10px', width: '101px' }}>pool <br />1/12</a> */}
            </p>
          </div>
          <div className="card card-style" style={{ boxShadow: 'none' }}>
            {/* <h5 style={{ textAlign: 'center' }}>Tap first 3 passphrase words in the correct order</h5> */}
            <div className="text-center" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', marginTop:5 }} >
              {
                this.state.randomOrderMneumonic.map((value, index) => {
                  return <a key={index} className="btn btn-m rounded-xl shadow-xl font-800" onClick={() => { this.inputMneumnic(value) }} style={{ marginBottom: '10px', fontSize:15, width:'auto', marginLeft:5, color: this.state.enterMneumnic.some(mneumonic => mneumonic.value == value) ? 'white' : 'black', backgroundColor: this.state.enterMneumnic.some(mneumonic => mneumonic.value == value) ? '#b0bec5' : '#68c6b0' }}> {value} </a>
                })
              }
              {/* <a  data-back-button className="btn btn-m rounded-xl shadow-xl text-uppercase font-800 bg-highlight1" style={{ marginBottom: '10px' }}> Playhill</a>
              <a  data-back-button className="btn btn-m rounded-xl shadow-xl text-uppercase font-800 bg-highlight1" style={{ marginBottom: '10px' }}> Playhill</a>
              <a  data-back-button className="btn btn-m rounded-xl shadow-xl text-uppercase font-800 bg-highlight1" style={{ marginBottom: '10px' }}> stomach</a>
              <a  data-back-button className="btn btn-m rounded-xl shadow-xl text-uppercase font-800 bg-highlight1" style={{ marginBottom: '10px' }}> stomach</a>
              <a  data-back-button className="btn btn-m rounded-xl shadow-xl text-uppercase font-800 bg-highlight1" style={{ marginBottom: '10px' }}> payment </a>
              <a  data-back-button className="btn btn-m rounded-xl shadow-xl text-uppercase font-800 bg-highlight1" style={{ marginBottom: '10px' }}> Playhill</a>
              <a  data-back-button className="btn btn-m rounded-xl shadow-xl text-uppercase font-800 bg-highlight1" style={{ marginBottom: '10px' }}> stomach</a>
              <a  data-back-button className="btn btn-m rounded-xl shadow-xl text-uppercase font-800 bg-highlight1" style={{ marginBottom: '10px' }}> stomach</a>
              <a  data-back-button className="btn btn-m rounded-xl shadow-xl text-uppercase font-800 bg-highlight1" style={{ marginBottom: '10px' }}> payment </a>
              <a  data-back-button className="btn btn-m rounded-xl shadow-xl text-uppercase font-800 bg-highlight1" style={{ marginBottom: '10px' }}> Playhill</a>
              <a  data-back-button className="btn btn-m rounded-xl shadow-xl text-uppercase font-800 bg-highlight1" style={{ marginBottom: '10px' }}> payment </a> */}
            </div>
          </div>
          {/* <a href="component-action-sheets-new.html" class="btn btn-margins rounded-s btn-m btn-full text-uppercase font-900 bg-red2-dark">Action Sheets Available here</a> */}
          {/* <a  className="btn btn-m mt-4 mb-3 btn-full content rounded-sm bg-highlight text-uppercase font-900" style={{backgroundColor: '#68c6b0 !important'}}>Confirm</a> */}
          <a onClick={()=>{ this.confirm() }} className="btn btn-l rounded-sm text-uppercase font-700 btn-full  shadow-xxl" data-menu="menu-action-edge-to-edge" style={{ backgroundColor: '#68c6b0', color: '#fff', marginLeft: 15, marginRight: 15 }} >Confirm</a>
        </div>
        {/* All Menus, Action Sheets, Modals, Notifications, Toasts, Snackbars get Placed outside the <div class="page-content"> */}
        <div id="menu-settings" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={308}>
          <div className="menu-title"><h1>Settings</h1><p className="color-highlight">Flexible and Easy to Use</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-n2" />
          <div className="content">
            <div className="list-group list-custom-small">
              <a data-toggle-theme data-trigger-switch="switch-dark-mode" className="pb-2 ml-n1">
                <i className="fa font-12 fa-moon rounded-s bg-highlight color-white mr-3" />
                <span>Dark Mode</span>
                <div className="custom-control scale-switch ios-switch">
                  <input data-toggle-theme-switch type="checkbox" className="ios-input" id="switch-dark-mode" />
                  <label className="custom-control-label" htmlFor="switch-dark-mode" />
                </div>
                <i className="fa fa-angle-right" />
              </a>
            </div> 
            <div className="list-group list-custom-large">
              <a data-menu="menu-highlights" >
                <i className="fa font-14 fa-tint bg-green1-dark rounded-s" />
                <span>Page Highlight</span>
                <strong>16 Colors Highlights Included</strong>
                <span className="badge bg-highlight color-white">HOT</span>
                <i className="fa fa-angle-right" />
              </a>
              <a data-menu="menu-backgrounds" className="border-0">
                <i className="fa font-14 fa-cog bg-blue2-dark rounded-s" />
                <span>Background Color</span>
                <strong>10 Page Gradients Included</strong>
                <span className="badge bg-highlight color-white">NEW</span>
                <i className="fa fa-angle-right" />
              </a>
            </div>
          </div>
        </div>
        {/* Menu Settings Highlights*/}
        <div id="menu-highlights" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={380} data-menu-effect="menu-over">
          <div className="menu-title"><h1>Highlights</h1><p className="color-highlight">Any Element can have a Highlight Color</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-n2" />
          <div className="content">
            <div className="highlight-changer">
              <a data-change-highlight="blue2"><i className="fa fa-circle color-blue2-dark" /><span className="color-blue2-light">Default</span></a>
              <a data-change-highlight="red2" style={{ backgroundColor: '#68c6b0' }}><i className="fa fa-circle color-red1-dark" /><span className="color-red2-light">Red</span></a>
              <a data-change-highlight="orange"><i className="fa fa-circle color-orange-dark" /><span className="color-orange-light">Orange</span></a>
              <a data-change-highlight="pink2"><i className="fa fa-circle color-pink2-dark" /><span className="color-pink2-light">Pink</span></a>
              <a data-change-highlight="magenta2"><i className="fa fa-circle color-magenta2-dark" /><span className="color-magenta2-light">Purple</span></a>
              <a data-change-highlight="aqua"><i className="fa fa-circle color-aqua-dark" /><span className="color-aqua-light">Aqua</span></a>
              <a data-change-highlight="teal"><i className="fa fa-circle color-teal-dark" /><span className="color-teal-light">Teal</span></a>
              <a data-change-highlight="mint"><i className="fa fa-circle color-mint-dark" /><span className="color-mint-light">Mint</span></a>
              <a data-change-highlight="green2"><i className="fa fa-circle color-green2-dark" /><span className="color-green2-light">Green</span></a>
              <a data-change-highlight="green1"><i className="fa fa-circle color-green1-dark" /><span className="color-green1-light">Grass</span></a>
              <a data-change-highlight="yellow2"><i className="fa fa-circle color-yellow2-dark" /><span className="color-yellow2-light">Sunny</span></a>
              <a data-change-highlight="yellow1"><i className="fa fa-circle color-yellow1-dark" /><span className="color-yellow1-light">Goldish</span></a>
              <a data-change-highlight="brown1"><i className="fa fa-circle color-brown1-dark" /><span className="color-brown1-light">Wood</span></a>
              <a data-change-highlight="dark1"><i className="fa fa-circle color-dark1-dark" /><span className="color-dark1-light">Night</span></a>
              <a data-change-highlight="dark2"><i className="fa fa-circle color-dark2-dark" /><span className="color-dark2-light">Dark</span></a>
              <div className="clearfix" />
            </div>
            <a data-menu="menu-settings" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">Back to Settings</a>
          </div>
        </div>
        {/* Menu Settings Backgrounds*/}
        <div id="menu-backgrounds" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={310} data-menu-effect="menu-over">
          <div className="menu-title"><h1>Backgrounds</h1><p className="color-highlight">Change Page Color Behind Content Boxes</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-n2" />
          <div className="content">
            <div className="background-changer">
              <a data-change-background="none"><i className="bg-theme" /><span>Default</span></a>
              <a data-change-background="plum"><i className="body-plum" /><span className="color-plum-dark">Plum</span></a>
              <a data-change-background="magenta3"><i className="body-magenta3" /><span className="color-magenta3-dark">Magenta</span></a>
              <a data-change-background="dark3"><i className="body-dark3" /><span className="color-dark3-dark">Dark</span></a>
              <a data-change-background="violet"><i className="body-violet" /><span className="color-violet-dark">Violet</span></a>
              <a data-change-background="red3"><i className="body-red3" /><span className="color-red3-dark">Red</span></a>
              <a data-change-background="green3"><i className="body-green3" /><span className="color-green3-dark">Green</span></a>
              <a data-change-background="sky"><i className="body-sky" /><span className="color-sky-dark">Sky</span></a>
              <a data-change-background="pumpkin"><i className="body-pumpkin" /><span className="color-pumpkin-dark">Orange</span></a>
              <a data-change-background="yellow3"><i className="body-yellow3" /><span className="color-yellow3-dark">Yellow</span></a>
              <div className="clearfix" />
            </div>
            <a data-menu="menu-settings" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">Back to Settings</a>
          </div>
        </div>
        {/* Menu Share */}
        <div id="menu-share" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={345} data-menu-effect="menu-over">
          <div className="menu-title mt-n1"><h1>Share the Love</h1><p className="color-highlight">Just Tap the Social Icon. We'll add the Link</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="content mb-0">
            <div className="divider mb-0" />
            <div className="list-group list-custom-small list-icon-0">
              <a className="shareToFacebook">
                <i className="font-18 fab fa-facebook color-facebook" />
                <span className="font-13">Facebook</span>
                <i className="fa fa-angle-right" />
              </a>
              <a className="shareToTwitter">
                <i className="font-18 fab fa-twitter-square color-twitter" />
                <span className="font-13">Twitter</span>
                <i className="fa fa-angle-right" />
              </a>
              <a className="shareToLinkedIn">
                <i className="font-18 fab fa-linkedin color-linkedin" />
                <span className="font-13">LinkedIn</span>
                <i className="fa fa-angle-right" />
              </a>
              <a className="shareToWhatsApp">
                <i className="font-18 fab fa-whatsapp-square color-whatsapp" />
                <span className="font-13">WhatsApp</span>
                <i className="fa fa-angle-right" />
              </a>
              <a className="shareToMail border-0">
                <i className="font-18 fa fa-envelope-square color-mail" />
                <span className="font-13">Email</span>
                <i className="fa fa-angle-right" />
              </a>
            </div>
          </div>
        </div>
        {/*------------------------------------------------------------------------------------------*/}
        {/* Place all Action Elements and Menus Outside of the page-content class. Starting from here*/}
        {/*------------------------------------------------------------------------------------------*/}
        {/*---------------*/}
        {/* Action Styles */}
        {/*---------------*/}
        {/* menu-action-box-full */}
        <div id="menu-action-box-full" className="menu menu-box-modal rounded-0" data-menu-effect="menu-parallax" data-menu-width="100%" data-menu-height="100%">
          <div className="card" data-card-height="cover">
            <div className="card-center text-center">
              <div className="content boxed-text-xl mb-0 mt-0">
                <h1>Full Screen Modal</h1>
                <p>
                  This sheet has a parallax effect. When activated the page will move up by a bit as well.
                    </p>
                <a className="close-menu btn btn-l rounded-sm btn-full bg-green1-dark text-uppercase font-800">AWESOME</a>
              </div>
            </div>
          </div>
        </div>
        {/* menu-action-box-square */}
        <div id="menu-action-box-square" className="menu menu-box-modal rounded-0" data-menu-effect="menu-push" data-menu-width={320} data-menu-height={240}>
          <div className="menu-title mt-n1">
            <h1>Square Edges</h1>
            <p className="color-highlight">This sheet has squared edges</p>
            <a className="close-menu"><i className="fa fa-times" /></a>
          </div>
          <div className="content mb-0 mt-2">
            <div className="divider mb-3" />
            <p>
              The default boxes are rounded, but you can create square edges as well.
                </p>
            <a className="close-menu btn btn-l rounded-0 btn-full bg-green1-dark text-uppercase font-800">AWESOME</a>
          </div>
        </div>
        {/* menu-action-edge-to-edge */}
        <div id="menu-action-edge-to-edge" className="menu menu-box-modal rounded-0" data-menu-width={320} data-menu-height={240}>
          <div className="menu-title mt-n1">
            <h1>Edge to Edge</h1>
            <p className="color-highlight">This covers the full width of the screen</p>
            <a className="close-menu"><i className="fa fa-times" /></a>
          </div>
          <div className="content mb-0 mt-2">
            <div className="divider mb-3" />
            <p>
              The default boxes are rounded, but you can create square edges as well.
                </p>
            <a className="close-menu btn btn-l rounded-0 btn-full bg-green1-dark text-uppercase font-800">AWESOME</a>
          </div>
        </div>
        {/*-----------------*/}
        {/* General Actions */}
        {/*-----------------*/}
        {/* menu-call */}
        <div id="menu-call" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={250}>
          <div className="menu-title mt-n1">
            <h1>Call Now</h1>
            <p className="color-highlight">Tap to Start a Call Now</p>
            <a className="close-menu"><i className="fa fa-times" /></a>
          </div>
          <div className="content mb-0 mt-2">
            <div className="divider mb-3" />
            <p>
              We're always here to help. Give us a Call Today. Just tap the button and get in touch with us.
                </p>
            <a href="tel:+1 234 567 890" className="default-link btn btn-l  rounded-sm btn-full bg-green1-dark text-uppercase font-800 btn-icon"><i className="fa fa-phone" />Call Now +1 234 567 890</a>
          </div>
        </div>
        {/* menu-subscribe */}
        <div id="menu-subscribe" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={320}>
          <div className="menu-title mt-n1">
            <h1>Subscribe</h1>
            <p className="color-theme opacity-50">Join our Newsletter</p>
            <a className="close-menu"><i className="fa fa-times" /></a>
          </div>
          <div className="content mb-0 mt-2">
            <div className="divider mb-3" />
            <p>
              Join the newsletter and get the latest and hottest news from us directly in your mailbox.
                </p>
            <div className="input-style input-style-2 input-required mb-4">
              <span className="color-highlight input-style-1-active">Email</span>
              <em>(required)</em>
              <input className="form-control" type="email" placeholder="name@domain.com" />
            </div>
            <a data-menu="menu-subscribe-confirm" className="btn btn-l mb-2 rounded-sm btn-full bg-blue2-dark text-uppercase font-800">Subscribe Now</a>
          </div>
        </div>
        {/* menu-subscribe-confirm */}
        <div id="menu-subscribe-confirm" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={320}>
          <div className="content text-center">
            <i className="fa fa-check-circle color-green1-dark fa-5x pt-2" />
            <h1 className="pt-3 font-30">Thank You</h1>
            <p className="boxed-text-xl">
              We've sent a confirmation email to your inbox. Be sure you click the link to confirm.
                </p>
            <a className="close-menu btn btn-full btn-m bg-green1-dark font-800 text-uppercase rounded-m">Awesome</a>
            <p className="font-10 opacity-50 pt-2">This is a dummy message. This form requires integration.</p>
          </div>
        </div>
        {/*menu-share-list */}
        <div id="menu-share-list" className="menu menu-box-modal rounded-m" data-menu-height={305} data-menu-width={320}>
          <div className="list-group list-custom-small pl-1 pr-3">
            <a className="shareToFacebook">
              <i className="font-18 fab fa-facebook color-facebook" />
              <span className="font-13">Facebook</span>
              <i className="fa fa-angle-right" />
            </a>
            <a className="shareToTwitter">
              <i className="font-18 fab fa-twitter-square color-twitter" />
              <span className="font-13">Twitter</span>
              <i className="fa fa-angle-right" />
            </a>
            <a className="shareToLinkedIn">
              <i className="font-18 fab fa-linkedin color-linkedin" />
              <span className="font-13">LinkedIn</span>
              <i className="fa fa-angle-right" />
            </a>
            <a className="shareToGooglePlus">
              <i className="font-18 fab fa-google-plus-square color-google" />
              <span className="font-13">Google +</span>
              <i className="fa fa-angle-right" />
            </a>
            <a className="shareToWhatsApp">
              <i className="font-18 fab fa-whatsapp-square color-whatsapp" />
              <span className="font-13">WhatsApp</span>
              <i className="fa fa-angle-right" />
            </a>
            <a className="shareToMail border-0">
              <i className="font-18 fa fa-envelope-square color-mail" />
              <span className="font-13">Email</span>
              <i className="fa fa-angle-right" />
            </a>
          </div>
        </div>
        {/*menu-share-thumbs */}
        <div id="menu-share-thumbs" className="menu menu-box-modal rounded-m" data-menu-height={420} data-menu-width={320}>
          <h1 className="text-center text-uppercase font-900 mt-3 pt-2">Share the Love</h1>
          <p className="boxed-text-xl under-heading">
            Share our page with the world, increase <br />
                your page exposure with the world.
              </p>
          <div className="divider divider-margins" />
          <div className="row text-center mr-4 ml-4 mb-0">
            <div className="col-4 mb-n2">
              <a className="icon icon-l bg-facebook rounded-s shadow-l"><i className="fab fa-facebook-f font-22" /><br /></a>
              <p className="font-11 opacity-70">Facebook</p>
            </div>
            <div className="col-4 mb-n2">
              <a className="icon icon-l bg-twitter rounded-s shadow-l"><i className="fab fa-twitter font-22" /><br /></a>
              <p className="font-11 opacity-70">Twitter</p>
            </div>
            <div className="col-4 mb-n2">
              <a className="icon icon-l bg-linkedin rounded-s shadow-l"><i className="fab fa-linkedin-in font-22" /><br /></a>
              <p className="font-11 opacity-70">LinkedIn</p>
            </div>
            <div className="col-4 mb-n2">
              <a className="icon icon-l bg-mail rounded-s shadow-l"><i className="fa fa-envelope font-22" /><br /></a>
              <p className="font-11 opacity-70">Email</p>
            </div>
            <div className="col-4 mb-n2">
              <a className="icon icon-l bg-whatsapp rounded-s shadow-l"><i className="fab fa-whatsapp font-22" /><br /></a>
              <p className="font-11 opacity-70">WhatsApp</p>
            </div>
            <div className="col-4 mb-n2">
              <a className="close-menu icon icon-l bg-red2-dark rounded-s shadow-l"><i className="fa fa-arrow-down font-22" /><br /></a>
              <p className="font-11 opacity-70">Close</p>
            </div>
          </div>
          <div className="divider divider-margins mt-n1 mb-3" />
          <p className="text-center font-10 mb-0">Copyright <span className="copyright-year" /> - Enabled. All rights reserved.</p>
        </div>
        {/*menu-tour-1 */}
        <div id="menu-tour-1" className="menu menu-box-modal rounded-m" data-menu-height={240} data-menu-width={320}>
          <div className="text-center">
            <h1 className="mt-4 font-700 font-22">Hi, I'm an Action Sheet</h1>
            <p className="boxed-text-xl pt-2">
              I can show your users different boxes, or multiple boxes, have different styles, and even complex content. Let me show you.
                </p>
            <a data-menu="menu-tour-2" className="btn btn-m rounded-sm shadow-l bg-highlight btn-center-m text-uppercase font-900">Show me more</a>
          </div>
        </div>
        {/*menu-tour-2 */}
        <div id="menu-tour-2" className="menu menu-box-top rounded-m" data-menu-height={200} data-menu-width={320}>
          <div className="text-center top-30">
            <h1 className="mt-4 font-700 font-22">Top? No problem!</h1>
            <p className="boxed-text-xl">
              You can use action sheets here as well, it's really easy to add them wherever you want.
                </p>
            <div className="row mb-0">
              <div className="col-6">
                <a data-menu="menu-tour-1" className="ml-3 btn btn-sm rounded-sm btn-full text-uppercase font-900 bg-red2-dark">Back</a>
              </div>
              <div className="col-6">
                <a data-menu="menu-tour-3" className="mr-3 btn btn-sm rounded-sm btn-full text-uppercase font-900 bg-green1-dark">Proceed</a>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        {/*menu-tour-3 */}
        <div id="menu-tour-3" className="menu menu-box-modal rounded-m" data-menu-height={220} data-menu-width={320}>
          <div className="text-center top-30">
            <h3 className="mt-4 font-700 font-22">Modal Sheets Too</h3>
            <p className="boxed-text-xl">
              You can use action sheets in any position. Fast, easy and add any content you need. Check out other examples.
                </p>
            <a className="close-menu btn btn-sm rounded-s shadow-l bg-green1-dark btn-center-m font-900">AWESOME</a>
            <div className="clear" />
          </div>
        </div>
        {/* menu-cookies */}
        <div id="menu-cookies" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={290}>
          <div className="content text-center">
            <i className="fa fa-cookie color-brown1-dark fa-5x pt-2" />
            <h1 className="pt-3 font-30">Cookies</h1>
            <p className="boxed-text-xl">
              We use cookies to make your experience of our products smoother and better.
                </p>
            <div className="row mb-0">
              <div className="col-7">
                <a className="close-menu btn btn-full btn-m btn-border color-green1-dark border-green1-dark font-700 text-uppercase rounded-sm">Privacy Policy</a>
              </div>
              <div className="col-5">
                <a className="close-menu btn btn-full btn-m bg-green1-dark font-700 text-uppercase rounded-sm">Okay</a>
              </div>
            </div>
          </div>
        </div>
        {/* menu-gdpr */}
        <div id="menu-gdpr" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={450}>
          <div className="menu-title mt-n1">
            <h1>Data Collection </h1>
            <p className="color-highlight">Information we collect from you.</p>
            <a className="close-menu"><i className="fa fa-times" /></a>
          </div>
          <div className="content mt-2">
            <div className="divider mb-3" />
            <div className="d-flex pt-1">
              <div className="align-self-center"><a className="icon font-12 color-theme  pr-3 ml-2"><i className="fa fa-user" /></a></div>
              <div className="align-self-center"><h5 className="mb-1">Personal Information</h5></div>
            </div>
            <ul className="px-5 mx-2 line-height-s pt-2 pb-2">
              <li>Favorite Songs</li>
              <li>Favorite Albums</li>
            </ul>
            <div className="d-flex">
              <div className="align-self-center"><a className="icon font-12 color-theme  pr-3 ml-2"><i className="fa fa-chart-pie" /></a></div>
              <div className="align-self-center"><h5 className="mb-1">App Usage and Analytics</h5></div>
            </div>
            <ul className="px-5 mx-2 line-height-s pt-2 pb-2">
              <li>Hours Listened</li>
            </ul>
            <div className="d-flex">
              <div className="align-self-center"><a className="icon font-12 color-theme  pr-3 ml-2"><i className="fa fa-bullseye" /></a></div>
              <div className="align-self-center"><h5 className="mb-1">Identifiers</h5></div>
            </div>
            <ul className="px-5 mx-2 line-height-s pt-2 pb-3">
              <li>Hours Listened</li>
              <li>Media Types Liked</li>
            </ul>
            <a data-menu="menu-gdpr-confirm" className="btn btn-m btn-full bg-blue2-dark text-uppercase rounded-sm font-800">I accept</a>
            <p className="text-center pt-2 mb-0 font-10">
              <a data-menu="menu-gdpr-reject" className="text-center color-theme opacity-50 px-2 pb-2 mt-3">Cancel. I don't consent.</a>
            </p>
          </div>
        </div>
        {/* menu-gdpr-confirm */}
        <div id="menu-gdpr-confirm" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={340}>
          <div className="content text-center">
            <i className="fa fa-check-circle color-green1-dark fa-5x pt-2" />
            <h1 className="pt-3 font-30">Thank You</h1>
            <p className="boxed-text-xl">
              Your data is always safe and we will never use direct identifiers to track you or use your real information in any way.
                </p>
            <a className="close-menu btn btn-full btn-m bg-green1-dark font-800 text-uppercase rounded-m">Awesome</a>
            <p className="font-10 opacity-50 pt-2 color-red2-dark font-800">THIS IS A DUMMY BOX. THERE IS NO DATA COLLECTED.</p>
          </div>
        </div>
        {/* menu-gdpr-confirm */}
        <div id="menu-gdpr-reject" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={340}>
          <div className="content text-center">
            <i className="fa fa-times-circle color-red2-dark fa-5x pt-2" />
            <h1 className="pt-3 font-30">We're Sorry</h1>
            <p className="boxed-text-xl">
              Without data collection  our services may lack the appropriate experience you want. You can change your mind and re-enable tracking.
                </p>
            <a className="close-menu btn btn-full btn-m bg-red2-dark font-800 text-uppercase rounded-m">Okay</a>
            <p className="font-10 opacity-50 pt-2 color-red2-dark font-800">THIS IS A DUMMY BOX. THERE IS NO DATA COLLECTED.</p>
          </div>
        </div>
        {/*menu-contact-1 */}
        <div id="menu-contact-1" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={460}>
          <div className="menu-title mt-n1">
            <h1>Get in Touch</h1>
            <p className="color-highlight">Send us a message and let's chat!</p>
            <a className="close-menu"><i className="fa fa-times" /></a>
          </div>
          <div className="formValidationError bg-red2-dark content rounded-sm shadow-xl" id="contactNameFieldError"><p className="text-center text-uppercase p-2 color-white font-900 ">Name is required!</p></div>
          <div className="formValidationError bg-red2-dark content rounded-sm shadow-xl" id="contactEmailFieldError"><p className="text-center text-uppercase p-2 color-white font-900">Mail address required!</p></div>
          <div className="formValidationError bg-red2-dark content rounded-sm shadow-xl" id="contactEmailFieldError2"><p className="text-center text-uppercase p-2 color-white font-900">Mail address must be valid!</p></div>
          <div className="formValidationError bg-red2-dark content rounded-sm shadow-xl" id="contactMessageTextareaError"><p className="text-center text-uppercase p-2 color-white font-900">Message field is empty!</p></div>
          <div className="formSuccessMessageWrap card card-style mt-4">
            <div className="shadow-l rounded-m bg-gradient-green1 mr-n1 ml-n1 mb-n1 ">
              <h1 className="color-white text-center pt-4"><i className="fa fa-check-circle fa-2x shadow-s scale-box rounded-circle" /></h1>
              <h2 className="color-white bold text-center pt-3">Message Sent</h2>
              <p className="color-white pb-4 text-center mt-n2 mb-0">We'll get back to you shortly.</p>
            </div>
          </div>
          <div className="formSuccessMessageWrap">
            <div className="content text-center">
              <h2>Meanwhile, let's get social!</h2>
              <p className="boxed-text-xl">
                Here are our social media platforms! Follow us for the latest updates or just say hello!
                  </p>
              <a className="icon icon-m shadow-xl rounded-xl bg-facebook"><i className="fab fa-facebook-f" /></a>
              <a className="icon icon-m shadow-xl rounded-xl bg-instagram ml-3 mr-3"><i className="fab fa-instagram" /></a>
              <a className="icon icon-m shadow-xl rounded-xl bg-twitter"><i className="fab fa-twitter" /></a>
            </div>
          </div>
          <div className="content mb-0 contact-form">
            <form action="php/contact.php" method="post" className="contactForm" id="contactForm">
              <fieldset>
                <div className="form-field form-name">
                  <label className="contactNameField color-theme" htmlFor="contactNameField">Name:<span>(required)</span></label>
                  <input type="text" name="contactNameField" defaultValue className="contactField round-small requiredField" id="contactNameField" />
                </div>
                <div className="form-field form-email">
                  <label className="contactEmailField color-theme" htmlFor="contactEmailField">Email:<span>(required)</span></label>
                  <input type="text" name="contactEmailField" defaultValue className="contactField round-small requiredField requiredEmailField" id="contactEmailField" />
                </div>
                <div className="form-field form-text">
                  <label className="contactMessageTextarea color-theme" htmlFor="contactMessageTextarea">Message:<span>(required)</span></label>
                  <textarea name="contactMessageTextarea" className="contactTextarea round-small requiredField" id="contactMessageTextarea" defaultValue={""} />
                </div>
                <div className="form-button">
                  <input type="submit" className="btn bg-highlight text-uppercase font-900 btn-m btn-full rounded-sm  shadow-xl contactSubmitButton mb-3" defaultValue="Send Message" data-formid="contactForm" />
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {/* menu-contact-2 */}
        <div id="menu-contact-2" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={290}>
          <div className="content text-center">
            <i className="fa fa-handshake color-gray2-dark fa-5x pt-2" />
            <h1 className="pt-3 font-30">Get in Touch</h1>
            <p className="boxed-text-xl">
              We're always here to help. Send us an email or contact us via phone. We're happy to help.
                </p>
            <div className="row mb-0">
              <div className="col-6">
                <a data-menu="menu-contact-1" className="btn btn-full btn-m bg-blue2-dark font-800 text-uppercase rounded-sm">Email</a>
              </div>
              <div className="col-6">
                <a data-menu="menu-call" className="btn btn-full btn-m bg-green1-dark font-800 text-uppercase rounded-sm">Call</a>
              </div>
            </div>
          </div>
        </div>
        {/*----------------*/}
        {/* Option Actions */}
        {/*----------------*/}
        <div id="menu-option-1" className="menu menu-box-modal rounded-m bg-transparent" data-menu-height={350} data-menu-width={330}>
          <div className="content pt-3 pb-3 bg-white rounded-m">
            <h1 className="text-center pt-3"><i className="fa fa-3x fa-info-circle color-blue2-dark shadow-xl rounded-circle" /></h1>
            <h3 className="text-center mt-3 font-700">Are you sure?</h3>
            <p className="boxed-text-xl opacity-70">
              You can continue with your previous actions.<br /> Easy to attach these to success calls.
                </p>
            <div className="row mb-0 pb-3 mr-3 ml-3">
              <div className="col-6">
                <a className="btn close-menu btn-full btn-m bg-red2-dark border-red-dark font-800 text-uppercase rounded-s">Cancel</a>
              </div>
              <div className="col-6">
                <a className="btn close-menu btn-full btn-m bg-green1-dark border-green-dark font-800 text-uppercase rounded-s">Okay</a>
              </div>
            </div>
          </div>
        </div>
        <div id="menu-option-2" className="menu menu-box-modal rounded-0 bg-transparent" data-menu-height={355} data-menu-width={330}>
          <div className="content bg-theme rounded-sm">
            <div className="list-group list-custom-small pr-2 pb-1">
              <a >
                <i className="font-14 fab fa-chrome color-yellow1-dark" />
                <span>Open in Browser</span>
                <i className="fa fa-angle-right pr-2" />
              </a>
              <a >
                <i className="font-14 fa fa-bookmark color-red2-dark" />
                <span>Save for Later</span>
                <i className="fa fa-angle-right pr-2" />
              </a>
              <a >
                <i className="font-14 fab fa-facebook-messenger color-facebook" />
                <span>Send in Message</span>
                <i className="fa fa-angle-right pr-2" />
              </a>
              <a >
                <i className="font-14 fa fa-envelope color-blue2-dark" />
                <span>Send in Email</span>
                <i className="fa fa-angle-right pr-2" />
              </a>
              <a >
                <i className="font-14 fa fa-exclamation-circle color-red2-dark" />
                <span>Report Article</span>
                <i className="fa fa-angle-right pr-2" />
              </a>
            </div>
            <a className="close-menu btn-m text-center mt-3 font-900 btn-full bg-red2-dark mt-n1 text-uppercase">Cancel</a>
          </div>
        </div>
        <div id="menu-option-3" className="menu menu-box-modal rounded-0 bg-transparent" data-menu-height={390} data-menu-width={330}>
          <div className="content bg-theme">
            <h1 className="text-center pt-3 font-20 font-700">Are you Sure?</h1>
            <p className="boxed-text-m">
              This is an action box. You can add options, buttons and more settings to it.
                </p>
            <div className="divider mb-0" />
            <div className="list-group list-custom-small text-center">
              <a >
                <span>OPTION 1</span>
              </a>
              <a >
                <span>OPTION 2</span>
              </a>
              <a >
                <span>OPTION 3</span>
              </a>
            </div>
          </div>
          <a className="close-menu btn-m text-center font-900 btn-full mx-3 bg-red2-dark text-uppercase">Cancel</a>
        </div>
        <div id="menu-option-4" className="menu menu-box-modal rounded-0 bg-transparent" data-menu-height={230} data-menu-width={330}>
          <div className="content bg-theme rounded-m mt-5">
            <h1 className="text-center pt-3 font-20 font-700">Are you Sure?</h1>
            <p className="boxed-text-m mb-3">
              This is an action box. You can add options, buttons and more settings to it.
                </p>
            <div className="divider mb-0" />
            <div className="row mb-0 text-center">
              <div className="col-6">
                <a className="close-menu btn btn-full color-green2-dark btn-l font-900 font-15">ACCEPT</a>
              </div>
              <div className="col-6">
                <a className="close-menu btn btn-full color-red2-dark btn-l font-900 font-15">DECLINE</a>
              </div>
            </div>
            <div className="divider mb-0" />
          </div>
        </div>
        {/*-----------------*/}
        {/* Account Actions */}
        {/*-----------------*/}
        {/* menu-login-1 */}
        <div id="menu-login-1" className="menu menu-box-modal rounded-m" data-menu-height={300} data-menu-width={320}>
          <div className="menu-title mt-n1">
            <h1>Login</h1>
            <p className="color-theme opacity-50">Please enter your credentials below</p>
            <a className="close-menu"><i className="fa fa-times" /></a>
          </div>
          <div className="content mb-0 mt-n2">
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-user font-11" />
              <span>Username</span>
              <em>(required)</em>
              <input type="name" placeholder="Username" />
            </div>
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-lock font-11" />
              <span>Password</span>
              <em>(required)</em>
              <input type="password" placeholder="Password" />
            </div>
            <div className="row">
              <div className="col-6">
                <a data-menu="menu-forgot-1" className="font-10">Forgot Password?</a>
              </div>
              <div className="col-6">
                <a data-menu="menu-register-1" className="float-right font-10">Create Account</a>
                <div className="clearfix" />
              </div>
            </div>
            <a className="btn btn-full btn-m shadow-l rounded-s text-uppercase font-900 bg-green1-dark mt-n2">LOGIN</a>
          </div>
        </div>
        {/* menu-login-2 */}
        <div id="menu-login-2" className="menu menu-box-modal rounded-m" data-menu-height={320} data-menu-width={320}>
          <div className="card m-0 bg-18 theme-dark" data-card-height={320}>
            <div className="card-body mx-0">
              <h1>Login</h1>
              <p className="font-11 mb-0 mt-n2">
                Please enter your credentials bellow to continue.
                  </p>
              <div className="divider mt-2 mb-1" />
              <div className="input-style has-icon input-style-1 input-required">
                <i className="input-icon fa fa-user font-11" />
                <span>Username</span>
                <em>(required)</em>
                <input type="name" placeholder="Username" />
              </div>
              <div className="input-style has-icon input-style-1 input-required">
                <i className="input-icon fa fa-lock font-11" />
                <span>Password</span>
                <em>(required)</em>
                <input type="password" placeholder="Password" />
              </div>
              <div className="row">
                <div className="col-6">
                  <a data-menu="menu-forgot-2" className="font-10">Forgot Password?</a>
                </div>
                <div className="col-6">
                  <a data-menu="menu-register-2" className="float-right font-10">Create Account</a>
                  <div className="clearfix" />
                </div>
              </div>
              <a className="btn btn-full btn-m shadow-l rounded-s text-uppercase font-900 bg-green1-dark mt-n2">LOGIN</a>
            </div>
            <div className="card-overlay bg-black opacity-90" />
          </div>
        </div>
        {/* menu-register-1 */}
        <div id="menu-register-1" className="menu menu-box-modal rounded-m" data-menu-height={420} data-menu-width={320}>
          <div className="menu-title mt-n1">
            <h1>Register</h1>
            <p className="color-theme opacity-50">Creat an Account</p>
            <a className="close-menu"><i className="fa fa-times" /></a>
          </div>
          <div className="content mb-0 mt-n2">
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-user font-11" />
              <span>Your User</span>
              <em>(required)</em>
              <input type="name" placeholder="Your User" />
            </div>
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-at font-11" />
              <span>Username</span>
              <em>(required)</em>
              <input type="email" placeholder="Your Email" />
            </div>
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-lock font-11" />
              <span>Choose Password</span>
              <em>(required)</em>
              <input type="password" placeholder="Choose Password" />
            </div>
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-lock font-11" />
              <span>Confirm Password</span>
              <em>(required)</em>
              <input type="password" placeholder="Confirm Password" />
            </div>
            <div className="row">
              <div className="col-6">
                <a data-menu="menu-forgot-1" className="font-10">Forgot Password?</a>
              </div>
              <div className="col-6">
                <a data-menu="menu-login-1" className="float-right font-10">Login Here</a>
                <div className="clearfix" />
              </div>
            </div>
            <a className="btn btn-full btn-m shadow-l rounded-s text-uppercase font-900 bg-blue2-dark mt-n2">Create account</a>
          </div>
        </div>
        {/* menu-register-2 */}
        <div id="menu-register-2" className="menu menu-box-modal rounded-m" data-menu-height={380} data-menu-width={320}>
          <div className="card m-0 bg-18 theme-dark" data-card-height={380}>
            <div className="card-body mx-0">
              <div className="input-style has-icon input-style-1 input-required">
                <i className="input-icon fa fa-user font-11" />
                <span>Your User</span>
                <em>(required)</em>
                <input type="name" placeholder="Your User" />
              </div>
              <div className="input-style has-icon input-style-1 input-required">
                <i className="input-icon fa fa-at font-11" />
                <span>Username</span>
                <em>(required)</em>
                <input type="email" placeholder="Your Email" />
              </div>
              <div className="input-style has-icon input-style-1 input-required">
                <i className="input-icon fa fa-lock font-11" />
                <span>Choose Password</span>
                <em>(required)</em>
                <input type="password" placeholder="Choose Password" />
              </div>
              <div className="input-style has-icon input-style-1 input-required">
                <i className="input-icon fa fa-lock font-11" />
                <span>Confirm Password</span>
                <em>(required)</em>
                <input type="password" placeholder="Confirm Password" />
              </div>
              <div className="row">
                <div className="col-6">
                  <a data-menu="menu-login-2" className="font-10">Login Here</a>
                </div>
                <div className="col-6">
                  <a data-menu="menu-forgot-2" className="float-right font-10">Forgot Password</a>
                  <div className="clearfix" />
                </div>
              </div>
              <a className="btn btn-full btn-m shadow-l rounded-s text-uppercase font-900 bg-blue2-dark mt-n2">Create account</a>
            </div>
            <div className="card-overlay bg-black opacity-90" />
          </div>
        </div>
        {/* menu-forgot-1 */}
        <div id="menu-forgot-1" className="menu menu-box-modal rounded-m" data-menu-height={240} data-menu-width={320}>
          <div className="menu-title mt-n1">
            <h1>Forgot</h1>
            <p className="color-theme opacity-50">Recover your account</p>
            <a className="close-menu"><i className="fa fa-times" /></a>
          </div>
          <div className="content mb-0 mt-n2">
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-at font-11" />
              <span>Username</span>
              <em>(required)</em>
              <input type="email" placeholder="Email Address" />
            </div>
            <div className="row">
              <div className="col-6">
                <a data-menu="menu-login-1" className="font-10">Login Here</a>
              </div>
              <div className="col-6">
                <a data-menu="menu-register-1" className="float-right font-10">Create Account</a>
                <div className="clearfix" />
              </div>
            </div>
            <a className="btn btn-full btn-m shadow-l rounded-s text-uppercase font-900 bg-red2-dark mt-n2">Reset Password</a>
          </div>
        </div>
        {/* menu-forgot-2 */}
        <div id="menu-forgot-2" className="menu menu-box-modal rounded-m" data-menu-height={260} data-menu-width={320}>
          <div className="card m-0 bg-18 theme-dark" data-card-height={260}>
            <div className="card-body mx-0">
              <h1>Forgot</h1>
              <p className="font-11 mb-0 mt-n2">
                Recover your Account.
                  </p>
              <div className="divider mt-2 mb-1" />
              <div className="input-style has-icon input-style-1 input-required">
                <i className="input-icon fa fa-at font-11" />
                <span>Username</span>
                <em>(required)</em>
                <input type="email" placeholder="Email Address" />
              </div>
              <div className="row">
                <div className="col-6">
                  <a data-menu="menu-login-2" className="font-10">Login Here</a>
                </div>
                <div className="col-6">
                  <a data-menu="menu-register-2" className="float-right font-10">Create Account</a>
                  <div className="clearfix" />
                </div>
              </div>
              <a className="btn btn-full btn-m shadow-l rounded-s text-uppercase font-900 bg-red2-dark mt-n2">Reset Password</a>
            </div>
            <div className="card-overlay bg-black opacity-90" />
          </div>
        </div>
        {/* menu-user-switcher-1 */}
        <div id="menu-user-switch" className="menu menu-box-modal rounded-m" data-menu-height={340} data-menu-width={320}>
          <div className="menu-title mt-n1">
            <h1>Accounts</h1>
            <p className="color-theme opacity-50">Switch or Login to another User</p>
            <a className="close-menu"><i className="fa fa-times" /></a>
          </div>
          <div className="content mb-0 mt-1">
            <div className="divider mb-3" />
            <div className="d-flex mb-4">
              <div className="align-self-center">
                <img src="images/splash/apple-touch-icon-120x120.png" width={60} className="rounded-xl shadow-xl border mr-3" />
              </div>
              <div className="align-self-center">
                <a >
                  <h4 className="font-700">Enabled</h4>
                  <h5 className="font-400 font-12 mt-n1 mb-0 opacity-50">name@domain.com</h5>
                </a>
              </div>
              <div className="align-self-center ml-auto">
                <a className="icon icon-l rounded-l mb-1"><i className="fa fa-check-circle color-blue-dark font-18 mr-n3 pr-1" /></a>
              </div>
            </div>
            <div className="d-flex mb-4">
              <div className="align-self-center">
                <img src="images/pictures/faces/2s.png" width={60} className="rounded-xl shadow-0 border mr-3" />
              </div>
              <div className="align-self-center">
                <a >
                  <h4 className="font-700">John Doe</h4>
                  <h5 className="font-400 font-12 mt-n1 mb-0 opacity-50">name@domain.com</h5>
                </a>
              </div>
              <div className="align-self-center ml-auto">
                <a className="icon icon-l rounded-l mb-1" />
              </div>
            </div>
            <div className="d-flex mb-4">
              <div className="align-self-center">
                <i className="icon icon-xl bg-gray2-light rounded-xl mr-2"><i className="fa fa-plus color-gray2-dark" /></i>
              </div>
              <div className="align-self-center">
                <a >
                  <h4 className="font-500 mb-0 pl-2 font-14">Log in or create new account</h4>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* menu-language */}
        <div id="menu-language" className="menu menu-box-modal rounded-m" data-menu-height={330} data-menu-width={320}>
          <div className="menu-title mt-n1">
            <h1>Site Language</h1>
            <p className="color-theme opacity-50">Just a Demo Action Sheet </p>
            <a className="close-menu"><i className="fa fa-times" /></a>
          </div>
          <div className="content mb-0 mt-0">
            <div className="divider mt-2 mb-0" />
            <div className="list-group list-custom-small pl-1">
              <a ><img className="mr-3 mt-n1" width={20} src="images/flags/United-States.png" /><span>English</span><i className="fa fa-angle-right" /></a>
              <a ><img className="mr-3 mt-n1" width={20} src="images/flags/India.png" /><span>Indian</span><i className="fa fa-angle-right" /></a>
              <a ><img className="mr-3 mt-n1" width={20} src="images/flags/Germany.png" /><span>German</span><i className="fa fa-angle-right" /></a>
              <a ><img className="mr-3 mt-n1" width={20} src="images/flags/Italy.png" /><span>Italian</span><i className="fa fa-angle-right" /></a>
              <a className="border-0"><img className="mr-3 mt-n1" width={20} src="images/flags/Spain.png" /><span>Spanish</span><i className="fa fa-angle-right" /></a>
            </div>
            <div className="clear" />
          </div>
        </div>
        {/* menu-age */}
        <div id="menu-age" className="menu menu-box-modal rounded-m" data-menu-height={220} data-menu-width={320}>
          <div className="menu-title mt-n1">
            <h1>Confirm Age</h1>
            <p className="color-theme opacity-50">You must be 18 to continue </p>
            <a className="close-menu"><i className="fa fa-times" /></a>
          </div>
          <div className="content mb-0 mt-0">
            <div className="row mb-0">
              <div className="col-4">
                <div className="input-style input-style-1 input-required">
                  <span>Day - 31</span>
                  <input id="date-birth-day" type="number" placeholder="Day - 31" />
                </div>
              </div>
              <div className="col-4">
                <div className="input-style input-style-1 input-required">
                  <span>Month - 12</span>
                  <input id="date-birth-month" type="number" placeholder="Month - 12" />
                </div>
              </div>
              <div className="col-4">
                <div className="input-style input-style-1 input-required">
                  <span>Year - 1986</span>
                  <input id="date-birth-year" type="number" placeholder="Year - 1986" />
                </div>
              </div>
            </div>
            <a className="check-age btn btn-full btn-m shadow-l rounded-s bg-blue2-dark mt-3 text-uppercase font-900">Verify Age</a>
          </div>
        </div>
        {/* menu-okay */}
        <div id="menu-age-okay" className="menu menu-box-modal rounded-m" data-menu-height={295} data-menu-width={320}>
          <div className="content text-center">
            <i className="fa fa-check-circle color-green1-dark fa-5x pt-2" />
            <h1 className="pt-3 font-20">Age Verified</h1>
            <p className="boxed-text-xl">
              Thank you for confirming your age. Please note this content is NSFW
                </p>
            <a className="close-menu btn btn-full btn-m bg-green1-dark font-800 text-uppercase rounded-sm">Okay</a>
          </div>
        </div>
        {/* menu-fail */}
        <div id="menu-age-fail" className="menu menu-box-modal rounded-m" data-menu-height={295} data-menu-width={320}>
          <div className="content text-center">
            <i className="fa fa-times-circle color-red2-dark fa-5x pt-2" />
            <h1 className="pt-3 font-20">Age Verification Failed</h1>
            <p className="boxed-text-xl">
              We're sorry. This content is innapropriate for younger audiences. Access Denied.
                </p>
            <a className="close-menu btn btn-full btn-m bg-red2-dark font-800 text-uppercase rounded-sm">Okay</a>
          </div>
        </div>
        {/* menu contact card*/}
        <div id="menu-contact-card" className="menu menu-box-modal rounded-m" data-menu-height={400} data-menu-width={320}>
          <div data-card-height={400} className="card mb-3 preload-img" data-src="images/pictures/14w.jpg">
            <div className="card-center text-center">
              <h1 className="fa-4x color-white pt-3">Enabled</h1>
              <p className="color-highlight mb-3">The Best Mobile Developer on Envato</p>
              <a href="scripts/vcard.vcf" className="default-link mb-5 btn btn-m rounded-s btn-center-xl bg-red2-dark font-900 text-uppercase"> DOWNLOAD V-CARD</a>
            </div>
            <div className="card-bottom">
              <p className="color-white text-center mb-0 font-11">Scroll Down For More Contact Details</p>
              <h1 className="color-white text-center scale-icon font-14 pb-3"><i className="fa fa-arrow-down color-white" /></h1>
            </div>
            <div className="card-overlay bg-black opacity-85" />
          </div>
          <div className="content mt-0 mb-2">
            <h1 className="font-20 font-700 color-highlight">Phone</h1>
            <div className="vcard-field"><strong>Mobile</strong><a href="tel:+1 234 567 890">+1 234 567 890</a><i className="fa fa-phone" /></div>
            <div className="vcard-field"><strong>Office</strong><a href="tel:+2 234 567 890">+2 234 567 890</a><i className="fa fa-suitcase" /></div>
            <div className="vcard-field border-0"><strong>Personal</strong><a href="tel:+3 234 567 890">+3 234 567 890</a><i className="fa fa-user" /></div>
            <div className="pt-4" />
            <h1 className="font-20 font-700 color-highlight pb-3">Address</h1>
            <div className="vcard-field line-height-l pb-3"><strong>Work</strong><a href="tel:+1 234 567 890">Milky Way, Planet Earth, <br /> 2134 UltraMobile Street</a><i className="fa fa-map-marker mt-n2" /></div>
            <div className="vcard-field line-height-l pb-3 pt-4 border-0"><strong>Home</strong><a href="tel:+1 234 567 890">Milky Way, Planet Earth, <br /> 1234 Enabled Avenue</a><i className="fa fa-map-marker mt-3" /></div>
            <div className="pt-4" />
            <h1 className="font-20 font-700 color-highlight">Email</h1>
            <div className="vcard-field"><strong>Home</strong><a href="mailto:home@domain.com">home@domain.com</a><i className="fa fa-home" /></div>
            <div className="vcard-field"><strong>Office</strong><a href="mailto:office@domain.com">office@domain.com</a><i className="fa fa-suitcase" /></div>
            <div className="vcard-field border-0"><strong>Personal</strong><a href="mailto:personal@domain.com">personal@domain.com</a><i className="fa fa-user" /></div>
            <div className="pt-4" />
            <h1 className="font-20 font-700 color-highlight">Website</h1>
            <div className="vcard-field border-0"><strong>URL</strong><a href="www.enableds.com">www.domain.com</a><i className="fa fa-globe" /></div>
            <div className="pt-4" />
            <h1 className="vcard-title color-highlight">Social</h1>
            <div className="vcard-field"><strong>Facebook</strong><a href="www.enableds.com">karla.black</a><i className="fab fa-facebook" /></div>
            <div className="vcard-field"><strong>Twitter</strong><a href="www.enableds.com">@karla.black</a><i className="fab fa-twitter" /></div>
            <div className="vcard-field border-0"><strong>Google Plus</strong><a href="www.enableds.com">@karla.black</a><i className="fab fa-google-plus" /></div>
            <div className="divider" />
            <div className="row mb-0">
              <div className="col-4">
                <a className="close-menu mb-4 btn btn-m rounded-s btn-full color-red2-dark border-red2-dark font-900 text-uppercase"> Close</a>
              </div>
              <div className="col-8">
                <a href="scripts/vcard.vcf" className="default-link mb-4 btn btn-m rounded-s btn-full bg-red2-dark font-900 text-uppercase">DOWNLOAD V-CARD</a>
              </div>
            </div>
          </div>
        </div>
        {/*------------------*/}
        {/* Commerce Actions */}
        {/*------------------*/}
        {/* menu-cart-1 */}
        <div id="menu-cart-1" className="menu menu-box-modal rounded-m" data-menu-height={420} data-menu-width={320}>
          <div className="menu-title"><h1>Shopping Cart</h1><p className="color-highlight">Your Current Cart Products</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins" />
          <div className="content mb-0">
            <div>
              <div className="d-flex mb-4">
                <div><img src="images/pictures/9s.jpg" className="rounded-m shadow-xl" width={80} /></div>
                <div className="ml-3">
                  <h5 className="font-600">Your awesome long or short product title</h5>
                  <h1 className="font-20 color-highlight">$99.<sup>99</sup></h1>
                </div>
              </div>
            </div>
          </div>
          <div className="content mb-0">
            <div>
              <div className="d-flex mb-4">
                <div><img src="images/pictures/13s.jpg" className="rounded-m shadow-xl" width={80} /></div>
                <div className="ml-3">
                  <h5 className="font-600">Macbook Pro 13" Core i7, 16GB, 512 SSD</h5>
                  <h1 className="font-20 color-highlight">$1499.<sup>99</sup></h1>
                </div>
              </div>
            </div>
          </div>
          <div className="divider divider-margins" />
          <div className="content">
            <div className="row mb-0">
              <div className="col-5"><a className="btn btn-full btn-m font-800 rounded-sm text-uppercase bg-green1-dark">Checkout</a></div>
              <div className="col-7"><a className="btn btn-full btn-m font-800 rounded-sm text-uppercase bg-blue2-dark">Continue Shopping</a></div>
            </div>
          </div>
        </div>
        {/* menu-cart-item-1 */}
        <div id="menu-cart-item-1" className="menu menu-box-modal rounded-m" data-menu-height={400} data-menu-width={320}>
          <div className="menu-title"><h1>Edit Product</h1><p className="color-highlight">Choose your Preferences</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins" />
          <div className="content mb-0">
            <div>
              <div className="d-flex mb-4">
                <div><img src="images/pictures/9s.jpg" className="rounded-m shadow-xl" width={120} /></div>
                <div className="ml-3">
                  <h5 className="font-600">Your awesome long or short product title</h5>
                  <h1 className="color-green1-dark">$99.<sup>99</sup></h1>
                  <a className="color-theme opacity-50 font-10"><i className="fa fa-times color-red2-dark pr-2 pt-3" />Remove</a>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-8">
                <div className="input-style input-style-2">
                  <span className="input-style-1-active">Select a Size</span>
                  <em><i className="fa fa-angle-down" /></em>
                  <select>
                    <option value={1}>Extra Small Size</option>
                    <option value={2}>Small Size</option>
                    <option value="default" >Medium Size</option>
                    <option value={4}>Large Size</option>
                    <option value={5}>Extra Large Size</option>
                  </select>
                </div>
              </div>
              <div className="col-4">
                <div className="input-style input-style-2">
                  <span className="input-style-1-active">Qty</span>
                  <input type="number" min={1} max={999} style={{ borderWidth: '1px' }} placeholder={1} />
                </div>
              </div>
            </div>
            <a className="btn  btn-m font-800 rounded-sm btn-full text-uppercase bg-green1-dark">CLose product Edit</a>
          </div>
        </div>
        {/* menu-review */}
        <div id="menu-review" className="menu menu-box-modal rounded-m" data-menu-height={445} data-menu-width={320}>
          <div className="menu-title"><h1>Leave a Review</h1><p className="color-theme opacity-50">Choose your Preferences</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins" />
          <div className="content mb-0">
            <div className="input-style input-style-2">
              <span className="color-highlight input-style-1-active">Name</span>
              <em>(required)</em>
              <input className="form-control" type="name" placeholder="John Doe" />
            </div>
            <div className="input-style input-style-2">
              <span className="input-style-1-active">Would you recommend this product?</span>
              <em><i className="fa fa-angle-down" /></em>
              <select className="form-control">
                <option value="default" >Maybe</option>
                <option value="default">Yes</option>
                <option value="default">No</option>
              </select>
            </div>
            <div className="input-style input-style-2">
              <span className="input-style-1-active">Give us your Feedback</span>
              <em>(required)</em>
              <textarea className="form-control" defaultValue={""} />
            </div>
            <div className="form-check icon-check mt-3 mb-3">
              <input className="form-check-input" type="checkbox" defaultValue id="check1" />
              <label className="form-check-label" htmlFor="check1">I have read and accept the review <a >Terms and Conditions</a> </label>
              <i className="icon-check-1 far fa-square color-blue2-dark font-16" />
              <i className="icon-check-2 fa fa-check-square font-16 color-blue2-dark" />
            </div>
            <a className="close-menu btn btn-m font-800 rounded-sm btn-full text-uppercase bg-blue2-dark">Post Review</a>
          </div>
        </div>
        {/* menu-wishlist */}
        <div id="menu-wishlist" className="menu menu-box-modal rounded-m" data-menu-height={420} data-menu-width={320}>
          <div className="menu-title"><h1>Wishlist</h1><p className="color-theme opacity-50">Your Current Cart Products</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins" />
          <div className="content mb-0">
            <div>
              <div className="d-flex mb-4">
                <div><img src="images/pictures/9s.jpg" className="rounded-m shadow-xl" width={80} /></div>
                <div className="ml-3">
                  <h5 className="font-600">Your awesome long or short product title</h5>
                  <h1 className="font-14 color-blue2-dark">$99.<sup>99</sup></h1>
                </div>
              </div>
            </div>
          </div>
          <div className="content mb-0">
            <div>
              <div className="d-flex mb-4">
                <div><img src="images/pictures/13s.jpg" className="rounded-m shadow-xl" width={80} /></div>
                <div className="ml-3">
                  <h5 className="font-600">Macbook Pro 13" Core i7, 16GB, 512 SSD</h5>
                  <h1 className="font-14 color-blue2-dark">$1499.<sup>99</sup></h1>
                </div>
              </div>
            </div>
          </div>
          <div className="divider divider-margins" />
          <div className="content">
            <a className="close-menu btn btn-full btn-m font-800 rounded-sm text-uppercase bg-green1-dark">View and Edit Wishlist</a>
          </div>
        </div>
        {/*-----------------*/}
        {/* Finance Actions */}
        {/*-----------------*/}
        {/* menu-credit-card */}
        <div id="menu-credit-card" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={420}>
          <div className="menu-title"><h1>Credit Card</h1><p className="color-highlight">Your Current Credit Card</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mt-3" />
          <div data-card-height={210} className="card card-style rounded-m shadow-xl">
            <div className="card-top text-left mt-4 ml-3">
              <h1 className="color-white text-shadow-l font-24">Enabled</h1>
            </div>
            <div className="card-center text-center">
              <p className="color-white font-20 bold uppercase line-height-small text-uppercase text-shadow-l font-10 mb-0 pb-0 mt-n2">1234 5678 9012 3456</p>
            </div>
            <div className="card-top mt-4 mr-3">
              <i className="fab fa-cc-amex text-shadow-l color-white font-30 mb-1 float-right" />
            </div>
            <div className="card-bottom mb-4 mr-3">
              <div className="text-right text-shadow-l">
                <p className="color-white font-10 font-800 text-uppercase text-shadow-large font-10 opacity-50 text-uppercase mb-n2">Valid Thru</p>
                <p className="color-white font-10 font-700 text-uppercase text-shadow-large font-16 mb-0 pb-0">03/2023</p>
              </div>
            </div>
            <div className="card-bottom line-height-small">
              <div className="text-left ml-3 mb-4 text-shadow-l">
                <p className="color-white font-10 font-800 text-uppercase text-shadow-large font-10 opacity-50 text-uppercase mb-n2">Card Holder</p>
                <p className="color-white font-10 font-700 text-uppercase text-shadow-large font-16 mb-0 pb-0">John Doe</p>
              </div>
            </div>
            <div className="card-overlay bg-highlight" />
            <div className="card-overlay bg-black opacity-20" />
            <div className="card-overlay bg-gradient opacity-70" />
          </div>
          <div className="content">
            <div className="row mb-0">
              <div className="col-6">
                <a className="close-menu btn btn-m btn-full rounded-sm border-highlight color-highlight font-700 text-uppercase">Remove Card</a>
              </div>
              <div className="col-6">
                <a className="close-menu btn btn-m btn-full rounded-sm bg-blue2-dark font-700 text-uppercase">Add Funds</a>
              </div>
              <div className="col-12 text-center">
                <a className="py-3 d-block opacity-30 color-theme opacity-50 font-10">Report Stolen Credit Card</a>
              </div>
            </div>
          </div>
        </div>
        {/* menu-credit-card-2 */}
        <div id="menu-credit-card-2" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={360}>
          <div data-card-height={210} className="card card-style rounded-m shadow-xl mt-4">
            <div className="card-top text-left mt-4 ml-3">
              <h1 className="color-white text-shadow-l font-24">Enabled</h1>
            </div>
            <div className="card-center text-center">
              <p className="color-white font-20 bold uppercase line-height-small text-uppercase text-shadow-l font-10 mb-0 pb-0 mt-n2">1234 5678 9012 3456</p>
            </div>
            <div className="card-top mt-4 mr-3">
              <i className="fab fa-cc-amex text-shadow-l color-white font-30 mb-1 float-right" />
            </div>
            <div className="card-bottom mb-4 mr-3">
              <div className="text-right text-shadow-l">
                <p className="color-white font-10 font-800 text-uppercase text-shadow-large font-10 opacity-50 text-uppercase mb-n2">Valid Thru</p>
                <p className="color-white font-10 font-700 text-uppercase text-shadow-large font-16 mb-0 pb-0">03/2023</p>
              </div>
            </div>
            <div className="card-bottom line-height-small">
              <div className="text-left ml-3 mb-4 text-shadow-l">
                <p className="color-white font-10 font-800 text-uppercase text-shadow-large font-10 opacity-50 text-uppercase mb-n2">Card Holder</p>
                <p className="color-white font-10 font-700 text-uppercase text-shadow-large font-16 mb-0 pb-0">John Doe</p>
              </div>
            </div>
            <div className="card-overlay bg-blue2-dark" />
            <div className="card-overlay bg-black opacity-20" />
            <div className="card-overlay bg-gradient opacity-70" />
          </div>
          <div className="content">
            <div className="row mb-0">
              <div className="col-6">
                <a className="close-menu btn btn-m btn-full rounded-sm border-highlight color-highlight font-700 text-uppercase">Remove Card</a>
              </div>
              <div className="col-6">
                <a className="close-menu btn btn-m btn-full rounded-sm bg-blue2-dark font-700 text-uppercase">Add Funds</a>
              </div>
              <div className="col-12 text-center">
                <a className="py-3 d-block opacity-50 color-theme font-10">Report Stolen Credit Card</a>
              </div>
            </div>
          </div>
        </div>
        {/* menu-card-list */}
        <div id="menu-card-list" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={380}>
          <div className="menu-title"><h1>Your Cards</h1><p className="color-highlight">Verifier Credit Cards Added</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mt-3" />
          <div className="content mt-0">
            <a data-menu="menu-credit-card" className="d-flex">
              <div className="align-self-center">
                <img src="images/framework/pay-2.png" width={50} />
              </div>
              <div className="align-self-center pl-3">
                <h5 className="mb-n2">MasterCard</h5>
                <p className="mt-n1 mb-0 font-10">**** 1535</p>
              </div>
              <div className="align-self-center ml-auto pl-3">
                <h5 className="mb-0">$1553,12</h5>
              </div>
            </a>
            <div className="divider mt-2 mb-2" />
            <a data-menu="menu-credit-card" className="d-flex">
              <div className="align-self-center">
                <img src="images/framework/pay-4.png" width={50} />
              </div>
              <div className="align-self-center pl-3">
                <h5 className="mb-n2">Visa Electron</h5>
                <p className="mt-n1 mb-0 font-10">**** 3516</p>
              </div>
              <div className="align-self-center ml-auto pl-3">
                <h5 className="mb-0">$4125,35</h5>
              </div>
            </a>
            <div className="divider mt-2 mb-2" />
            <a data-menu="menu-credit-card" className="d-flex">
              <div className="align-self-center">
                <img src="images/framework/pay-1.png" width={50} />
              </div>
              <div className="align-self-center pl-3">
                <h5 className="mb-n2">Visa Cards</h5>
                <p className="mt-n1 mb-0 font-10">Default Card</p>
              </div>
              <div className="align-self-center ml-auto pl-3">
                <h5 className="mb-0">$8178,15</h5>
              </div>
            </a>
            <div className="divider mt-3" />
            <a className="close-menu btn btn-m btn-full rounded-sm bg-highlight font-700 text-uppercase">Close Menu</a>
          </div>
        </div>
        {/* menu-card-list */}
        <div id="menu-wallet-info" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={280}>
          <div data-card-height={280} className="card mb-0 rounded-m shadow-xl preload-img" data-src="images/pictures/18w.jpg">
            <div className="card-top mt-4 ml-3">
              <h1 className="color-white mb-0 mb-n2">Karla Black</h1>
              <p className="bottom-0 color-white opacity-50 under-heading font-12">1234 5678 9012 3456</p>
            </div>
            <div className="card-top mt-4 mr-3">
              <a className="mt-1 float-right text-uppercase font-900 font-11 btn btn-s rounded-s shadow-l bg-highlight">Add Funds</a>
            </div>
            <div className="card-center pt-5 mt-2 text-center">
              <h1 className="color-white fa-4x">$8.178</h1>
              <p className="color-white opacity-70 font-11">Remaining Account Ballance</p>
            </div>
            <div className="card-bottom">
              <p className="ml-3 mb-2 font-8 font-500 opacity-50 color-white">Exp: 10/22</p>
            </div>
            <div className="card-bottom">
              <p className="text-right mr-3 mb-2 font-8 font-500 opacity-50 color-white"><i className="fa fa-wifi font-10 rotate-90" /></p>
            </div>
            <div className="card-overlay bg-black opacity-70 rounded-m" />
          </div>
        </div>
        {/* menu-payment-info */}
        <div id="menu-payment-info" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={405}>
          <div className="menu-title"><h1>Water Bill</h1><p className="color-highlight">Transaction Details for Payment</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-1 mt-3" />
          <div className="content">
            <div className="row mb-0">
              <div className="col-2">
                <span className="icon icon-xl rounded-xl bg-blue2-dark"><i className="fa fa-tint font-30" /></span>
              </div>
              <div className="col-10 pl-4">
                <div className="d-flex">
                  <div><p className="font-700 color-theme pl-2">Service</p></div>
                  <div className="ml-auto"><p>Utility Expenses</p></div>
                </div>
                <div className="d-flex">
                  <div><p className="font-700 color-theme pl-2">Date</p></div>
                  <div className="ml-auto"><p>15th July 2025</p></div>
                </div>
              </div>
            </div>
            <div className="divider mt-3 mb-3" />
            <div className="row mb-0">
              <div className="col-6"><h4 className="font-14">Details</h4></div>
              <div className="col-6"><h4 className="font-14 text-right">Monthly Subscription</h4></div>
              <div className="divider divider-margins w-100 mt-2 mb-2" />
              <div className="col-6"><h4 className="font-14 mt-1">Amount</h4></div>
              <div className="col-6"><h4 className="font-14 text-right mt-1">$9.99 / month</h4></div>
              <div className="divider divider-margins w-100 mt-2 mb-2" />
              <div className="col-6"><h4 className="font-14 mt-1">Transaction ID</h4></div>
              <div className="col-6"><h4 className="font-14 text-right mt-1">#987-456-165</h4></div>
              <div className="divider divider-margins w-100 mt-2 mb-3" />
              <div className="col-12"><a className="close-menu btn btn-full btn-m bg-blue2-dark rounded-sm text-uppercase font-800">Download Invoice</a></div>
            </div>
          </div>
        </div>
        {/* menu-subscription-info */}
        <div id="menu-subscription-info" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={405}>
          <div className="menu-title"><h1>Apple Music</h1><p className="color-theme opacity-50">Monthly Renewal Service</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-1 mt-3" />
          <div className="content">
            <div className="row mb-0">
              <div className="col-2">
                <span className="icon icon-xl rounded-xl bg-gray2-dark"><i className="fab fa-apple font-30" /></span>
              </div>
              <div className="col-10 pl-4">
                <div className="d-flex">
                  <div><p className="font-700 color-theme pl-2">Service</p></div>
                  <div className="ml-auto"><p>Apple Music</p></div>
                </div>
                <div className="d-flex">
                  <div><p className="font-700 color-theme pl-2">Date</p></div>
                  <div className="ml-auto"><p>15th July 2025</p></div>
                </div>
              </div>
            </div>
            <div className="divider mt-3 mb-3" />
            <div className="row mb-0">
              <div className="col-6"><h4 className="font-14">Details</h4></div>
              <div className="col-6"><h4 className="font-14 text-right">Monthly Subscription</h4></div>
              <div className="divider divider-margins w-100 mt-2 mb-2" />
              <div className="col-6"><h4 className="font-14 mt-1">Amount</h4></div>
              <div className="col-6"><h4 className="font-14 text-right mt-1">$9.99 / month</h4></div>
              <div className="divider divider-margins w-100 mt-2 mb-2" />
              <div className="col-6"><h4 className="font-14 mt-1">Transaction ID</h4></div>
              <div className="col-6"><h4 className="font-14 text-right mt-1">#987-456-165</h4></div>
              <div className="divider divider-margins w-100 mt-2 mb-3" />
              <div className="col-12"><a className="close-menu btn btn-full btn-m bg-red2-dark rounded-sm text-uppercase font-800">Cancel Subscription</a></div>
            </div>
          </div>
        </div>
        {/* menu-add-funds*/}
        <div id="menu-add-funds" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={395}>
          <div className="menu-title"><h1>Add Funds</h1><p className="color-green1-dark">Top off your Account</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-1 mt-3" />
          <div className="content px-1">
            <div className="input-style input-style-1 input-required">
              <h5 className="font-700 font-11 opacity-30 text-uppercase mb-n3">Add Funds To</h5>
              <em><i className="fa fa-angle-down" /></em>
              <select className="font-12">
                <option value={1} >Main Account</option>
                <option value={2}>Savings Account</option>
              </select>
            </div>
            <div className="input-style input-style-1 input-required">
              <h5 className="font-700 font-11 opacity-30 text-uppercase mb-n3">Add Funds From</h5>
              <em><i className="fa fa-angle-down" /></em>
              <select className="font-12">
                <option value={1} >Visa Credit Card</option>
                <option value={2}>Mastercard Credit Card</option>
              </select>
            </div>
            <div className="input-style input-style-1 input-required mb-4">
              <h5 className="font-700 font-11 opacity-30 text-uppercase mb-n3">Enter Amount</h5>
              <em>(required)</em>
              <input type="email" className="pl-0" placeholder={1000} />
            </div>
            <a className="close-menu btn btn-full btn-m bg-green1-dark rounded-sm text-uppercase font-800">Add Funds</a>
          </div>
        </div>
        {/* menu-send-funds */}
        <div id="menu-send-funds" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={390}>
          <div className="menu-title"><h1>Send Funds</h1><p className="color-blue2-dark">Enter Transaction Details</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mt-3 mb-3" />
          <div className="content px-1">
            <div className="input-style input-style-1 input-required">
              <h5 className="font-700 font-11 opacity-30 text-uppercase mb-n3">Select Account</h5>
              <em><i className="fa fa-angle-down" /></em>
              <select className="font-12">
                <option value={1} >Default Account</option>
                <option value={2}>Savings Account</option>
              </select>
            </div>
            <div className="input-style input-style-1 input-required">
              <h5 className="font-700 font-11 opacity-30 text-uppercase mb-n3">Receiver Email</h5>
              <em>(required)</em>
              <input type="email" className="pl-0" placeholder="name@domain.com" />
            </div>
            <div className="input-style input-style-1 input-required mb-4">
              <h5 className="font-700 font-11 opacity-30 text-uppercase mb-n3">Amount in USD</h5>
              <em>(required)</em>
              <input type="name" className="form-control pl-0" placeholder="12,250" min={1} max={99999999999} />
            </div>
            <a className="close-menu btn btn-full btn-m bg-blue2-dark rounded-sm text-uppercase font-800">Send Funds</a>
          </div>
        </div>
        {/* menu-request-funds */}
        <div id="menu-request-funds" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={320}>
          <div className="menu-title"><h1>Request Funds</h1><p className="color-highlight">Enter Transaction Details</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mt-3 mb-2" />
          <div className="content px-1">
            <div className="input-style input-style-1 input-required">
              <h5 className="font-700 font-11 opacity-30 text-uppercase mb-n3">Request From</h5>
              <em>(required)</em>
              <input type="email" className="pl-0" placeholder="name@domain.com" />
            </div>
            <div className="input-style input-style-1 input-required mb-4">
              <h5 className="font-700 font-11 opacity-30 text-uppercase mb-n3">Enter amount</h5>
              <em>(required)</em>
              <input type="email" className="pl-0" placeholder={1000} />
            </div>
            <a className="close-menu btn btn-full btn-m bg-red2-dark rounded-sm text-uppercase font-800">Request Funds</a>
          </div>
        </div>
        {/* menu-payment-status */}
        <div id="menu-payment-status" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={480}>
          <div className="menu-title"><h1>Payment Sent</h1><p className="color-theme opacity-40">Transaction Details for Payment</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-1 mt-3" />
          <div className="content">
            <div className="row mb-0">
              <div className="col-3">
                <img src="images/pictures/faces/1s.png" width={80} className="rounded-xl" />
              </div>
              <div className="col-9 pl-4">
                <div className="d-flex">
                  <div><p className="font-700 color-theme">To</p></div>
                  <div className="ml-auto"><p>John Doe</p></div>
                </div>
                <div className="d-flex">
                  <div><p className="font-700 color-theme">From</p></div>
                  <div className="ml-auto"><p> Card **** 9431</p></div>
                </div>
                <div className="d-flex">
                  <div><p className="font-700 color-theme">Date</p></div>
                  <div className="ml-auto"><p>15th July 2025</p></div>
                </div>
              </div>
            </div>
            <div className="divider mt-3 mb-3" />
            <div className="row mb-0">
              <div className="col-6"><h4 className="font-14">Type</h4></div>
              <div className="col-6"><h4 className="font-14 text-right">Payment Sent</h4></div>
              <div className="divider divider-margins w-100 mt-2 mb-2" />
              <div className="col-6"><h4 className="font-14 mt-1">Amount</h4></div>
              <div className="col-6"><h4 className="font-14 text-right mt-1">$530.24</h4></div>
              <div className="divider divider-margins w-100 mt-2 mb-2" />
              <div className="col-6"><h4 className="font-14 mt-1">Transaction ID</h4></div>
              <div className="col-6"><h4 className="font-14 text-right mt-1">#123-456-165</h4></div>
              <div className="divider divider-margins w-100 mt-2 mb-2" />
              <div className="col-6"><h4 className="font-14 mt-1">Status</h4></div>
              <div className="col-6"><h4 className="font-14 text-right mt-1 color-green1-dark">Completed</h4></div>
              <div className="divider divider-margins w-100 mt-2 mb-3" />
              <div className="col-12"><a className="close-menu btn btn-full btn-m bg-blue2-dark rounded-sm text-uppercase font-800">Download PDF</a></div>
            </div>
          </div>
        </div>
        {/*-------------------*/}
        {/* Education Actions */}
        {/*-------------------*/}
        {/* menu-table-of-contents */}
        <div id="menu-table-of-contents" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={370}>
          <div className="menu-title"><h1>Table of Contents</h1><p className="color-highlight">Select the Chapter you wisht to View</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-n2" />
          <div className="content mt-2">
            <div className="list-group list-custom-small pl-1">
              <a data-menu="menu-chapter-1" >
                <span>Chapter 1: Introduction</span>
                <i className="fa fa-angle-right" />
              </a>
              <a >
                <span>Chapter 2: Ingredients</span>
                <i className="fa fa-link" />
              </a>
              <a >
                <span>Chapter 3: Preparation</span>
                <i className="fa fa-link" />
              </a>
              <a >
                <span>Chapter 4: Conclusion</span>
                <i className="fa fa-link" />
              </a>
            </div>
            <a className="close-menu btn btn-full btn-m rounded-sm bg-highlight font-800 text-uppercase mt-4">Close Menu</a>
          </div>
        </div>
        {/* menu-chapter-1 */}
        <div id="menu-chapter-1" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={370}>
          <div className="menu-title"><h1>Chapter 1: Introduction</h1><p className="color-highlight">5 Lectures - 2 Hours 50 Minutes </p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-n2" />
          <div className="content">
            <div className="d-flex mb-3">
              <div className="align-self-center">
                <a className="mb-0 color-theme opacity-60 font-14"><i className="fa color-green1-dark fa-check-circle pr-2 " />Welcome</a>
              </div>
              <div className="align-self-center ml-auto">
                <h6 className="mb-0 font-11 badge bg-green1-dark">COMPLETE</h6>
              </div>
            </div>
            <div className="d-flex mb-3">
              <div className="align-self-center">
                <a className="mb-0 color-theme opacity-60 font-14"><i className="fa color-green1-dark fa-check-circle pr-2 " />Tools Required</a>
              </div>
              <div className="align-self-center ml-auto">
                <h6 className="mb-0 font-11 badge bg-green1-dark">COMPLETE</h6>
              </div>
            </div>
            <div className="d-flex mb-3">
              <div className="align-self-center">
                <a className="mb-0 color-theme opacity-60 font-14"><i className="fa color-blue2-dark fa-clock pr-2 " />Ingredients Required</a>
              </div>
              <div className="align-self-center ml-auto">
                <h6 className="mb-0 font-11 badge bg-blue2-dark">IN PROGRESS</h6>
              </div>
            </div>
            <div className="d-flex mb-3">
              <div className="align-self-center">
                <a className="mb-0 color-theme opacity-60 font-14"><i className="fa fa-play-circle pr-2 " />Tools Required</a>
              </div>
              <div className="align-self-center ml-auto">
                <h6 className="mb-0 font-11 opacity-50">34 Minutes</h6>
              </div>
            </div>
            <div className="d-flex mb-3">
              <div className="align-self-center">
                <a className="mb-0 color-theme opacity-60 font-14"><i className="fa fa-play-circle pr-2 " />Start Mixing Ingedients</a>
              </div>
              <div className="align-self-center ml-auto">
                <h6 className="mb-0 font-11 opacity-50">1 Hour 34 Minutes</h6>
              </div>
            </div>
            <a data-menu="menu-table-of-contents" className="btn btn-full btn-m rounded-sm bg-highlight font-800 text-uppercase mt-4">Back to Table of Contents</a>
          </div>
        </div>
        {/* menu-subscription-required */}
        <div id="menu-subscription-required" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={385}>
          <div className="menu-title"><h1>Premium Required</h1><p className="color-highlight">Go Unlimited and Unlock this Content</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="card card-style mx-0 rounded-0 bg-14 mt-3 mb-0" data-card-height={300}>
            <div className="card-center pl-3">
              <h1 className="color-white font-28 mb-0">Subscribe for</h1>
              <h1 className="color-white font-31">to Unlimted</h1>
              <h1 className="color-white font-20 mt-n2">Books and Courses</h1>
              <a className="btn btn-m rounded-sm bg-highlight color-white mt-3 text-uppercase font-800">Start your Trial Now</a>
              <p className="mb-0 color-white opacity-60 font-11 mt-2">30 Day Free Trial then 9.99 / month</p>
            </div>
            <div className="card-overlay bg-black opacity-60" />
          </div>
        </div>
        {/* menu-buy-course */}
        <div id="menu-buy-course" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={510}>
          <div className="responsive-iframe"><iframe src="https://www.youtube.com/embed/c9MnSeYYtYY" allowFullScreen /></div>
          <div className="content">
            <div className="d-flex">
              <div className="align-self-center">
                <h3 className="pr-4">Create videos using your smartphone.</h3>
              </div>
              <div className="ml-auto align-self-center">
                <h1 className="font-32 font-800">$13.99</h1>
                <h5 className="font-13"><del>$27.98</del> - 50% OFF</h5>
              </div>
            </div>
            <div className="divider mt-2 mb-4" />
            <ul className="icon-list">
              <li><i className="fa fa-play-circle" style={{ width: '25px' }} /> 3 Hour Video + 30 PDF Articles</li>
              <li><i className="fa fa-star" style={{ width: '25px' }} /> 13 Articles with Downloadable Content</li>
              <li><i className="fa fa-cloud" style={{ width: '25px' }} /> 30 Day Money-Back Guarantee</li>
            </ul>
            <div className="divider mt-4" />
            <div className="row mb-0">
              <div className="col-7">
                <a className="btn btn-full btn-m rounded-sm border-highlight color-highlight font-800 text-uppercase">Add to Cart</a>
              </div>
              <div className="col-5">
                <a className="btn btn-full btn-m rounded-sm bg-highlight font-800 text-uppercase">Buy NOW</a>
              </div>
            </div>
          </div>
        </div>
        {/* menu-course-details */}
        <div id="menu-course-details" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={430}>
          <div className="menu-title"><h1>Course Details</h1><p className="color-highlight">Full Information About Our Course</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-4" />
          <div className="content">
            <div className="d-flex">
              <div className="align-self-center">
                <img src="images/pictures/6s.jpg" className="img-fluid" />
              </div>
              <div className="ml-auto align-self-center">
                <h3 className="pl-3 pr-4">Create videos using your smartphone.</h3>
                <h5 className="pl-3 font-12 opacity-30">15 Courses - 2 hours 25 Minutes</h5>
              </div>
            </div>
            <div className="divider mt-4 mb-4" />
            <ul className="icon-list">
              <li><i className="fa fa-play-circle" style={{ width: '25px' }} /> 3 Hour Video + 30 PDF Articles</li>
              <li><i className="fa fa-star" style={{ width: '25px' }} /> 13 Articles with Downloadable Content</li>
              <li><i className="fa fa-cloud" style={{ width: '25px' }} /> 30 Day Money-Back Guarantee</li>
            </ul>
            <div className="divider" />
            <a className="close-menu btn btn-full btn-m rounded-sm bg-highlight font-800 text-uppercase">Close Menu</a>
          </div>
        </div>
        {/* menu-author-details */}
        <div id="menu-author-details" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={370}>
          <div className="menu-title"><h1>Author Details</h1><p className="color-highlight">Premium Verified Author Account</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-4" />
          <div className="content">
            <div className="d-flex">
              <div className="align-self-center">
                <img src="images/pictures/faces/2s.png" width={50} />
              </div>
              <div className="align-self-center">
                <h3 className="pl-3 pr-4 mb-0">John Doe</h3>
                <h5 className="pl-3 font-12 font-400"><i className="fa fa-check-circle color-blue2-dark" /> Verified Writer</h5>
              </div>
            </div>
            <div className="divider mt-4 mb-4" />
            <div className="d-flex text-center mx-3">
              <div className="mr-auto">
                <h1 className="font-24">34</h1>
                <p className="font-11 line-height-xs">Published</p>
              </div>
              <div className="mr-auto ml-auto">
                <h1 className="font-24">134</h1>
                <p className="font-11 line-height-xs">Thesis</p>
              </div>
              <div className="ml-auto">
                <h1 className="font-24">321</h1>
                <p className="font-11 line-height-xs">Followers</p>
              </div>
            </div>
            <div className="divider mt-4" />
            <a className="btn btn-full btn-m rounded-sm bg-highlight font-800 text-uppercase">View full Profile</a>
          </div>
        </div>
        {/*--------------*/}
        {/* Task Actions */}
        {/*--------------*/}
        {/* menu-upload */}
        <div id="menu-upload" className="menu menu-box-modal rounded-m" data-menu-height={255} data-menu-width={320}>
          <div className="list-group list-custom-small pl-2 mr-4">
            <a >
              <i className="font-14 fa fa-file color-gray2-dark" />
              <span className="font-13">File</span>
              <i className="fa fa-angle-right" />
            </a>
            <a >
              <i className="font-14 fa fa-image color-gray2-dark" />
              <span className="font-13">Photo</span>
              <i className="fa fa-angle-right" />
            </a>
            <a >
              <i className="font-14 fa fa-video color-gray2-dark" />
              <span className="font-13">Video</span>
              <i className="fa fa-angle-right" />
            </a>
            <a >
              <i className="font-14 fa fa-user color-gray2-dark" />
              <span className="font-13">Camera</span>
              <i className="fa fa-angle-right" />
            </a>
            <a >
              <i className="font-14 fa fa-map-marker color-gray2-dark" />
              <span className="font-13">Location</span>
              <i className="fa fa-angle-right" />
            </a>
          </div>
        </div>
        {/* menu-manage */}
        <div id="menu-manage" className="menu menu-box-modal rounded-m" data-menu-height={230} data-menu-width={320}>
          <div className="menu-title"><h1>Manage Project </h1><p className="color-theme opacity-40">Manage your Project Details</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-n2" />
          <div className="content mt-2">
            <div className="list-group list-custom-large">
              <a data-menu="menu-team">
                <i className="fa font-14 fa-user bg-green1-dark rounded-s" />
                <span>Team</span>
                <strong>Assign Members</strong>
                <i className="fa fa-angle-right" />
              </a>
              <a data-menu="menu-dates" className="border-0">
                <i className="fa font-14 fa-cog bg-blue2-dark rounded-s" />
                <span>Dates</span>
                <strong>Project Timeframe</strong>
                <i className="fa fa-angle-right" />
              </a>
            </div>
          </div>
        </div>
        {/* menu-team */}
        <div id="menu-team" className="menu menu-box-modal rounded-m" data-menu-height={360} data-menu-width={320}>
          <div className="menu-title"><h1>Manage Team </h1><p className="color-theme opacity-40">Manage your Project Details</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-n2" />
          <div className="content mt-2">
            <div className="list-group list-custom-small">
              <a data-menu="menu-member">
                <img src="images/pictures/faces/1small.png" width={35} className="rounded-sm mr-2" />
                <span>John Doe</span>
                <strong className="badge bg-green1-dark">YOU</strong>
                <i className="fa fa-angle-right" />
              </a>
              <a data-menu="menu-member">
                <img src="images/pictures/faces/2small.png" width={35} className="rounded-sm mr-2" />
                <span>James Bond</span>
                <strong className="badge bg-yellow1-dark">FRONT END</strong>
                <i className="fa fa-angle-right" />
              </a>
              <a data-menu="menu-member">
                <img src="images/pictures/faces/4small.png" width={35} className="rounded-sm mr-2" />
                <span>Jack Sir</span>
                <strong className="badge bg-blue2-dark">GRAPHIC DESIGN</strong>
                <i className="fa fa-angle-right" />
              </a>
              <a data-menu="menu-member">
                <img src="images/pictures/faces/3small.png" width={35} className="rounded-sm mr-2" />
                <span>Jack Son</span>
                <strong className="badge bg-red2-dark">SERVER LANGUAGE</strong>
                <i className="fa fa-angle-right" />
              </a>
            </div>
            <a data-menu="menu-manage" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-3">Back to Settings</a>
          </div>
        </div>
        {/* menu-member */}
        <div id="menu-member" className="menu menu-box-modal rounded-m" data-menu-height={360} data-menu-width={320}>
          <div className="menu-title"><h1>John Doe </h1><p className="color-theme opacity-40">Manage Permissions</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-n2" />
          <div className="content mt-2">
            <div className="list-group list-custom-small">
              <a data-trigger-switch="switch-1">
                <i className="fa fa-upload bg-gray2-dark rounded-sm ml-0" />
                <span>Upload Files</span>
                <div className="custom-control small-switch ios-switch">
                  <input type="checkbox" className="ios-input" id="switch-1" defaultChecked />
                  <label className="custom-control-label" htmlFor="switch-1" />
                </div>
              </a>
              <a data-trigger-switch="switch-1">
                <i className="fa fa-download bg-blue2-dark rounded-sm ml-0" />
                <span>Download Files</span>
                <div className="custom-control small-switch ios-switch">
                  <input type="checkbox" className="ios-input" id="switch-1" defaultChecked />
                  <label className="custom-control-label" htmlFor="switch-1" />
                </div>
              </a>
              <a data-trigger-switch="switch-2">
                <i className="fa fa-check bg-green1-dark rounded-sm ml-0" />
                <span>Complete Task</span>
                <div className="custom-control small-switch ios-switch">
                  <input type="checkbox" className="ios-input" id="switch-2" />
                  <label className="custom-control-label" htmlFor="switch-2" />
                </div>
              </a>
              <a data-trigger-switch="switch-2">
                <i className="fa fa-plus bg-brown2-dark rounded-sm ml-0" />
                <span>Add New Members</span>
                <div className="custom-control small-switch ios-switch">
                  <input type="checkbox" className="ios-input" id="switch-2" />
                  <label className="custom-control-label" htmlFor="switch-2" />
                </div>
              </a>
            </div>
            <a data-menu="menu-team" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-3">Back to Members</a>
          </div>
        </div>
        {/* menu-dates */}
        <div id="menu-dates" className="menu menu-box-modal rounded-m" data-menu-height={360} data-menu-width={320}>
          <div className="menu-title"><h1>Dates </h1><p className="color-theme opacity-40">Project Deadlines</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-4" />
          <div className="content mt-2">
            <div className="input-style input-style-2">
              <span className="input-style-1-active">Project Deadline</span>
              <em><i className="fa fa-angle-down" /></em>
              <input type="date" defaultValue="1980-08-26" />
            </div>
            <div className="input-style input-style-2">
              <span className="input-style-1-active">Project Priority</span>
              <em><i className="fa fa-angle-down" /></em>
              <select>
                <option value="a" >Low</option>
                <option value="b">Medium</option>
                <option value="c">High</option>
                <option value="d">Critical</option>
              </select>
            </div>
            <div className="input-style input-style-2">
              <span className="input-style-1-active">Project Status</span>
              <em><i className="fa fa-angle-down" /></em>
              <select>
                <option value={1}>Planing</option>
                <option value={2} >Developing</option>
                <option value={3}>Complete</option>
              </select>
            </div>
            <a data-menu="menu-manage" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-3">Back to Members</a>
          </div>
        </div>
        {/*--------------*/}
        {/* Food Actions */}
        {/*--------------*/}
        {/* menu-reservation */}
        <div id="menu-reservation" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={428}>
          <div className="menu-title"><h1>Make a Reservation</h1><p className="color-highlight">We'll make the preparations.</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-1" />
          <div className="content">
            <div className="input-style input-style-2 input-required">
              <span className="color-highlight">Name</span>
              <em>(required)</em>
              <input className="form-control" type="name" />
            </div>
            <div className="input-style input-style-2 input-required">
              <span className="color-highlight">Phone</span>
              <em>(required)</em>
              <input className="form-control" type="tel" />
            </div>
            <div className="input-style input-style-2 input-required">
              <span className="color-highlight">Date</span>
              <em>(required)</em>
              <input className="form-control" type="date" />
            </div>
            <div className="input-style input-style-2 input-required">
              <span>Party Size</span>
              <em><i className="fa fa-angle-down" /></em>
              <select className="form-control">
                <option value="default" disabled >Size of Party</option>
                <option value="iOS">1</option>
                <option value="Linux">2</option>
                <option value="MacOS">3</option>
                <option value="Android">4</option>
                <option value="Windows">5</option>
              </select>
            </div>
            <a className="btn btn-m btn-full rounded-sm shadow-xl text-uppercase font-700 bg-red2-dark mt-4">Submit Reservation</a>
          </div>
        </div>
        {/* menu-hours */}
        <div id="menu-hours" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={350}>
          <div className="menu-title"><h1>Working Hours</h1><p className="color-highlight">Flexible and Easy to Use</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mb-2 mt-3" />
          <div className="card mb-0">
            <div data-card-height={0} data-closed-message="Sorry! We're closed!" data-closed-message-under="We'll be back very soon!" data-opened-message="Welcome! We're open!" data-opened-message-under="Call us Now! Let's have a chat!" className="business-hours caption shadow-xl">
            </div>
            <div className="content mt-0">
              {/* To set open and Close Hours Open custom.js and search for "SET OPENING HOURS BELOW" */}
              {/* Only setting the code below will not make the system work automatically*/}
              <div className="working-hours day-monday">          <p>Monday</p>    <p>09:00 AM</p> <p>05:00 PM</p></div>
              <div className="working-hours day-tuesday">         <p>Tuesday</p>   <p>09:00 AM</p> <p>05:00 PM</p></div>
              <div className="working-hours day-wednesday">       <p>Wednesday</p> <p>09:00 AM</p> <p>05:00 PM</p></div>
              <div className="working-hours day-thursday">        <p>Thursday</p>  <p>09:00 AM</p> <p>05:00 PM</p></div>
              <div className="working-hours day-friday">          <p>Friday</p>    <p>09:00 AM</p> <p>05:00 PM</p></div>
            </div>
          </div>
        </div>
        {/* menu-cart */}
        <div id="menu-cart" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={420}>
          <div className="menu-title"><h1>Your Order</h1><p className="color-highlight">Your Current Cart Products</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins" />
          <div className="content mb-0">
            <div>
              <div className="d-flex mb-4">
                <div><img src="images/food/full/1s.jpg" className="rounded-m shadow-xl" width={80} /></div>
                <div className="ml-3">
                  <h5 className="font-600">Royal Hamburger with Extra Sauce and Onion Rings</h5>
                  <h1 className="font-20 color-highlight">29.<sup>99</sup></h1>
                </div>
              </div>
            </div>
          </div>
          <div className="content mb-0">
            <div>
              <div className="d-flex mb-4">
                <div><img src="images/food/full/2s.jpg" className="rounded-m shadow-xl" width={80} /></div>
                <div className="ml-3">
                  <h5 className="font-600">House Speciality Pizza with Mozzarella and Mushrooms</h5>
                  <h1 className="font-20 color-highlight">$29.<sup>99</sup></h1>
                </div>
              </div>
            </div>
            <div className="divider" />
            <div className="row mb-0">
              <div className="col-6"><a className="btn btn-full btn-m font-800 rounded-sm text-uppercase bg-green1-dark">Cart Page</a></div>
              <div className="col-6"><a className="btn btn-full btn-m font-800 rounded-sm text-uppercase bg-blue2-dark">Checkout</a></div>
            </div>
          </div>
        </div>
        {/* menu-cart-edit */}
        <div id="menu-cart-edit" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={420}>
          <div className="menu-title"><h1>Edit Product</h1><p className="color-highlight">Choose your Preferences</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins" />
          <div className="content mb-0">
            <div>
              <div className="d-flex mb-4">
                <div>
                  <img src="images/food/full/1s.jpg" className="rounded-m shadow-xl" width={120} />
                </div>
                <div className="ml-3">
                  <h5 className="font-600">Royal Burger With King Size Onion Extras</h5>
                  <h1 className="color-highlight">$29.<sup>99</sup></h1>
                  <a className="color-theme opacity-50 font-10"><i className="fa fa-times color-red2-dark pr-2 pt-3" />Remove</a>
                </div>
              </div>
            </div>
            <div className="row mb-2 pt-2">
              <div className="col-8">
                <div className="input-style input-style-2">
                  <span className="input-style-1-active">Select a Size</span>
                  <em><i className="fa fa-angle-down" /></em>
                  <select>
                    <option value={1}>Extra Small Size</option>
                    <option value={2}>Small Size</option>
                    <option value="default" >Medium Size</option>
                    <option value={4}>Large Size</option>
                    <option value={5}>Extra Large Size</option>
                  </select>
                </div>
              </div>
              <div className="col-4">
                <div className="input-style input-style-2">
                  <span className="input-style-1-active">Qty</span>
                  <input type="number" min={1} max={999} style={{ borderWidth: '1px' }} placeholder={1} />
                </div>
              </div>
            </div>
            <div className="divider mb-4" />
            <div className="row mb-0">
              <div className="col-5">
                <a className="close-menu btn  btn-m font-800 rounded-sm btn-full text-uppercase bg-red2-dark">Close</a>
              </div>
              <div className="col-7">
                <a data-menu="menu-ingredients" className="btn  btn-m font-800 rounded-sm btn-full text-uppercase bg-blue2-dark">Edit Ingredients</a>
              </div>
            </div>
          </div>
        </div>
        {/* menu-ingredients */}
        <div id="menu-ingredients" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={510}>
          <div className="content">
            <img src="images/food/full/1w.jpg" className="img-fluid rounded-m mb-4" />
            <h3>Add or Remove Ingredients</h3>
            <p className="mt-n1">
              Customise your burger just the way you like it!
                </p>
            <div className="row mt-3">
              <div className="col-6">
                <div className="form-check icon-check">
                  <input className="form-check-input" type="checkbox" defaultValue id="check1" defaultChecked />
                  <label className="form-check-label" htmlFor="check1">Lettuce</label>
                  <i className="icon-check-1 fa fa-square color-gray-dark font-18" />
                  <i className="icon-check-2 fa fa-check-square font-18 color-highlight" />
                </div>
                <div className="form-check icon-check">
                  <input className="form-check-input" type="checkbox" defaultValue id="check2" defaultChecked />
                  <label className="form-check-label" htmlFor="check2">Onion Rings</label>
                  <i className="icon-check-1 fa fa-square color-gray-dark font-18" />
                  <i className="icon-check-2 fa fa-check-square font-18 color-highlight" />
                </div>
                <div className="form-check icon-check">
                  <input className="form-check-input" type="checkbox" defaultValue id="check3" defaultChecked />
                  <label className="form-check-label" htmlFor="check3">Bacon Slices</label>
                  <i className="icon-check-1 fa fa-square color-gray-dark font-18" />
                  <i className="icon-check-2 fa fa-check-square font-18 color-highlight" />
                </div>
                <div className="form-check icon-check">
                  <input className="form-check-input" type="checkbox" defaultValue id="check1a" />
                  <label className="form-check-label" htmlFor="check1a">Fries</label>
                  <i className="icon-check-1 fa fa-square color-gray-dark font-18" />
                  <i className="icon-check-2 fa fa-check-square font-18 color-highlight" />
                </div>
                <div className="form-check icon-check">
                  <input className="form-check-input" type="checkbox" defaultValue id="check2a" />
                  <label className="form-check-label" htmlFor="check2a">Lettuce</label>
                  <i className="icon-check-1 fa fa-square color-gray-dark font-18" />
                  <i className="icon-check-2 fa fa-check-square font-18 color-highlight" />
                </div>
              </div>
              <div className="col-6">
                <div className="form-check icon-check">
                  <input className="form-check-input" type="checkbox" defaultValue id="check4" defaultChecked />
                  <label className="form-check-label" htmlFor="check4">Cheese</label>
                  <i className="icon-check-1 fa fa-square color-gray-dark font-18" />
                  <i className="icon-check-2 fa fa-check-square font-18 color-highlight" />
                </div>
                <div className="form-check icon-check">
                  <input className="form-check-input" type="checkbox" defaultValue id="check5" defaultChecked />
                  <label className="form-check-label" htmlFor="check5">Salad</label>
                  <i className="icon-check-1 fa fa-square color-gray-dark font-18" />
                  <i className="icon-check-2 fa fa-check-square font-18 color-highlight" />
                </div>
                <div className="form-check icon-check">
                  <input className="form-check-input" type="checkbox" defaultValue id="check6" defaultChecked />
                  <label className="form-check-label" htmlFor="check6">Tomato</label>
                  <i className="icon-check-1 fa fa-square color-gray-dark font-18" />
                  <i className="icon-check-2 fa fa-check-square font-18 color-highlight" />
                </div>
                <div className="form-check icon-check">
                  <input className="form-check-input" type="checkbox" defaultValue id="check4a" />
                  <label className="form-check-label" htmlFor="check4a">Ham</label>
                  <i className="icon-check-1 fa fa-square color-gray-dark font-18" />
                  <i className="icon-check-2 fa fa-check-square font-18 color-highlight" />
                </div>
                <div className="form-check icon-check">
                  <input className="form-check-input" type="checkbox" defaultValue id="check5a" />
                  <label className="form-check-label" htmlFor="check5a">Tuna</label>
                  <i className="icon-check-1 fa fa-square color-gray-dark font-18" />
                  <i className="icon-check-2 fa fa-check-square font-18 color-highlight" />
                </div>
              </div>
            </div>
            <div className="divider" />
            <a data-menu="menu-cart-edit" className="btn btn-m bg-green1-dark btn-full rounded-sm shadow-xl text-uppercase font-700">Apply Changes</a>
          </div>
        </div>
        {/*-----------------*/}
        {/* Content Actions */}
        {/*-----------------*/}
        {/* menu-bookmarks */}
        <div id="menu-bookmarks" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={445}>
          <div className="menu-title"><h1>Bookmarks</h1><p className="color-highlight">Your Saved Articles</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mt-3 mb-0" />
          <div className="content">
            <a className="d-block pb-4">
              <div className="d-flex">
                <div>
                  <img src="images/pictures/28t.jpg" className="mr-3 rounded-m" width={110} />
                </div>
                <div>
                  <span className="badge bg-red2-dark text-uppercase px-2 py-1">NEws</span>
                  <h1 className="font-700 font-15 line-height-m pb-2">Privacy rules make it impossible to track you.</h1>
                  <span className="color-theme opacity-30 font-400 font-10"><i className="far fa-clock pr-2" />2 hours ago</span>
                  <span className="color-theme opacity-30 font-400 font-10 px-2"><i className="far fa-comment pr-2" />26 Comments</span>
                </div>
              </div>
            </a>
            <a className="d-block pb-4">
              <div className="d-flex">
                <div>
                  <img src="images/pictures/21t.jpg" className="mr-3 rounded-m" width={110} />
                </div>
                <div>
                  <span className="badge bg-blue2-dark text-uppercase px-2 py-1">NEws</span>
                  <h1 className="font-700 font-15 line-height-m pb-2">Technology now is in every part of our life.</h1>
                  <span className="color-theme opacity-30 font-400 font-10"><i className="far fa-clock pr-2" />3 Days ago</span>
                  <span className="color-theme opacity-30 font-400 font-10 px-2"><i className="far fa-comment pr-2" />26 Comments</span>
                </div>
              </div>
            </a>
            <a className="close-menu btn btn-m btn-full rounded-sm bg-highlight font-700 text-uppercase">Close Menu</a>
          </div>
        </div>
        {/* menu-author-1 */}
        <div id="menu-author-1" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={240}>
          <div className="menu-title"><h1>Alex Doeson</h1><p className="color-highlight">Senior Content Writer</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mt-3 mb-0" />
          <div className="content">
            <div className="d-flex">
              <div>
                <img src="images/pictures/14t.jpg" className="rounded-m" width={110} />
              </div>
              <div className="px-3 ml-auto w-100">
                <div className="d-flex">
                  <div className="mr-auto">
                    <a className="color-theme opacity-50">
                      <span className="font-11 d-block mb-n1">Articles</span>
                      <strong className="font-24">55</strong>
                    </a>
                  </div>
                  <div className="mx-auto">
                    <a className="color-theme opacity-50">
                      <span className="font-11 d-block mb-n1">Followers</span>
                      <strong className="font-24">95k</strong>
                    </a>
                  </div>
                  <div className="ml-auto">
                    <a className="color-theme opacity-50">
                      <span className="font-11 d-block mb-n1">Rating</span>
                      <strong className="font-24">4.9</strong>
                    </a>
                  </div>
                </div>
                <a className="btn btn-sm text-uppercase font-700 rounded-sm btn-full bg-blue2-dark mt-3">Follow</a>
              </div>
            </div>
          </div>
        </div>
        {/* menu-author-2 */}
        <div id="menu-author-2" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={540}>
          <div className="menu-title"><h1>About Author</h1><p className="color-highlight">Who wrote this post.</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mt-3 mb-0" />
          <img src="images/pictures/faces/2s.png" className="mx-auto shadow-xl rounded-circle over-card" width={150} />
          <div className="content">
            <h1 className="text-center font-32">John Doe</h1>
            <a className="color-highlight text-center d-block mt-n2 font-11 pb-3">Editor and Content Reviewer</a>
            <div className="text-center">
              <a className="icon icon-s mx-2 mb-4 rounded-circle bg-facebook"><i className="fab fa-facebook-f" /></a>
              <a className="icon icon-s mx-2 mb-4 rounded-circle bg-instagram"><i className="fab fa-instagram" /></a>
              <a className="icon icon-s mx-2 mb-4 rounded-circle bg-twitter"><i className="fab fa-twitter" /></a>
            </div>
            <em className="text-center font-20 font-300 d-block color-theme opacity-60 mb-3">I love everything that's sweet and sour in large portions with a heavy dose of exercise afterwards.</em>
            <a className="close-menu btn btn-m btn-full rounded-sm bg-highlight font-700 text-uppercase">Close Menu</a>
          </div>
        </div>
        {/* menu-author-1 */}
        <div id="menu-author-1" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={240}>
          <div className="menu-title"><h1>Alex Doeson</h1><p className="color-highlight">Senior Content Writer</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mt-3 mb-0" />
          <div className="content">
            <div className="d-flex">
              <div>
                <img src="images/pictures/14t.jpg" className="rounded-m" width={110} />
              </div>
              <div className="px-3 ml-auto w-100">
                <div className="d-flex">
                  <div className="mr-auto">
                    <a className="color-theme opacity-50">
                      <span className="font-11 d-block mb-n1">Articles</span>
                      <strong className="font-24">55</strong>
                    </a>
                  </div>
                  <div className="mx-auto">
                    <a className="color-theme opacity-50">
                      <span className="font-11 d-block mb-n1">Followers</span>
                      <strong className="font-24">95k</strong>
                    </a>
                  </div>
                  <div className="ml-auto">
                    <a className="color-theme opacity-50">
                      <span className="font-11 d-block mb-n1">Rating</span>
                      <strong className="font-24">4.9</strong>
                    </a>
                  </div>
                </div>
                <a className="btn btn-sm text-uppercase font-700 rounded-sm btn-full bg-blue2-dark mt-3">Follow</a>
              </div>
            </div>
          </div>
        </div>
        {/* menu-categories */}
        <div id="menu-categories" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={375}>
          <div className="menu-title"><h1>Categories</h1><p className="color-highlight">Associated Tags</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mt-3 mb-0" />
          <div className="content mt-0">
            <div className="list-group list-custom-small mb-4">
              <a >
                <i className="fa font-12 fa-shopping-bag bg-highlight color-white rounded-s" />
                <span className="font-800 color-highlight">Web Design</span>
                <span className="badge bg-dark1-light font-11">15</span>
                <i className="fa fa-angle-right" />
              </a>
              <a >
                <i className="fa font-12 fa-cog bg-blue2-dark color-white rounded-s" />
                <span>Technology</span>
                <span className="badge bg-dark1-light font-11">21</span>
                <i className="fa fa-angle-right" />
              </a>
              <a >
                <i className="fa font-12 fa-car bg-brown1-dark color-white rounded-s" />
                <span>Automotive</span>
                <span className="badge bg-dark1-light font-11">46</span>
                <i className="fa fa-angle-right" />
              </a>
              <a >
                <i className="fa font-12 fa-bolt bg-yellow1-dark color-white rounded-s" />
                <span>Latest Trends</span>
                <span className="badge bg-dark1-light font-11">17</span>
                <i className="fa fa-angle-right" />
              </a>
            </div>
            <a className="close-menu btn btn-m btn-full rounded-sm bg-highlight font-700 text-uppercase">Close Menu</a>
          </div>
        </div>
        {/* menu-tags */}
        <div id="menu-tags" className="menu menu-box-modal rounded-m" data-menu-width={320} data-menu-height={340}>
          <div className="menu-title"><h1>Tag Cloud</h1><p className="color-highlight">Associated Tags</p><a className="close-menu"><i className="fa fa-times" /></a></div>
          <div className="divider divider-margins mt-3" />
          <div className="content mt-0">
            <a className="badge bg-red2-dark mb-2 mx-1 px-3 py-2 text-uppercase rounded-s font-11"><i className="fa fa-cog pr-2" />Tech</a>
            <a className="badge bg-green1-dark mb-2 mx-1 px-3 py-2 text-uppercase rounded-s font-11"><i className="fa fa-globe pr-2" />Web</a>
            <a className="badge bg-yellow1-dark mb-2 mx-1 px-3 py-2 text-uppercase rounded-s font-11"><i className="fa fa-star pr-2" />Reviews</a>
            <div className="divider mt-2 mb-3" />
            <a className="badge bg-blue2-dark mb-2 mx-1 px-3 py-3 text-uppercase rounded-s font-12"><i className="fa fa-cog pr-2" />Tech</a>
            <a className="badge bg-teal-dark mb-2 mx-1 px-3 py-3 text-uppercase rounded-s font-12"><i className="fa fa-globe pr-2" />Web</a>
            <a className="badge bg-brown1-dark mb-2 mx-1 px-3 py-3 text-uppercase rounded-s font-12"><i className="fa fa-star pr-2" />Reviews</a>
            <div className="divider mt-3" />
            <a className="close-menu btn btn-m btn-full rounded-sm bg-highlight font-700 text-uppercase">Close Menu</a>
          </div>
        </div>
        {/*---------------*/}
        {/* Media Actions */}
        {/*---------------*/}
        {/* menu-youtube*/}
        {/*
        <div id="menu-youtube" class="menu menu-box-modal rounded-m" data-menu-height="390" data-menu-width="320">
            <div class='responsive-iframe max-iframe'><iframe src='https://www.youtube.com/embed/c9MnSeYYtYY' frameborder='0' allowfullscreen></iframe></div>
            <h3 class="text-center text-uppercase mt-3">Video Embeds</h3>
            <p class="boxed-text-l">
                Embed any video from any media service. Just copy the iframe and we'll handle the rest.
            </p>
            <a  class="close-menu btn btn-center-m btn-sm shadow-l rounded-s text-uppercase font-900 bg-green1-dark">Awesome</a>
        </div>        
        
    */}
        {/* menu maps */}
        {/*
        <div id="menu-maps" class="menu menu-box-modal rounded-m" data-menu-height="390" data-menu-width="320">
            <div class='responsive-iframe max-iframe'><iframe src='https://maps.google.com/?ie=UTF8&ll=47.595131,-122.330414&spn=0.006186,0.016512&t=h&z=17&output=embed' frameborder='0' allowfullscreen></iframe></div>
            <h3 class="text-center text-uppercase mt-3">Maps Embeds</h3>
            <p class="boxed-text-l">
                Embed maps with great ease. Just copy the iframe and we'll handle the rest.
            </p>
            <a  class="close-menu btn btn-center-m btn-sm shadow-l rounded-s text-uppercase font-900 bg-green1-dark">Awesome</a>
        </div>       
        
    */}
        {/* menu soundcloud */}
        {/*
        <div id="menu-soundcloud" class="menu menu-box-modal rounded-m" data-menu-height="390" data-menu-width="320">
            <div class='responsive-iframe max-iframe'>
                <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/118951508&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/envato" title="Envato" target="_blank" style="color: #cccccc; text-decoration: none;">Envato</a> · <a href="https://soundcloud.com/envato/audiojungle-watermark" title="AudioJungle Watermark" target="_blank" style="color: #cccccc; text-decoration: none;">AudioJungle Watermark</a></div>        </div>
            <h3 class="text-center text-uppercase mt-3">Sound Cloud Embeds</h3>
            <p class="boxed-text-l">
                Embed maps with great ease. Just copy the iframe and we'll handle the rest.
            </p>
            <a  class="close-menu btn btn-center-m btn-sm shadow-l rounded-s text-uppercase font-900 bg-green1-dark">Awesome</a>
        </div>   
    */}
        {/* menu apple-music */}
        {/*
        <div id="menu-apple-music" class="menu menu-box-modal rounded-m" data-menu-height="390" data-menu-width="320">
            <div class='responsive-iframe max-iframe'>
                <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameborder="0" height="450" style="width:100%;max-width:660px;overflow:hidden;background:transparent;" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/bohemian-rhapsody-the-original-soundtrack/1434899831"></iframe>
            </div>
            <h3 class="text-center text-uppercase mt-3 pt-2">Apple Music embeds</h3>
            <p class="boxed-text-l">
                Embed maps with great ease. Just copy the iframe and we'll handle the rest.
            </p>
            <a  class="close-menu btn btn-center-m btn-sm shadow-l rounded-s text-uppercase font-900 bg-green1-dark">Awesome</a>
        </div>   
    */}
        <Snackbar
          open={this.state.snackbarState.open}
          onClose={this.handleClose}
          message={this.state.snackbarState.message}
          key={'top' + 'center'}
        />
      </div>
    );
  }
}

export default EnterMnemonic;
import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import backButton from '../../assets/images/mob/left-arrow (2).png';
import { CopyToClipboard } from "react-copy-to-clipboard";
import logo from '../../assets/images/logo/logo3.png';

export default class DisplayPasspharse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addresses: [
                { address: '0' }
            ],
            qrImg: '',
            snackbarState: { open: false, message: '' },
            mneumonic: localStorage.getItem('mneumonic')
        };
    }

    async handleShowSnackBar(msg) {
        await this.setState({ snackbarState: { open: true, message: msg } })
        setTimeout(() => {
            this.setState({ snackbarState: { open: false, message: '' } });
        }, 2000);
    }

    render() {
        return (
            <>
                <div id="page">
                    <div className="header header-fixed header-logo-center" style={{ backgroundColor: '#fff' }}>
                        <a  className="header-title"><img src={logo} height="24px" width="110px" /></a>
                        <a onClick={() => { this.props.history.push('/walletDashboard') }} data-back-button className="header-icon header-icon-1"><img height={18} src={backButton} /></a>
                    </div>
                    <div className="content text-center" style={{ marginTop: '80px' }}>
                        <h4 style={{ paddingBottom: '20px' }}>Secret Recovery Phrase</h4>
                        <h6>
                            DO NOT share this phrase with anyone!
                            These words can be used to steal all your accounts.
                            </h6>
                    </div>
                    <div className="card card-style" style={{ marginTop: '25px' }}>
                        <div className="content text-center">
                            <p>
                                Your private Secret Recovery Phrase
                            </p>
                            {/* <br/> */}
                            <div style={{ margin: 13, border: '1px solid #dbdbdb', padding: 8, borderRadius: 8 }} >
                                <p><b>{this.state.mneumonic}</b></p>
                            </div>
                            <CopyToClipboard text={this.state.mneumonic} onCopy={() => { this.handleShowSnackBar('Address copied!'); }}>
                                <a className="btn btn-m btn-full mb-3 rounded-xl text-uppercase font-900 shadow-s bg-mint-dark bg-highlight1" style={{ fontSize: '14px !important', color: 'white', margin: 'auto', justifyContent: 'center', width: '45%' }}>Copy</a>
                            </CopyToClipboard>
                            <br/>
                            <br/>
                            <a onClick={()=>{ this.props.history.push('/walletDashboard') }} className="btn btn-m btn-full btn-danger mb-3 rounded-xl text-uppercase font-900  " style={{ fontSize: '14px !important', color: 'white', margin: 'auto', justifyContent: 'center', width: '45%' }}>Close</a>
                        </div>
                    </div>
                    <Snackbar
                        open={this.state.snackbarState.open}
                        onClose={this.handleClose}
                        message={this.state.snackbarState.message}
                        key={'top' + 'center'}
                    />
                </div>
            </>
        )
    }
}

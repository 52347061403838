import React, { Component } from 'react'

export default class DatePipe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayDate : ''
        };
    }

    componentDidMount() {
        let date = new Date(this.props.date * 1000);
        let display = date.toDateString();
        this.setState({displayDate : display });
    }

    render() {
        return (
            <>
                <p>{ this.state.displayDate }</p>
            </>
        )
    }
}

import React, { Component } from 'react';
// import QrReader from 'react-qr-scanner';
import QrReader from "react-qr-reader";

class QrScanner extends Component {
  constructor(props){
    super(props)
    this.state = {
      delay: 100,
      result: null,
    }

    this.handleScan = this.handleScan.bind(this)
  }

  componentDidMount() {
    console.log('this.props:', this.props);
  }

  sendBackData(data, isClose) {
    this.props.scannerCallBackData(data, isClose);
  }

  handleScan(data){
    // console.log('data:',data);
    if (data) {
      this.setState({
        result: data,
      }); 
      this.sendBackData(data, true);
    }
  }

  handleError(err){
    console.log('error:',err);
    alert('error...:',JSON.stringify(err));
  }

  render(){
    const previewStyle = {
      height: 220,
      width: 300,
      textAlign:'center',
      marginTop:100
    } 

    return(
      <>
        <div style={{ textAlign:'center' }} >
        {
            !this.state.result ?
            <QrReader
            delay={this.state.delay}
            style={previewStyle}
            onError={this.handleError}
            onScan={this.handleScan}
            /> : <p>{JSON.stringify(this.state.result)}</p> 
         }
        <div>
          <br/>
          <br/>
          <br/>
          <br/>
          <a onClick={()=>{ this.sendBackData(null, true); }} style={{ cursor:'pointer' }} className="header-icon header-icon-1" style={{ marginTop:20, cursor:'pointer' }} ><i style={{ fontSize:30 }} className="fas fa-times-circle"></i></a></div>
        </div>
      </>
    )
  }
}

export default QrScanner;
import React, { Component } from 'react'
import { db } from '../firebase'; 

export default class AddressAbbr extends Component {

    constructor(props){
        super(props);
        this.state = {
            name:''
        };
    }
 
    componentDidMount() {
        let val = this.props.value.slice(0,[3, 6][0] + 2) + '...' + this.props.value.slice(this.props.value.length-[4, 8][1], this.props.value.length);       
        var docRef = db.collection('walletAddresses').doc(this.props.value);
        docRef.get().then((doc)=>{
          if (doc.exists) {
            // console.log("Document data:", doc.data());
            this.setState({ name : doc.data().name });
        } else {
            // console.log("No such document!");
            this.setState({name:val});
        }
        }).catch((error) => {
        //   console.log("Error getting document:", error);
          this.setState({name:val});
      });
    }

    render() {
        return (
            <>
             { this.state.name }   
            </>
        )
    }
}

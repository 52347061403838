import React, { Component } from 'react';
import { BehaviorSubject } from 'rxjs';
import Web3 from 'web3';
import { db } from '../firebase';
import abi from '../../binance-abi';

const TEST_NET = "https://polygon-rpc.com/";
const TEST_NET_ADDRESS = '0x6f0dCC6597C86A2FdE9795F634f621f947CA125c';
const web3 = new Web3(new Web3.providers.HttpProvider(TEST_NET));
const axios = require('axios');
const bip39 = require('bip39');
const HdKey = require('ethereumjs-wallet').hdkey;

const adminAddress = '0x30f8004401350b65706d8fE3150EC73B4Fa01F94';

var historySub = new BehaviorSubject(null);
var addressesSub = new BehaviorSubject(null);
var balanceSub = new BehaviorSubject(null); 
var otherBalanceSub = new BehaviorSubject(null);
  

const getUserHistory = async (address) => {
    try {
        let latestBlock = await web3.eth.getBlockNumber();
        let myHistory = [];

        let history = await axios.get(`https://api.polygonscan.com/api?module=account&action=txlist&address=${address}&startblock=0&endblock=${latestBlock}&sort=asc&apikey=K8DKIU8CD3PT4AIP8HA2MFIZK7F7J5FR3I`);

        // console.log('history:',history.data);
 
        if (history.data.status == "1") {
            myHistory = history.data.result.filter((value, index) => {
                return value.value != "0";
            });
        }

        let fbeHistory = await axios.get(`https://api.polygonscan.com/api?module=account&action=tokentx&contractaddress=${TEST_NET_ADDRESS}&address=${address}&page=1&offset=100&sort=asc&apikey=K8DKIU8CD3PT4AIP8HA2MFIZK7F7J5FR3I`);

        // console.log('fbeHistory:', fbeHistory.data);

        let finalFbeHistory = [];

        if (fbeHistory.data.status == "1") {
            finalFbeHistory = fbeHistory.data.result;
        }

        let temp = finalFbeHistory.concat(myHistory);
        // console.log('temp:',temp);
        let myNewHistory = await temp.sort((a, b) => {
            return parseInt(b.timeStamp) - parseInt(a.timeStamp);
        }); 
        myNewHistory.map((value, index) => {
            let valFrom = value.from.slice(0, [3, 6][0] + 2) + '...' + value.from.slice(value.from.length - [4, 8][1], value.from.length);

            let valTo = value.to.slice(0, [3, 6][0] + 2) + '...' + value.to.slice(value.to.length - [4, 8][1], value.to.length);


            var fromRef = db.collection("walletAddresses").doc(value.from);
            fromRef.get().then((doc) => {
                if (doc.exists) {
                    // this.setState({ name: doc.data().name });
                    value.displayFrom = doc.data().name;
                } else {
                    value.displayFrom = valFrom;
                }
            }).catch((error) => {
                value.displayFrom = valFrom;
            });

            var toRef = db.collection("walletAddresses").doc(value.to);
            toRef.get().then((doc) => {
                if (doc.exists) {
                    // this.setState({ name: doc.data().name });
                    value.displayTo = doc.data().name;
                } else {
                    value.displayTo = valTo;
                }
            }).catch((error) => {
                value.displayTo = valTo;
            });

        });
        historySub.next(myNewHistory);  //fbeHistory.data.result.concat(myHistory)

    } catch (error) {
        // console.log('getUserHistory error:', error);
    }
}

const generateAddress = async () => {
    let mnenumonic = localStorage.getItem('mneumonic');
    // console.log('mnenumonic:', mnenumonic);
    showKeys(mnenumonic);
}

const showKeys = async (mnenumonic) => {
    let addresses = await createWallet(mnenumonic);
    let myAddress = [];
    for (let i = 0; i < 1; i++) {
        let privateKey = addresses[i].getPrivateKey().toString('hex');
        // let publicKey = addresses[i].getAddress().toString('hex');
        let address = web3.eth.accounts.privateKeyToAccount(privateKey);
        myAddress.push({
            address: address.address,
            abbrAddress: address.address.slice(0, [2, 4][0] + 2) + '...' + address.address.slice(address.address.length - [3, 4][1], address.address.length),
            privateKey: privateKey.toString()
        });
    }
    addressesSub.next(myAddress);
    addAddressOnFirebase(myAddress);
} 
 
const createWallet = (mneumonic) => {
    let i = 0;
    let addresses = [];
    while (i < 1) {
        let seed = bip39.mnemonicToSeedSync(mneumonic);
        //console.log(mneumonic);
        let wallet = HdKey.fromMasterSeed(seed).derivePath("m/44'/60'/0'/0/0").getWallet();
        // wallet.getPublicKeyString()
        // wallet.getPrivateKeyString()
        addresses.push(wallet);
        i++;
    }

    return addresses;
}

const addAddressOnFirebase = (myAddress) => {
    // var docRef = db.collection("walletAddresses").doc(myAddress[0].address.toLowerCase());
    // docRef.get().then((doc) => {
    //     if (doc.exists) { } else {
    //         if (sessionStorage.getItem('user_display_name')) {
    //             db.collection('walletAddresses').doc(myAddress[0].address.toLowerCase()).set({
    //                 name: sessionStorage.getItem('user_display_name')
    //             })
    //         }
    //     } 
    // }).catch((error) => {
    // });

    if (sessionStorage.getItem('user_id')) {
        var user_id = sessionStorage.getItem('user_id');
        var userDoc = db.collection('users').doc(user_id);
         
        userDoc.get().then((doc)=>{
            if (doc.exists) { 
                if (!doc.data().walletAddresses || doc.data().walletAddresses != myAddress[0].address.toLowerCase()) {
                    db.collection('users').doc(user_id).update({
                        'walletAddresses' : myAddress[0].address.toLowerCase()
                    }).catch((error)=>{
                        // console.log("Error update document in user table:", error);
                    })   
                }
            } 
        }).catch((error)=>{
            // console.log("Address upload error:", error);
        });
    } 
}

const getBalance = async (address) => {
    var FoobeeContract = new web3.eth.Contract(abi, TEST_NET_ADDRESS);

    var balance = await FoobeeContract.methods.balanceOf(address).call();

    console.log('balance:',balance);
    let decimals = await FoobeeContract.methods.decimals().call();
    balanceSub.next(parseFloat(balance) / parseFloat(10 ** decimals));
} 

const getOtherBalance = async (address) => {
   let balance = await web3.eth.getBalance(address); 
   let finalBalance = Number(web3.utils.fromWei(balance));
   
   otherBalanceSub.next(finalBalance.toFixed(4))
//    console.log('getOtherBalance: ',);
}
 
export { historySub, getUserHistory, addressesSub, generateAddress, getBalance, balanceSub, getOtherBalance, otherBalanceSub, adminAddress }


import React, { Component } from 'react';
import logo from '../../assets/images/logo/logo3.png';
import documentImg from '../../assets/images/mob/document.png';
import Snackbar from '@material-ui/core/Snackbar';

class EnterPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewPassword: false,
            password: '',
            snackbarState: { open: false, message: '' }
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {

    }


    async handleShowSnackBar(msg) {
        await this.setState({ snackbarState: { open: true, message: msg } })
        setTimeout(() => {
          this.setState({ snackbarState: { open: false, message: '' } });
        }, 2000);
    }

    handleSubmit() {
        if (this.state.password) {
            if (this.state.password == localStorage.getItem('walletPassord')) {

            } else {
                this.handleShowSnackBar('Please enter valid password!');
            }
        } else {
            this.handleShowSnackBar('Please enter password!');
        }
    }
    

    render() {
        return (
            <>
                <div id="page">
                    <div className="header header-fixed header-logo-center" style={{ backgroundColor: '#fff' }}>
                        {/* <a className="header-title"><img src={logo} height="25px" width="110px" /></a> */}
                        {/* <a  data-back-button className="header-icon header-icon-1"><img src="./images/mob/left-arrow (2).png" /></a> */}
                        {/* <a  data-toggle-theme class="header-icon header-icon-4"><i class="fas fa-lightbulb"></i></a> */}
                    </div>
                    {/* <div id="footer-bar" class="footer-bar-1">
        <a ><i class="fa fa-home"></i><span>Home</span></a>
        <a href="index-components.html" class="active-nav"><i class="fa fa-star"></i><span>Features</span></a>
        <a href="index-pages.html"><i class="fa fa-heart"></i><span>Pages</span></a>
        <a href="index-search.html"><i class="fa fa-search"></i><span>Search</span></a>
        <a  data-menu="menu-settings"><i class="fa fa-cog"></i><span>Settings</span></a>
    </div> */}
                    <div className="page-content header-clear-medium">
                        <div className="page-content header-clear-medium">
                            <div className="card card-style">
                                <div className="content mb-0">
                                    <h4 className="pt-3 mt-3 text-center font-700 font-20 text-uppercase">Enter Password</h4>
                                    <div className="input-style has-icon input-style-1 input-required">
                                        {/* <i className="input-icon fa fa-lock color-theme" /> */}
                                        <i className="input-icon fa fa-lock color-theme" />
                                        <em style={{ marginRight: '20px', color: '#6e6e6e' }}>
                                            {/* <i className="input-icon fa fa-eye-slash " /> */}
                                            {this.state.viewPassword ? <i style={{ marginTop: 5 }} onClick={() => this.setState({ viewPassword: !this.state.viewPassword })} className="input-icon fa fa-eye" /> :
                                                <i style={{ marginTop: 5 }} onClick={() => this.setState({ viewPassword: !this.state.viewPassword })} className="input-icon fa fa-eye-slash" />
                                            }
                                        </em>
                                        <input type={this.state.viewPassword ? "text" : "password"} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} placeholder="Enter Password" />
                                    </div>
                                    <a onClick={this.handleSubmit} className="btn btn-m mt-4 mb-3 btn-full rounded-sm bg-highlight text-uppercase font-900" style={{ backgroundColor: '#68c6b0' }}>Confirm</a>
                                </div>
                            </div>
                            {/* <div className="card card-style">
                                <div className="content mb-0">
                                    <ul className="icon-list">
                                        <li><i style={{ color:'#68c6b0' }} className="fa fa-check color-green1-dark mb-2" /> Password must contain alist 6 characters including UPPERCASE/lowercase and numbers</li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {/* End of Page Content*/}
                    {/* All Menus, Action Sheets, Modals, Notifications, Toasts, Snackbars get Placed outside the <div class="page-content"> */}
                    <div id="menu-settings" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={308}>
                        <div className="menu-title"><h1>Settings</h1><p className="color-highlight">Flexible and Easy to Use</p><a className="close-menu"><i className="fa fa-times" /></a></div>
                        <div className="divider divider-margins mb-n2" />
                        <div className="content">
                            <div className="list-group list-custom-small">
                                <a data-toggle-theme data-trigger-switch="switch-dark-mode" className="pb-2 ml-n1">
                                    <i className="fa font-12 fa-moon rounded-s bg-highlight color-white mr-3" />
                                    <span>Dark Mode</span>
                                    <div className="custom-control scale-switch ios-switch">
                                        <input data-toggle-theme-switch type="checkbox" className="ios-input" id="switch-dark-mode" />
                                        <label className="custom-control-label" htmlFor="switch-dark-mode" />
                                    </div>
                                    <i className="fa fa-angle-right" />
                                </a>
                            </div>
                            <div className="list-group list-custom-large">
                                <a data-menu="menu-highlights" >
                                    <i className="fa font-14 fa-tint bg-green1-dark rounded-s" />
                                    <span>Page Highlight</span>
                                    <strong>16 Colors Highlights Included</strong>
                                    <span className="badge bg-highlight color-white">HOT</span>
                                    <i className="fa fa-angle-right" />
                                </a>
                                <a data-menu="menu-backgrounds" className="border-0">
                                    <i className="fa font-14 fa-cog bg-blue2-dark rounded-s" />
                                    <span>Background Color</span>
                                    <strong>10 Page Gradients Included</strong>
                                    <span className="badge bg-highlight color-white">NEW</span>
                                    <i className="fa fa-angle-right" />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* Menu Settings Highlights*/}
                    <div id="menu-highlights" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={380} data-menu-effect="menu-over">
                        <div className="menu-title"><h1>Highlights</h1><p className="color-highlight">Any Element can have a Highlight Color</p><a className="close-menu"><i className="fa fa-times" /></a></div>
                        <div className="divider divider-margins mb-n2" />
                        <div className="content">
                            <div className="highlight-changer">
                                <a data-change-highlight="blue2"><i className="fa fa-circle color-blue2-dark" /><span className="color-blue2-light">Default</span></a>
                                <a data-change-highlight="red2"><i className="fa fa-circle color-red1-dark" /><span className="color-red2-light">Red</span></a>
                                <a data-change-highlight="orange"><i className="fa fa-circle color-orange-dark" /><span className="color-orange-light">Orange</span></a>
                                <a data-change-highlight="pink2"><i className="fa fa-circle color-pink2-dark" /><span className="color-pink2-light">Pink</span></a>
                                <a data-change-highlight="magenta2"><i className="fa fa-circle color-magenta2-dark" /><span className="color-magenta2-light">Purple</span></a>
                                <a data-change-highlight="aqua"><i className="fa fa-circle color-aqua-dark" /><span className="color-aqua-light">Aqua</span></a>
                                <a data-change-highlight="teal"><i className="fa fa-circle color-teal-dark" /><span className="color-teal-light">Teal</span></a>
                                <a data-change-highlight="mint"><i className="fa fa-circle color-mint-dark" /><span className="color-mint-light">Mint</span></a>
                                <a data-change-highlight="green2"><i className="fa fa-circle color-green2-dark" /><span className="color-green2-light">Green</span></a>
                                <a data-change-highlight="green1"><i className="fa fa-circle color-green1-dark" /><span className="color-green1-light">Grass</span></a>
                                <a data-change-highlight="yellow2"><i className="fa fa-circle color-yellow2-dark" /><span className="color-yellow2-light">Sunny</span></a>
                                <a data-change-highlight="yellow1"><i className="fa fa-circle color-yellow1-dark" /><span className="color-yellow1-light">Goldish</span></a>
                                <a data-change-highlight="brown1"><i className="fa fa-circle color-brown1-dark" /><span className="color-brown1-light">Wood</span></a>
                                <a data-change-highlight="dark1"><i className="fa fa-circle color-dark1-dark" /><span className="color-dark1-light">Night</span></a>
                                <a data-change-highlight="dark2"><i className="fa fa-circle color-dark2-dark" /><span className="color-dark2-light">Dark</span></a>
                                <div className="clearfix" />
                            </div>
                            <a data-menu="menu-settings" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">Back to Settings</a>
                        </div>
                    </div>
                    {/* Menu Settings Backgrounds*/}
                    <div id="menu-backgrounds" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={310} data-menu-effect="menu-over">
                        <div className="menu-title"><h1>Backgrounds</h1><p className="color-highlight">Change Page Color Behind Content Boxes</p><a className="close-menu"><i className="fa fa-times" /></a></div>
                        <div className="divider divider-margins mb-n2" />
                        <div className="content">
                            <div className="background-changer">
                                <a data-change-background="none"><i className="bg-theme" /><span>Default</span></a>
                                <a data-change-background="plum"><i className="body-plum" /><span className="color-plum-dark">Plum</span></a>
                                <a data-change-background="magenta3"><i className="body-magenta3" /><span className="color-magenta3-dark">Magenta</span></a>
                                <a data-change-background="dark3"><i className="body-dark3" /><span className="color-dark3-dark">Dark</span></a>
                                <a data-change-background="violet"><i className="body-violet" /><span className="color-violet-dark">Violet</span></a>
                                <a data-change-background="red3"><i className="body-red3" /><span className="color-red3-dark">Red</span></a>
                                <a data-change-background="green3"><i className="body-green3" /><span className="color-green3-dark">Green</span></a>
                                <a data-change-background="sky"><i className="body-sky" /><span className="color-sky-dark">Sky</span></a>
                                <a data-change-background="pumpkin"><i className="body-pumpkin" /><span className="color-pumpkin-dark">Orange</span></a>
                                <a data-change-background="yellow3"><i className="body-yellow3" /><span className="color-yellow3-dark">Yellow</span></a>
                                <div className="clearfix" />
                            </div>
                            <a data-menu="menu-settings" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">Back to Settings</a>
                        </div>
                    </div>
                    {/* Menu Share */}
                    <div id="menu-share" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={345} data-menu-effect="menu-over">
                        <div className="menu-title mt-n1"><h1>Share the Love</h1><p className="color-highlight">Just Tap the Social Icon. We'll add the Link</p><a className="close-menu"><i className="fa fa-times" /></a></div>
                        <div className="content mb-0">
                            <div className="divider mb-0" />
                            <div className="list-group list-custom-small list-icon-0">
                                <a className="shareToFacebook">
                                    <i className="font-18 fab fa-facebook color-facebook" />
                                    <span className="font-13">Facebook</span>
                                    <i className="fa fa-angle-right" />
                                </a>
                                <a className="shareToTwitter">
                                    <i className="font-18 fab fa-twitter-square color-twitter" />
                                    <span className="font-13">Twitter</span>
                                    <i className="fa fa-angle-right" />
                                </a>
                                <a className="shareToLinkedIn">
                                    <i className="font-18 fab fa-linkedin color-linkedin" />
                                    <span className="font-13">LinkedIn</span>
                                    <i className="fa fa-angle-right" />
                                </a>
                                <a className="shareToWhatsApp">
                                    <i className="font-18 fab fa-whatsapp-square color-whatsapp" />
                                    <span className="font-13">WhatsApp</span>
                                    <i className="fa fa-angle-right" />
                                </a>
                                <a className="shareToMail border-0">
                                    <i className="font-18 fa fa-envelope-square color-mail" />
                                    <span className="font-13">Email</span>
                                    <i className="fa fa-angle-right" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Snackbar
                    open={this.state.snackbarState.open}
                    onClose={this.handleClose}
                    message={this.state.snackbarState.message}
                    key={'top' + 'center'}
                />
            </>
        );
    }
}

export default EnterPassword;
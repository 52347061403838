import React, { Component } from 'react';
import abi from './binance-abi';
import './App.css';
import '../src/assets/styles/bootstrap.css';
import '../src/assets/styles/style.css';
import '../src/assets/fonts/css/fontawesome-all.min.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Web3 from 'web3';
import EnterScreens from './app/screens/EnterScreens';
import CreatePassword from './app/screens/CreatePassword';
import GenerateMnemonic from './app/screens/GenerateMneumonic';
import EnterMnemonic from './app/screens/EnterMneumonic';
import WalletDashboard from './app/screens/WalletDashboard';
import SendFoobee from './app/screens/SendFoobee';
import EnterPassword from './app/screens/EnterPassword';
import TransactionDetails from './app/screens/TransactionDetails';
import ReceiveFoobee from './app/screens/ReceiveFoobee';
import QrScanner from './app/screens/QrScanner';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import { db } from '../src/app/firebase';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar'
import DisplayPasspharse from './app/screens/DisplayPasspharse';
import SendMatic from './app/screens/SendMatic';

const bip39 = require('bip39');
var HdKey = require('ethereumjs-wallet').hdkey;

var TEST_NET = "https://polygon-rpc.com/";
var TEST_NET_ADDRESS = '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee';
var web3 = new Web3(new Web3.providers.HttpProvider(TEST_NET));

class App extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      snackbarState: { open: false, message: '' },
    };

    // this.handleClickOpen = this.handleClickOpen.bind(this);
    // this.handleClose = this.handleClose.bind(this);
  } 

  componentWillMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const user_id = queryParams.get('user_id');
    const app_id = queryParams.get('app_id');
    const app_secret = queryParams.get('app_secret');

    if (user_id) sessionStorage.setItem('user_id', user_id);
    if (app_id) sessionStorage.setItem('app_id', app_id);
    if (app_secret) sessionStorage.setItem('app_secret', app_secret);
  }

  showKeys(mnenumonic) {
    let addresses = this.createWallet(mnenumonic);
    for (let i = 0; i < 10; i++) {
      console.log("----------------------------------");
      console.log("Public Key :", addresses[i].getAddress().toString('hex'));
      console.log("Private Key :", addresses[i].getPrivateKey().toString('hex'));
      let privateKey = addresses[i].getPrivateKey().toString('hex');
      console.log("asdas :", web3.eth.accounts.privateKeyToAccount(privateKey));
      console.log("isValid", web3.utils.isAddress(web3.eth.accounts.privateKeyToAccount(privateKey)));
      console.log("----------------------------------");
    }
  }

  createWallet(mneumonic) {
    let i = 0;
    let addresses = [];
    while (i < 10) {
      let seed = bip39.mnemonicToSeedSync(mneumonic, i.toString());
      console.log(seed);
      let wallet = HdKey.fromMasterSeed(seed).derivePath("m/44'/60'/0'/0/0").getWallet();
      // wallet.getPublicKeyString()
      // wallet.getPrivateKeyString()
      addresses.push(wallet);
      i++;
    }

    return addresses;
  }

  createMneumonic() {
    return bip39.generateMnemonic();
  }

  async loadBlockchainData() {
    const web3 = new Web3(new Web3.providers.HttpProvider(TEST_NET));

    // var x = web3.eth.getBalance("0x81b7E08F65Bdf5648606c89998A9CC8164397647").then((result) => {
    //   //return result.json();
    //   console.log('result:',result);
    // }).then((balance) => {
    //   console.log(balance);
    // }).catch((e) => {
    //   console.log(e);
    // });
    // console.log(x);

    var FoobeescreenPlayContract = new web3.eth.Contract(abi, TEST_NET_ADDRESS);
    console.log('FoobeescreenPlayContract', FoobeescreenPlayContract);

    FoobeescreenPlayContract.methods.balanceOf('0x1Cd7907Cbb1dd8420035639FcF9DA90AC3ab14D7').call({ from: '0x1Cd7907Cbb1dd8420035639FcF9DA90AC3ab14D7' }, (error, result) => {
      console.log('result...........', result);
    }, error => {
      console.log('error.............', error);
    });

  }

  getUserDetails() {
  } 

  async requireToAuth(to, from, next) {
    var user_id = await sessionStorage.getItem('user_id');
    let app_secret = await sessionStorage.getItem('app_secret');
    let app_id = await sessionStorage.getItem('app_id');
    if (user_id && app_secret && app_id) {
      var walletAuth = db.collection("wallteAuthentication").doc('authorize');
      walletAuth.get().then((doc) => {
        if (doc.exists) {
          // console.log("wallteAuth Document data:", doc.data());
          if ((doc.data().androidAppId == app_id || doc.data().iOSAppId == app_id) && doc.data().projectSecrete == app_secret) {
            var userDoc = db.collection('users').doc(user_id);
            userDoc.get().then((resDoc) => { 
              if (resDoc.exists) {
                if (resDoc.data().name) sessionStorage.setItem('user_display_name', resDoc.data().name);
                if (to.meta.auth) {
                  let mneumonic = localStorage.getItem('mneumonic');
                  if (mneumonic && localStorage.getItem('walletPassord') ) {
                    next();
                  }
                  next.redirect('enterScreens');
                } else { 
                  next();
                  console.log('else called');
                } 
              } else {
                console.log("No such document!");
                alert('Invalid user!');
              }
            }).catch((error) => {
              console.log("Error getting document:", error);
            });
          } else {
            alert('Invalid user!');
          }
        } else {
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    } else {
      // this.handleShowSnackBar('Invalid user!');
      alert('Invalid user!');
      // console.log('Invalid user');
      // console.log(this.state);
      // await this.setState({ dialogOpen: true });
      // this.handleClickOpen(); 
    }
  };

  render() {
    return (
      <div>
        <BrowserRouter>
          <GuardProvider guards={[this.requireToAuth]}>
            <Switch>
              <GuardedRoute exact path='/enterScreens' component={EnterScreens} />
              <GuardedRoute exact path='/createPassword' component={CreatePassword} />
              <GuardedRoute exact path='/generateMnemonic' component={GenerateMnemonic} />
              <GuardedRoute exact path='/enterMnemonic' component={EnterMnemonic} meta={{ auth: true }} />
              <GuardedRoute path='/walletDashboard' exact component={WalletDashboard} meta={{ auth: true }} />
              <GuardedRoute exact path='/sendFoobee' component={SendFoobee} meta={{ auth: true }} />
              <GuardedRoute exact path='/sendMatic' component={SendMatic} meta={{ auth: true }} />
              <GuardedRoute exact path='/enterPassword' component={EnterPassword} meta={{ auth: true }} />
              <GuardedRoute exact path='/transactionDetails' component={TransactionDetails} meta={{ auth: true }} />
              <GuardedRoute exact path='/receiveFoobee' component={ReceiveFoobee} meta={{ auth: true }} />
              <GuardedRoute exact path="/displayPassphrase" component={DisplayPasspharse} meta={{ auth: true }} />
              <Route exact path='/scanqr' component={QrScanner} />
              {/* <Route component={Home} />  */}
              <Redirect to='walletDashboard' />
            </Switch>
          </GuardProvider>
        </BrowserRouter>
        <Snackbar
          open={this.state.snackbarState.open}
          onClose={this.handleClose}
          message={this.state.snackbarState.message}
          key={'top' + 'center'}
        />
        <Dialog
          open={this.state.dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Invalid User!"}</DialogTitle>
          <DialogContent>
            {/* <DialogContentText style={{ marginTop: -10 }} id="alert-dialog-description">
              Are you sure to do this?
          </DialogContentText> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Ok
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default App;

const firebaseConfig = {
  apiKey: "AIzaSyChSEL7kZ4o8j_p_iGXD7NGcrNzJBJQquo",
  authDomain: "foobeeapp-52f36.firebaseapp.com",
  databaseURL: "https://foobeeapp-52f36.firebaseio.com",
  projectId: "foobeeapp-52f36",
  storageBucket: "foobeeapp-52f36.appspot.com",
  messagingSenderId: "985389624680",
  appId: "1:985389624680:web:34eaa6347fd5c08d71badd"
};

export default firebaseConfig;
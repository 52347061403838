import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import logo from '../../assets/images/logo/logo3.png';
import documentImg from '../../assets/images/mob/document.png';
import userImg from '../../assets/images/pictures/faces/1s.png';
import AddressAbbr from './AddressAbbr';
import DatePipe from './DatePipe';
import { db } from '../firebase'; 
import backButton from '../../assets/images/mob/left-arrow (2).png';
import Web3 from 'web3';
import Snackbar from '@material-ui/core/Snackbar';

class TransactionDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayAmount : '',
            displayHash : '',
            displayFrom: '',
            displayTo: '',
            coinSymbol : 'FBE',
            snackbarState: { open: false, message: '' }
        }
    } 
 
    async componentDidMount() {
        console.log('props:',this.props);

        let valFrom = this.props.location.state?.value?.from?.slice(0,[3, 6][0] + 2) + '...' + this.props.location.state?.value?.from.slice(this.props.location.state?.value?.from.length-[4, 8][1], this.props.location.state?.value?.from.length); 

        let valTo = this.props.location.state?.value?.to.slice(0,[3, 6][0] + 2) + '...' + this.props.location.state?.value?.to.slice(this.props.location.state?.value?.to.length-[4, 8][1], this.props.location.state?.value?.to.length); 

        // this.setState({ displayFrom : valFrom, displayTo : valTo });



        if (this.props.location.state?.displayValue.startsWith("0x")) {
            // var fromRef = await db.collection('walletAddresses').doc(this.props.location.state?.value?.from);
            // fromRef.get().then((doc)=>{
            //     if (doc.exists) {
            //       this.setState({ displayFrom : doc.data().name });
            //   } else {
            //       this.setState({displayFrom:valFrom});
            //   }
            //   }).catch((error) => {
            //     console.log("Error getting document:", error);
            //     this.setState({displayFrom:valFrom});
            // });

            this.setState({displayFrom:valFrom});
            this.setState({displayTo:valTo});
            // var toRef = db.collection('walletAddresses').doc(this.props.location.state?.value?.to);

            // toRef.get().then((doc)=>{
            //     if (doc.exists) {
            //       this.setState({ displayTo : doc.data().name });
            //   } else {
            //       this.setState({displayTo:valTo});
            //   }
            //   }).catch((error) => {
            //     console.log("Error getting document:", error);
            //     this.setState({displayTo:valTo});
            // });
        } else {
            console.log('else called');
            if (this.props.location.state?.check == "Send") {
                console.log('Send called');
                var fromRef = await db.collection('walletAddresses').doc(this.props.location.state?.value?.from);
                fromRef.get().then((doc)=>{
                    if (doc.exists) {
                      this.setState({ displayFrom : doc.data().name, displayTo : this.props.location.state?.displayValue });
                  } else {
                    this.setState({ displayFrom : valFrom, displayTo : this.props.location.state?.displayValue });
                  }
                  }).catch((error) => {
                    this.setState({ displayFrom : valFrom, displayTo : this.props.location.state?.displayValue });
                });
            } else if (this.props.location.state?.check == "Received") {
                console.log('Received called');
                var toRef = db.collection('walletAddresses').doc(this.props.location.state?.value?.to);

                toRef.get().then((doc)=>{
                    if (doc.exists) {
                        this.setState({ displayTo : doc.data().name, displayFrom : valFrom });
                  } else {
                    this.setState({ displayTo : valTo, displayFrom : valFrom });
                  }
                  }).catch((error) => {
                    this.setState({ displayTo : valTo, displayFrom : valFrom });
                });
            } else {
                console.log('Fail called');
                this.setState({ displayTo : this.props.location.state?.value?.to, displayFrom : valTo });
            }
        }

        var amount;
        if (this.props.location.state.value?.tokenDecimal) { 
            amount = Number(this.props.location.state?.value?.value) / (10 ** Number(this.props.location.state.value?.tokenDecimal));
            this.setState({coinSymbol : 'FBE'});
        } else {
            amount =  Web3.utils.fromWei(this.props.location.state?.value?.value, 'ether');
            this.setState({coinSymbol : 'MATIC'});
        }
        let hash = this.props.location.state.value.hash.slice(0,[3, 6][0] + 2) + '...' + this.props.location.state.value.hash.slice(this.props.location.state.value.hash.length-[4, 8][1], this.props.location.state.value.hash.length);       
        this.setState({ displayAmount : amount });
        this.setState({ displayHash: hash });
    }  
    
    async handleShowSnackBar(msg) {
        await this.setState({ snackbarState: { open: true, message: msg } })
        setTimeout(() => {
          this.setState({ snackbarState: { open: false, message: '' } });
        }, 2000);
    }

    render() {
        return (
            <>
                <div id="page">
                    <div className="header header-fixed header-logo-center" style={{ backgroundColor: '#fff' }}>
                        <a  className="header-title"><img src={logo} height="24px" width="110px" /></a>
                        <a onClick={()=>{ this.props.history.push('/walletDashboard') }} data-back-button className="header-icon header-icon-1"><img height={18} src={backButton} /></a>
                        {/* <a  data-toggle-theme class="header-icon header-icon-4"><i class="fas fa-lightbulb"></i></a> */}
                    </div>
                    {/* <div id="footer-bar" class="footer-bar-1">
        <a ><i class="fa fa-home"></i><span>Home</span></a>
        <a href="index-components.html"><i class="fa fa-star"></i><span>Features</span></a>
        <a href="index-pages.html" class="active-nav"><i class="fa fa-heart"></i><span>Pages</span></a>
        <a href="index-search.html"><i class="fa fa-search"></i><span>Search</span></a>
        <a  data-menu="menu-settings"><i class="fa fa-cog"></i><span>Settings</span></a>
    </div> */}
                    <div className="page-content header-clear-medium">
                        <div className="card card-style">
                            <div className="content">
                                <h3 style={{ paddingBottom: '25px !important' }}>Transaction Detail</h3>
                                <br/>
                                <div className="row mb-0">
                                    <div className="col-3">
                                        <img src={userImg} width={80} className="rounded-xl" />
                                    </div>
                                    <div className="col-9 pl-4">
                                        <div className="d-flex">
                                            <div><p className="font-700 color-theme">From</p></div>
                                            <div className="ml-auto">{ this.state.displayFrom }</div>
                                        </div>
                                        <div className="d-flex">
                                            <div><p className="font-700 color-theme">To</p></div>
                                            <div className="ml-auto">{ this.state.displayTo }</div>
                                        </div>
                                        <div className="d-flex">
                                            <div><p className="font-700 color-theme">Via</p></div>
                                            <div className="ml-auto"><p>Foobee Wallet</p></div>
                                        </div>
                                        <div className="d-flex">
                                            <div><p className="font-700 color-theme">Date</p></div>
                                            <div className="ml-auto"><DatePipe date={this.props?.location?.state?.value?.timeStamp} /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider mt-3 mb-3" />
                                <div className="row mb-0">
                                    <div className="col-6"><h4 className="font-15">Type</h4></div>
                                    <div className="col-6"><h4 className="font-15 text-right">Wallet </h4></div>
                                    <div className="divider divider-margins w-100 mt-2 mb-2" />
                                    {
                                        this.props.location.state.txreceipt_status ? <></> : <> <div className="col-6"><h4 className="font-15 mt-1">Amount</h4></div>
                                        <div className="col-6"><h4 style={{ color:'#68c6b0' }} className="font-15 text-right mt-1 color-green1-dark">{ this.state.displayAmount } { this.state.coinSymbol }</h4></div> </> 
                                    }
                                    {/* <div class="divider divider-margins w-100 mt-2 mb-2"></div> */}
                                    {/* <div class="col-6"><h4 class="font-15 mt-1">Comission</h4></div>
                    <div class="col-6"><h4 class="font-15 text-right mt-1 color-red2-dark">$ -2.50</h4></div> */}
                                    <div className="divider divider-margins w-100 mt-2 mb-2" />
                                    <div className="col-6"><h4 className="font-15 mt-1">Transaction Hash</h4></div>
                                    <div className="col-6"><h4 className="font-15 text-right mt-1">
                                    <CopyToClipboard text={this.props.location.state.value.hash} onCopy={() => { this.handleShowSnackBar('Transaction hash copied!'); }}><p>{ this.state.displayHash } &nbsp;&nbsp;<i className="far fa-copy" /></p></CopyToClipboard></h4></div>
                                    <div className="divider divider-margins w-100 mt-2 mb-2" />
                                    <div className="col-6"><h4 className="font-15 mt-1">Status</h4></div>
                                    <div className="col-6"><h4 className={ this.props.location.state.txreceipt_status ? "font-15 text-right mt-1 text-danger" : "font-15 text-right mt-1 text-success" }>{ this.props.location.state.txreceipt_status ? 'Fail' : 'Success' }</h4></div>
                                    <div className="divider divider-margins w-100 mt-2 mb-3" />
                                    {/* <div class="col-12"><a  class="btn btn-full btn-m bg-blue2-dark rounded-sm text-uppercase font-800 mt-3">Download PDF</a></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End of Page Content*/}
                    {/* All Menus, Action Sheets, Modals, Notifications, Toasts, Snackbars get Placed outside the <div class="page-content"> */}
                    <div id="menu-settings" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={308}>
                        <div className="menu-title"><h1>Settings</h1><p className="color-highlight">Flexible and Easy to Use</p><a  className="close-menu"><i className="fa fa-times" /></a></div>
                        <div className="divider divider-margins mb-n2" />
                        <div className="content">
                            <div className="list-group list-custom-small">
                                <a  data-toggle-theme data-trigger-switch="switch-dark-mode" className="pb-2 ml-n1">
                                    <i className="fa font-12 fa-moon rounded-s bg-highlight color-white mr-3" />
                                    <span>Dark Mode</span>
                                    <div className="custom-control scale-switch ios-switch">
                                        <input data-toggle-theme-switch type="checkbox" className="ios-input" id="switch-dark-mode" />
                                        <label className="custom-control-label" htmlFor="switch-dark-mode" />
                                    </div>
                                    <i className="fa fa-angle-right" />
                                </a>
                            </div>
                            <div className="list-group list-custom-large">
                                <a data-menu="menu-highlights" >
                                    <i className="fa font-14 fa-tint bg-green1-dark rounded-s" />
                                    <span>Page Highlight</span>
                                    <strong>16 Colors Highlights Included</strong>
                                    <span className="badge bg-highlight color-white">HOT</span>
                                    <i className="fa fa-angle-right" />
                                </a>
                                <a data-menu="menu-backgrounds"  className="border-0">
                                    <i className="fa font-14 fa-cog bg-blue2-dark rounded-s" />
                                    <span>Background Color</span>
                                    <strong>10 Page Gradients Included</strong>
                                    <span className="badge bg-highlight color-white">NEW</span>
                                    <i className="fa fa-angle-right" />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* Menu Settings Highlights*/}
                    <div id="menu-highlights" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={380} data-menu-effect="menu-over">
                        <div className="menu-title"><h1>Highlights</h1><p className="color-highlight">Any Element can have a Highlight Color</p><a  className="close-menu"><i className="fa fa-times" /></a></div>
                        <div className="divider divider-margins mb-n2" />
                        <div className="content">
                            <div className="highlight-changer">
                                <a  data-change-highlight="blue2"><i className="fa fa-circle color-blue2-dark" /><span className="color-blue2-light">Default</span></a>
                                <a  data-change-highlight="red2"><i className="fa fa-circle color-red1-dark" /><span className="color-red2-light">Red</span></a>
                                <a  data-change-highlight="orange"><i className="fa fa-circle color-orange-dark" /><span className="color-orange-light">Orange</span></a>
                                <a  data-change-highlight="pink2"><i className="fa fa-circle color-pink2-dark" /><span className="color-pink2-light">Pink</span></a>
                                <a  data-change-highlight="magenta2"><i className="fa fa-circle color-magenta2-dark" /><span className="color-magenta2-light">Purple</span></a>
                                <a  data-change-highlight="aqua"><i className="fa fa-circle color-aqua-dark" /><span className="color-aqua-light">Aqua</span></a>
                                <a  data-change-highlight="teal"><i className="fa fa-circle color-teal-dark" /><span className="color-teal-light">Teal</span></a>
                                <a  data-change-highlight="mint"><i className="fa fa-circle color-mint-dark" /><span className="color-mint-light">Mint</span></a>
                                <a  data-change-highlight="green2"><i className="fa fa-circle color-green2-dark" /><span className="color-green2-light">Green</span></a>
                                <a  data-change-highlight="green1"><i className="fa fa-circle color-green1-dark" /><span className="color-green1-light">Grass</span></a>
                                <a  data-change-highlight="yellow2"><i className="fa fa-circle color-yellow2-dark" /><span className="color-yellow2-light">Sunny</span></a>
                                <a  data-change-highlight="yellow1"><i className="fa fa-circle color-yellow1-dark" /><span className="color-yellow1-light">Goldish</span></a>
                                <a  data-change-highlight="brown1"><i className="fa fa-circle color-brown1-dark" /><span className="color-brown1-light">Wood</span></a>
                                <a  data-change-highlight="dark1"><i className="fa fa-circle color-dark1-dark" /><span className="color-dark1-light">Night</span></a>
                                <a  data-change-highlight="dark2"><i className="fa fa-circle color-dark2-dark" /><span className="color-dark2-light">Dark</span></a>
                                <div className="clearfix" />
                            </div>
                            <a  data-menu="menu-settings" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">Back to Settings</a>
                        </div>
                    </div>
                    {/* Menu Settings Backgrounds*/}
                    <div id="menu-backgrounds" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={310} data-menu-effect="menu-over">
                        <div className="menu-title"><h1>Backgrounds</h1><p className="color-highlight">Change Page Color Behind Content Boxes</p><a  className="close-menu"><i className="fa fa-times" /></a></div>
                        <div className="divider divider-margins mb-n2" />
                        <div className="content">
                            <div className="background-changer">
                                <a  data-change-background="none"><i className="bg-theme" /><span>Default</span></a>
                                <a  data-change-background="plum"><i className="body-plum" /><span className="color-plum-dark">Plum</span></a>
                                <a  data-change-background="magenta3"><i className="body-magenta3" /><span className="color-magenta3-dark">Magenta</span></a>
                                <a  data-change-background="dark3"><i className="body-dark3" /><span className="color-dark3-dark">Dark</span></a>
                                <a  data-change-background="violet"><i className="body-violet" /><span className="color-violet-dark">Violet</span></a>
                                <a  data-change-background="red3"><i className="body-red3" /><span className="color-red3-dark">Red</span></a>
                                <a  data-change-background="green3"><i className="body-green3" /><span className="color-green3-dark">Green</span></a>
                                <a  data-change-background="sky"><i className="body-sky" /><span className="color-sky-dark">Sky</span></a>
                                <a  data-change-background="pumpkin"><i className="body-pumpkin" /><span className="color-pumpkin-dark">Orange</span></a>
                                <a  data-change-background="yellow3"><i className="body-yellow3" /><span className="color-yellow3-dark">Yellow</span></a>
                                <div className="clearfix" />
                            </div>
                            <a  data-menu="menu-settings" className="btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">Back to Settings</a>
                        </div>
                    </div>
                    {/* Menu Share */}
                    <div id="menu-share" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={345} data-menu-effect="menu-over">
                        <div className="menu-title mt-n1"><h1>Share the Love</h1><p className="color-highlight">Just Tap the Social Icon. We'll add the Link</p><a  className="close-menu"><i className="fa fa-times" /></a></div>
                        <div className="content mb-0">
                            <div className="divider mb-0" />
                            <div className="list-group list-custom-small list-icon-0">
                                <a  className="shareToFacebook">
                                    <i className="font-18 fab fa-facebook color-facebook" />
                                    <span className="font-13">Facebook</span>
                                    <i className="fa fa-angle-right" />
                                </a>
                                <a  className="shareToTwitter">
                                    <i className="font-18 fab fa-twitter-square color-twitter" />
                                    <span className="font-13">Twitter</span>
                                    <i className="fa fa-angle-right" />
                                </a>
                                <a  className="shareToLinkedIn">
                                    <i className="font-18 fab fa-linkedin color-linkedin" />
                                    <span className="font-13">LinkedIn</span>
                                    <i className="fa fa-angle-right" />
                                </a>
                                <a  className="shareToWhatsApp">
                                    <i className="font-18 fab fa-whatsapp-square color-whatsapp" />
                                    <span className="font-13">WhatsApp</span>
                                    <i className="fa fa-angle-right" />
                                </a>
                                <a  className="shareToMail border-0">
                                    <i className="font-18 fa fa-envelope-square color-mail" />
                                    <span className="font-13">Email</span>
                                    <i className="fa fa-angle-right" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <Snackbar
                            open={this.state.snackbarState.open}
                            message={this.state.snackbarState.message}
                            key={'top' + 'center'}
                        />
                </div>
            </>
        );
    }
}

export default TransactionDetails;